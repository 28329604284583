import React, { useEffect, useState } from 'react';
import * as Images from '../../../../../assets';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ImgComp from '../../../ImgComp';
import { fetchWithFormDataWithoutBodys } from '../../../../../apiGeneralFunction';
import { LogoutModalBox } from '../../../ModalBox';

const Drawermodal = ({ isOpen, handleDrawerToggle }) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState('Dashboard');

    useEffect(() => {
        const path = location.pathname.split('/')[2];
        let componentName = path ? path.charAt(0).toUpperCase() + path.slice(1) : 'Dashboard';
        if (path === 'salesreports') {
            componentName = 'Sales Reports';
        }
        setSelectedComponent(componentName);
    }, [location]);


    const changeComponent = (component) => {
        if (component === 'Sign Out') {
            setIsModalOpen(true);
        } else {
            setSelectedComponent(component);
            navigate(`/dashboard/${component.toLowerCase().replace(/\s+/g, '')}`);

        }

    };

    // const handleLogout = () => {
    //     const fetchAPI = async () => {
    //         const tokenValue = localStorage.getItem('login api response token') || '';
    //         const storedToken = JSON.parse(tokenValue);
    //         if (!storedToken) {
    //             navigate('/');
    //             return;
    //         }
    //         else {
    //             try {
    //                 const result = await fetchWithFormDataWithoutBody('logout', storedToken);
    //                 console.log('API logout response:', result);
    //                 if (result?.status === true) {
    //                     localStorage.clear();
    //                     navigate('/');
    //                 }
    //                 else {
    //                 }
    //             } catch (error) {
    //             }
    //         }
    //     };
    //     fetchAPI();
    // }

    const handleLogout = () => {
        const fetchAPI = async () => {
            const tokenValue = localStorage.getItem('login api response token');
            const storedToken = JSON.parse(tokenValue);

            if (!storedToken) {
                navigate('/');
                return;
            }

            else {
                try {

                    const result = await fetchWithFormDataWithoutBodys('api/v1/logout', storedToken);
                    console.log('API logout response:', result);
                    if (result?.status === true) {
                        localStorage.clear();
                        navigate('/');

                    }
                    else {
                    }
                } catch (error) {
                }
            }
        };
        fetchAPI();
    }

    const buttonEditContent = (
        <>
            <div className='flex items-center gap-5 justify-end mt-3'>
                <button
                    className='border border-red-800 mybzb_text_clr text-base px-5 py-2 rounded-lg w-full focus:border-red-800 focus:outline-0 focus:ring-0'
                    onClick={() => setIsModalOpen(false)}
                >
                    No
                </button>
                <button
                    className=' mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg w-full focus:border-red-800  focus:outline-0 focus:ring-0'
                    onClick={() => handleLogout()}
                >
                    Yes
                </button>
            </div>
        </>
    )


    const ModalContent = () => {
        return (
            <>
                <ImgComp
                    src={Images.logout_vendor_img}
                />
                <div className='pt-3'>
                    Are you sure, <br />
                    You want to Log out ?
                </div>
            </>
        )
    }

    const Sidebar = ({ changeComponent }) => {
        const [hoveredItem, setHoveredItem] = useState(null);

        const handleMouseEnter = (itemName) => {
            setHoveredItem(itemName);
        };

        const handleMouseLeave = () => {
            setHoveredItem(null);
        };



        const sidebarItems = [
            { name: 'Dashboard', normalImg: Images.dashboard_normal_icon, hoverImg: Images.dashboard_hover_icon },
            { name: 'Sellers', normalImg: Images.seller_normal_icon, hoverImg: Images.seller_hover_icon },
            { name: 'Customers', normalImg: Images.customer_normal_icon, hoverImg: Images.customer_hover_icon },
            { name: 'Orders', normalImg: Images.order_normal_icon, hoverImg: Images.order_hover_icon },
            { name: 'Products', normalImg: Images.product_normal_icon, hoverImg: Images.product_hover_icon },
            { name: 'Reports', normalImg: Images.report_normal_icon, hoverImg: Images.report_hover_icon },
            { name: 'Reviews', normalImg: Images.review_normal_icon, hoverImg: Images.review_hover_icon },
            { name: 'Subscription', normalImg: Images.subscription_normal_icon, hoverImg: Images.subscription_hover_icon },
            { name: 'Transactions', normalImg: Images.transaction_normal_icon, hoverImg: Images.transaction_hover_icon },
            { name: 'General', normalImg: Images.general_normal_icon, hoverImg: Images.general_hover_icon },
            { name: 'Settings', normalImg: Images.settings_normal_icon, hoverImg: Images.settings_hover_icon },
            { name: 'CMS', normalImg: Images.cms_normal_icon, hoverImg: Images.cms_hover_icon },
            { name: 'Sign Out', normalImg: Images.signout_normal_icon, hoverImg: Images.signout_hover_icon },
            { name: 'Profile', normalImg: Images.dashboard_normal_icon, hoverImg: Images.dashboard_hover_icon },
        ];

        console.log("selectedComponent", selectedComponent);
        return (
            <div className="h-full px-10 py-14 max-lg:w-full max-lg:py-0  border-r-1"
            >
                <div className=" flex items-center ">
                    <ImgComp
                        src={Images.logo}
                        srcSet={Images.logo}
                        alt={'logo'}
                        className={'w-[30%] mybzb_bg_pinkclr rounded-lg'}

                    />
                    <div className='font-bold text-3xl ms-3'>MyBzB</div>
                </div>
                <div className=''>
                    {sidebarItems.map((item, index) => (
                        <div key={index} >
                            <button
                                key={item.name}
                                onMouseEnter={() => handleMouseEnter(item.name)}
                                onMouseLeave={handleMouseLeave}
                                className={`flex items-center block py-3 my-5 px-5  w-full rounded-xl focus:outline-0 focus:ring-0 ${selectedComponent === item.name || hoveredItem === item.name
                                    ? 'bg-red-700 text-white'
                                    : 'text-neutral-500'
                                    }`}
                                onClick={() => changeComponent(item.name)}
                            >
                                <img
                                    src={hoveredItem === item.name || selectedComponent === item.name ? item.hoverImg : item.normalImg}
                                    alt={item.name}
                                    className="w-5 me-3 "
                                />
                                {item.name}
                            </button>
                        </div>
                    ))}
                </div>


            </div>
        );
    };


    return (
        <>
            <div
                // className={`fixed top-0 left-0 w-full h-full  overflow-y-auto flex items-center bg-gray-500 bg-opacity-50 z-30 ${isOpen ? 'block' : 'hidden'}`}
                className={`fixed top-0 left-0 w-full h-full  overflow-y-auto flex items-center bg-gray-500 bg-opacity-50  z-30 ${isOpen ? 'block' : 'hidden'}`}
                role="dialog"
                aria-modal="true"

            >
                <div className="bg-white rounded-lg w-full max-w-xs overflow-y-auto z-50 h-full" style={{ background: '#fff !important' }}>
                    <div
                        className='cursor-pointer bg-white mx-auto text-end py-5 pe-5'
                        onClick={handleDrawerToggle}

                    >
                        <i className='fa fa-close text-neutral-500'></i>
                    </div>

                    <Sidebar changeComponent={changeComponent} />
                </div>
            </div>
            {isModalOpen &&
                <div>
                    <LogoutModalBox
                        content={<ModalContent />}
                        buttonContent={buttonEditContent}
                        modalWidth={`max-w-sm`}
                        onClose={() => setIsModalOpen(false)}
                    />
                </div>
            }

        </>
    );
};

export default Drawermodal;    

import React, { useState } from 'react'
import Layout from '../ReusableComp/Layout'
import Register from './Register'


const UserAuth = () => {

    return (
        <>
            <Register />

        </>
    )
}

export default UserAuth
import  React from "react";
function SocialMediaIcons() {
    const socialMediaIcons = [{ src: "https://cdn.builder.io/api/v1/image/assets/TEMP/2dd432875c0d5f3d57cdd5170d6a6824a2e8cfd398433c3ef80cd53fe035e252?apiKey=8e5983036f284a13866aae97819cdb73&", alt: "Social media icon 1" }, { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/ba01fd53fee55190d2612b00153906d1f773d091500cf7c0f5bd5ddeccde12d5?apiKey=8e5983036f284a13866aae97819cdb73&", alt: "Social media icon 2" }, { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/8eb06097d9c6bb8cdbb5f7a8cb9831cb8f45b3d690966b8cc9adb025defefaca?apiKey=8e5983036f284a13866aae97819cdb73&", alt: "Social media icon 3" }, { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/ef76db6d738c49a10840817df0d456a250baa7d42656ca3a382f6f2c56b27163?apiKey=8e5983036f284a13866aae97819cdb73&", alt: "Social media icon 4" }, { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/ae752705b1e9e0089dcbb68c2d527bb8b2feabfb18c2fb46153cbec5d4324ced?apiKey=8e5983036f284a13866aae97819cdb73&", alt: "Social media icon 5" }, { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/5202f7e7e6c69e20dfb3d755e738b46fc8c3251f18df9c67a50ca8d77b3df76d?apiKey=8e5983036f284a13866aae97819cdb73&", alt: "Social media icon 6" },];
    return (
        <div className="flex gap-3 my-auto"> {socialMediaIcons.map((icon, index) => (
            <img key={index} loading="lazy" src={icon.src} alt={icon.alt} className="shrink-0 w-4 aspect-square" />))} </div>);
} 
function LanguageSelector() { 
    return (
    <div className="overflow-hidden relative flex-col justify-center px-px aspect-[2.2]"> 
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6259d493f90b121a01a7ba38b5dd3a046a6dd87dd11b0082475854cf846be6cb?apiKey=8e5983036f284a13866aae97819cdb73&" alt="English language flag" className="object-cover absolute inset-0 size-full" /> Eng </div>); 
    }
function CurrencySelector() {
    return (
    <div className="overflow-hidden relative flex-col justify-center px-1 aspect-[2.35]">
         <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/13b93dac7e79b0f1df66e645cba3286c4c9df891b227fdafdcd827cb059979a9?apiKey=8e5983036f284a13866aae97819cdb73&" alt="Indian Rupee currency symbol" className="object-cover absolute inset-0 size-full" /> INR </div>
         );
}
function Header() {
    return (<header className="flex justify-center items-center px-16 py-3 w-full bg-red-700 shadow-sm max-md:px-5 max-md:max-w-full">
        <div className="flex gap-5 justify-between w-full max-w-[1320px] max-md:flex-wrap max-md:max-w-full">
            <div className="my-auto text-sm leading-5 text-white"> Welcome to MyBzB{" "} </div>
            <div className="flex gap-5 justify-between py-1"> <div className="flex gap-3 justify-center">
                <div className="text-sm leading-5 text-white">Follow us:</div> <SocialMediaIcons />
            </div>
                <div className="flex gap-5 justify-between text-sm leading-5 text-white whitespace-nowrap">
                    <LanguageSelector /> <CurrencySelector /> </div> </div> </div> </header>
    );
}

export default Header;
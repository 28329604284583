import React, { useEffect, useState } from "react";
import * as Images from '../../../../../../assets';
import { useLocation, useNavigate } from "react-router-dom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { fetchWithFormDataToken, fetchWithSellerFormDataToken } from "../../../../../../apiGeneralFunction";
import ImgComp from "../../../../../ReusableComp/ImgComp";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SellerApprovedDetailpage = () => {

    const location = useLocation();
    const sellerId = location?.state?.sellerId;

    console.log("SellerApprovedDetailpage", sellerId);

    const [hoveredCard, setHoveredCard] = useState(null);

    const handleMouseEnter = (id) => {
        setHoveredCard(id);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [revenueData, setRevenueData] = useState({});
    const [totalRevenue, setTotalRevenue] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const controller = new AbortController();

    useEffect(() => {
        fetchSellerDashboardData();
        return () => {
            controller.abort();
        };
    }, []);

    const options = [
        { id: 1, title: 'Yearly' },
        { id: 2, title: 'Monthly' },
        { id: 3, title: 'Weekly' },
    ]

    const fetchSellerDashboardData = async () => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            // return;
        }

        const abortController = new AbortController();

        try {
            const formdata = new FormData();
            formdata.append("seller_id", sellerId);

            const result = await fetchWithSellerFormDataToken(`seller/dashboard`, formdata, storedToken, abortController);

            console.log("delete category response", result);
            if (result?.status === true) {
                setData(result?.data);
                setTotalRevenue(result?.data[0]?.total_revenue);
            }
            else {
            }
        } catch (error) {

        }
        finally {
            abortController.abort();
        }
    }

    const chartData = {
        labels: Object.keys(totalRevenue),
        datasets: [
            {
                label: 'Revenue',
                data: Object.values(totalRevenue).map(item => item.revenue),
                backgroundColor: 'rgba(172, 53, 51,0.8)',
                borderColor: 'rgba(172, 53, 51)',
                borderWidth: 1,
                barThickness: 10,
                borderRadius: 10,
                marginRight: 5,
            },
            {
                label: 'Profit',
                data: Object.values(totalRevenue).map(item => item.profit),
                backgroundColor: 'rgba(80, 171, 46,0.9)',
                borderColor: 'rgb(80, 171, 46)',
                borderWidth: 1,
                barThickness: 10,
                borderRadius: 10,
            }
        ]
    };

    const chartOptions = {
        scales: {
            x: {
                type: 'category',
                barPercentage: 0.5,
                categoryPercentage: 0.8,
                maxBarThickness: 50,
            },
            y: {
                beginAtZero: true,
            }
        },
    };

    const Card = ({ title, amount, isHovered, onMouseEnter, onMouseLeave, onClick }) => {
        return (
            <>
                <div
                    onClick={onClick}
                    className={`bg-white relative h-fit flex flex-col grow px-5 py-5 rounded-3xl shadow-red-50 shadow-lg max-md:px-5 max-md:mt-6 max-md:w-full max-md:max-w-full `}
                >
                    <div>
                        <ImgComp
                            src={Images.half_circle}
                            className="absolute h-24 max-lg:h-20 right-0 top-0 "
                        />
                    </div>
                    <div className="z-10 flex justify-start items-center ">
                        <div className="flex justify-between flex-col items-start gap-3">
                            <div
                                className="flex-auto my-auto text-base font-medium text-center text-neutral-500 max-md:max-w-full "
                            >
                                {title}
                            </div>
                            <div
                                className="text-xl text-black max-md:max-w-full text-center font-bold  max-lg:text-xl"
                                style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 4 }}
                            >₹ {Number(amount).toLocaleString('en-IN')}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const CardCount = ({ title, amount, src, onClick }) => {
        return (
            <div
                onClick={onClick}
                className={`w-full bg-white relative h-fit flex flex-col grow py-2 px-4 max-lg:px-2 rounded-3xl shadow-red-50 shadow-lg  max-md:w-full max-md:max-w-full `}
            >
                <div>
                    <ImgComp
                        src={Images.half_circle}
                        className="absolute h-24  max-lg:h-20 right-0 top-0"
                    />
                </div>
                <div className="z-10 flex justify-start items-center ">
                    <div className="flex gap-5 justify-between ">
                        <div className="flex justify-center items-center px-3.5  w-24 h-24 max-lg:w-20 max-lg:h-20 terms_bg_clr">
                            <ImgComp
                                src={src}
                                className="aspect-square "
                            />
                        </div>
                    </div>
                    <div className="flex justify-between flex-col items-start">
                        <div className="flex-auto my-auto text-sm max-lg:text-xs font-medium text-neutral-500 max-md:max-w-full ">
                            {title}
                        </div>
                        <div className="text-xl text-black max-md:max-w-full font-bold"
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 4 }}
                        >{amount}</div>
                    </div>
                </div>
            </div>
        )
    }

    // const OrderOverview = ({ order_pending, order_return, order_completed, ongoing_order }) => {
    //     return (
    //         <section className="flex flex-col w-[40%] max-md:w-full">
    //             <div className="flex flex-col grow self-stretch px-8 py-6 mx-auto w-full bg-white rounded-3xl max-md:px-5 max-md:mt-8">
    //                 <h2 className="text-xl font-semibold text-neutral-900">Order Overview</h2>
    //                 <div className="flex gap-5 mt-5">
    //                     <div
    //                         onClick={() => navigate('/dashboard/orders')}
    //                         className="flex flex-col flex-1 justify-center items-start p-4 bg-rose-50 rounded-2xl max-md:pr-5"
    //                     >
    //                         <div className="flex gap-1.5">
    //                             <div className="flex justify-center items-center px-2 w-10 h-10 bg-red-700 rounded-full">
    //                                 <ImgComp
    //                                     src={Images.dashboard_shop}
    //                                     className="w-6 aspect-square"
    //                                 />

    //                             </div>
    //                             <div className="my-auto text-base font-semibold text-neutral-900">{order_pending}</div>
    //                         </div>
    //                         <div className="mt-4 text-sm font-medium text-neutral-900">Order Pending</div>
    //                     </div>
    //                     <div
    //                         onClick={() => navigate('/dashboard/orders')}
    //                         className="flex flex-col flex-1 justify-center items-start p-4 bg-orange-100 rounded-2xl max-md:pr-5"
    //                     >
    //                         <div className="flex gap-2.5">
    //                             <div className="flex justify-center items-center px-2 w-10 h-10 bg-red-400 rounded-full">
    //                                 <ImgComp
    //                                     src={Images.dashboard_shop}
    //                                     className="w-6 aspect-square"
    //                                 />
    //                             </div>
    //                             <div className="my-auto text-lg font-semibold text-neutral-900">{order_return}</div>
    //                         </div>
    //                         <div className="mt-4 text-sm font-medium text-neutral-900">Order Return</div>
    //                     </div>
    //                 </div>
    //                 <div className="flex gap-5 mt-5">
    //                     <div className="flex flex-col flex-1 justify-center p-4 bg-green-100 rounded-2xl">
    //                         <div className="flex gap-2.5">
    //                             <div className="flex justify-center items-center px-2 w-10 h-10 bg-green-500 rounded-full">
    //                                 <ImgComp
    //                                     src={Images.dashboard_shop}
    //                                     className="w-6 aspect-square"
    //                                 />
    //                             </div>
    //                             <div className="my-auto text-lg font-semibold text-neutral-900">{order_completed}</div>
    //                         </div>
    //                         <div className="mt-4 text-sm font-medium text-neutral-900">Order Completed</div>
    //                     </div>
    //                     <div className="flex flex-col flex-1 justify-center p-4 bg-purple-100 rounded-2xl">
    //                         <div className="flex gap-2.5">
    //                             <div className="flex justify-center items-center px-2 w-10 h-10 bg-purple-400 rounded-full">
    //                                 <ImgComp
    //                                     src={Images.dashboard_shop}
    //                                     className="w-6 aspect-square"
    //                                 />
    //                             </div>
    //                             <div className="my-auto text-lg font-semibold text-neutral-900">{ongoing_order}</div>
    //                         </div>
    //                         <div className="mt-4 text-sm font-medium text-neutral-900">On Going Order</div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </section>
    //     );
    // }

    const OrderOverview = ({ order_pending, order_return, order_completed, ongoing_order }) => {
        return (
            <section className="flex flex-col w-full max-lg:w-full">
                <div className="flex flex-col gap-5 grow self-stretch px-8 py-6 mx-auto w-full bg-white rounded-3xl max-md:px-5 max-md:mt-8">
                    <h2 className="text-xl font-semibold text-neutral-900">Order Overview</h2>
                    <div className="grid grid-cols-4 gap-5">
                        <div className="flex flex-col flex-1 justify-center p-4 bg-rose-50 rounded-2xl"
                            // onClick={() => navigate('/dashboard/orders')}
                        >
                            <div className="flex gap-2.5">
                                <div className="flex justify-center items-center px-2 w-10 h-10 bg-red-700 rounded-full">
                                    <ImgComp
                                        src={Images.dashboard_shop}
                                        className="w-6 aspect-square"
                                    />
                                </div>
                                <div className="my-auto text-lg font-semibold text-neutral-900">{order_pending}</div>
                            </div>
                            <div className="mt-4 text-sm font-bold text-neutral-900">Order Pending</div>
                        </div>
                        <div className="flex flex-col flex-1 justify-center p-4 bg-orange-100 rounded-2xl"
                            // onClick={() => navigate('/dashboard/orders')}
                        >
                            <div className="flex gap-2.5">
                                <div className="flex justify-center items-center px-2 w-10 h-10 bg-red-400 rounded-full">
                                    <ImgComp
                                        src={Images.dashboard_shop}
                                        className="w-6 aspect-square"
                                    />
                                </div>
                                <div className="my-auto text-lg font-semibold text-neutral-900">{order_return}</div>
                            </div>
                            <div className="mt-4 text-sm font-bold text-neutral-900">Order Return</div>
                        </div>
                        <div className="flex flex-col flex-1 justify-center p-4 bg-green-100 rounded-2xl"
                            // onClick={() => navigate('/dashboard/orders')}
                        >
                            <div className="flex gap-2.5">
                                <div className="flex justify-center items-center px-2 w-10 h-10 bg-green-500 rounded-full">
                                    <ImgComp
                                        src={Images.dashboard_shop}
                                        className="w-6 aspect-square"
                                    />
                                </div>
                                <div className="my-auto text-lg font-semibold text-neutral-900">{order_completed}</div>
                            </div>
                            <div className="mt-4 text-sm font-bold text-neutral-900">Order Completed</div>
                        </div>
                        <div className="flex flex-col flex-1 justify-center p-4 bg-purple-100 rounded-2xl"
                            // onClick={() => navigate('/dashboard/orders')}
                        >
                            <div className="flex gap-2.5">
                                <div className="flex justify-center items-center px-2 w-10 h-10 bg-purple-400 rounded-full">
                                    <ImgComp
                                        src={Images.dashboard_shop}
                                        className="w-6 aspect-square"
                                    />
                                </div>
                                <div className="my-auto text-lg font-semibold text-neutral-900">{ongoing_order}</div>
                            </div>
                            <div className="mt-4 text-sm font-bold text-neutral-900">On Going Order</div>
                        </div>
                    </div>

                </div>
            </section>
        );
    }

    const OrderList = ({ orders }) => {
        return (
            <section className="bg-white flex flex-col p-4 grow w-full bg-white rounded-3xl max-md:px-5 max-md:max-w-full">
                <div className="flex gap-5 justify-between items-center max-md:flex-wrap max-md:max-w-full">
                    <h2 className="my-auto text-xl font-semibold text-neutral-900">Recent Order List</h2>
                    <div
                        className="cursor-pointer justify-center px-2 py-1 text-xs font-medium rounded-lg border border-gray-200 border-solid text-neutral-400"
                        onClick={() => navigate('/dashboard/orders')}
                    >
                        See all
                    </div>
                </div>
                <div className="flex gap-3 justify-between mt-8 text-sm font-medium max-md:flex-wrap">
                    <table className="w-full text-xs text-left rtl:text-right text-gray-500 ">
                        <thead className="text-xs text-gray-500  ">
                            <tr className='text-center'>
                                <th scope="col" className="px-3 py-2">
                                    Order ID
                                </th>
                                <th scope="col" className="px-3 py-2">
                                    Product ID
                                </th>
                                <th scope="col" className="px-3 py-2">
                                    Product Name
                                </th>
                                <th scope="col" className="px-3 py-2">
                                    Date
                                </th>
                                <th scope="col" className="px-3 py-2">
                                    Amount
                                </th>
                                <th scope="col" className="px-3 py-2">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders?.length > 0 ?
                                orders && orders?.reverse().map((item, index) => {
                                    const createdDate = item?.create_date;
                                    const dateOnly = new Date(createdDate).toLocaleDateString();
                                    const renderStatus = (status) => {
                                        console.log("status", status);
                                        switch (status) {
                                            case "1":
                                                return <span className="text-amber-600 bg-amber-50  px-3 rounded-lg">Pending</span>;
                                            case "2":
                                                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Confirm</span>;
                                            case "3":
                                                return <span className="text-blue-600  bg-blue-50 px-3 rounded-lg">Shipping</span>;
                                            case "4":
                                                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Delivered</span>;
                                            case "5":
                                                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Completed</span>;
                                            case "6":
                                                return <span className="text-red-600 bg-red-50 px-3 rounded-lg">Cancelled</span>;
                                            case "7":
                                                return <span className="text-amber-600 bg-amber-50  px-3 rounded-lg">Returned</span>;
                                            default:
                                                return <span className="text-gray-500">Unknown</span>;
                                        }
                                    };

                                    return (
                                        <tr
                                            className="bg-white text-black hover:bg-gray-50 text-center"
                                            key={index}
                                            // onClick={() => navigate('/dashboard/orders/order-detail')}
                                        >
                                            <td className="px-3 py-3">
                                                {item?.order_id}
                                            </td>
                                            <td className="px-3 py-3">
                                                {item?.product_id}
                                            </td>

                                            <td className="px-3 py-3">
                                                {item?.productname}
                                            </td>

                                            <td className="px-3 py-3">
                                                {item?.date}
                                            </td>
                                            <td className="px-3 py-3">
                                                ₹ {Number(item?.amount).toLocaleString('en-IN')}
                                            </td>
                                            <td className="px-3 py-3 font-bold">
                                                {renderStatus(item?.status)}
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <></>
                            }
                        </tbody>
                    </table>
                </div>
            </section>
        );
    }

    const ProductsListing = ({ products }) => {
        const last10Products = products?.slice(0, 10) || [];
        return (
            <section className="flex flex-col grow px-3 py-6 bg-white rounded-3xl max-md:px-5 max-md:mt-8 max-w-full">
                <div className="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full">
                    <h2 className="my-auto text-xl font-semibold text-neutral-900">Products Listing</h2>
                    <div
                        className="cursor-pointer justify-center px-4 py-3 text-sm font-medium rounded-lg border border-gray-200 border-solid text-neutral-400"
                        onClick={() => navigate('/dashboard/products')}
                    >
                        See all
                    </div>
                </div>
                <div className="flex justify-between mt-5 text-sm font-medium text-neutral-900 max-md:flex-wrap">
                    <table className="w-full text-xs text-left rtl:text-right text-gray-500 ">
                        <thead className="text-sm text-gray-700   ">
                            <tr className='text-center'>

                                <th scope="col" className="px-2 py-3">
                                    Product
                                </th>
                                <th scope="col" className="px-2 py-3">
                                    Product Name
                                </th>
                                <th scope="col" className="px-2 py-3">
                                    In Stock
                                </th>
                                <th scope="col" className="px-2 py-3">
                                    Sold
                                </th>
                                <th scope="col" className="px-2 py-3">
                                    Status
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {last10Products?.length > 0 ?
                                last10Products && last10Products?.map((item, index) => {
                                    return (
                                        <tr className="bg-white hover:bg-gray-50 text-center"
                                            // onClick={() => navigate('/dashboard/products/product-detail')}
                                            key={index}
                                        >

                                            {/* <td className="px-6 py-4">
                                            <ProfileImageComponent
                                                    profilePicUrl={item?.profile_pic}
                                                    className={`w-12 h-12 rounded-full border`}
                                                />
                                            </td> */}
                                            <td className="px-6 py-4 flex justify-center items-center">
                                                <img src={item?.image} alt="" className="w-12 h-12 rounded-full" />
                                            </td>


                                            <td className="px-6 py-4">
                                                {item?.name}
                                            </td>

                                            <td className="px-6 py-4">
                                                {item?.in_stock}
                                            </td>
                                            <td className="px-6 py-4">
                                                {item?.sold}
                                            </td>
                                            <td className="px-6 py-4 capitalize ">
                                                <div className="bg-green-50 text-green-700 w-fit mx-auto px-2.5 py-1.5">
                                                    {item?.status}
                                                </div>
                                            </td>

                                        </tr>
                                    )
                                })
                                :
                                <>
                                    <tr>
                                        <td colSpan="5" className="text-center py-4">No products available</td> {/* Optional: message when no products are available */}
                                    </tr>
                                </>
                            }


                        </tbody>
                    </table>
                </div>
            </section>
        );
    }

    const backSellerList = () => {
        navigate('/dashboard/sellers')
    }


    const handleOptionClick = (id) => {
        setSelectedId(id);
        setIsOpen(false);
    };


    return (
        <>
            <div
                className="text-neutral-500 text-sm font-bold flex items-center gap-3 cursor-pointer mb-5"
                onClick={backSellerList}  
            >
                <i className="fa fa-arrow-left text-neutral-500"></i> Back to Sellers listing
                
            </div>
            <div className="flex flex-col self-stretch  rounded-xl max-md:px-5">
                {data && data?.length > 0 && data?.map((item, index) => {
                    return (
                        <>
                            {/* <div className="flex items-center justify-between" key={index}>
                                <div className="flex-1 gap-5 w-full max-md:flex-wrap max-md:max-w-full grid grid-cols-4 w-full">
                                    <div className="flex flex-col gap-5">
                                        <Card
                                            id="total_transaction"
                                            isHovered={hoveredCard === 'total_transaction'}
                                            onMouseEnter={() => handleMouseEnter('total_transaction')}
                                            onMouseLeave={handleMouseLeave}
                                            title={`Total Transaction amount`}
                                            amount={item?.total_transaction_amount}
                                        />
                                        <Card
                                            id="today_transaction"
                                            isHovered={hoveredCard === 'today_transaction'}
                                            onMouseEnter={() => handleMouseEnter('today_transaction')}
                                            onMouseLeave={handleMouseLeave}
                                            title={`Today’s Transaction amount`} amount={item?.today_transaction_amount} />
                                    </div>
                                    <div className="flex flex-col gap-5">
                                        <CardCount title={`Total Orders`} amount={item?.total_orders} src={Images.icn_total_order} />
                                        <CardCount title={`Today’s Orders`} amount={item?.today_orders} src={Images.icn_total_order} />
                                    </div>

                                    <div className="flex flex-col gap-5">
                                        <CardCount title={`Total Product Count`} amount={item?.total_product_count} src={Images.icn_total_product} />
                                        <CardCount title={`Today’s Product Count`} amount={item?.today_orders} src={Images.icn_total_product} />
                                    </div>

                                    <div className="flex flex-col gap-5">
                                        <CardCount title={`Total Customers`} amount={item?.total_customers_count} src={Images.icn_total_customers} />
                                        <CardCount title={`Today’s New Customers`} amount={item?.today_customer_count} src={Images.icn_total_customers} />
                                    </div>
                                </div>
                            </div> */}
                            <div className="flex items-center justify-between" key={index}>
                                <div
                                    className="flex  w-full  max-lg:grid-cols-1 grid grid-cols-4 gap-5"
                                >
                                    <div className="flex w-full gap-5 flex-col max-lg:flex-row">
                                        <Card
                                            id="total_transaction"
                                            isHovered={hoveredCard === 'total_transaction'}
                                            // onMouseEnter={() => handleMouseEnter('total_transaction')}
                                            // onMouseLeave={handleMouseLeave}
                                            title={`Total Transaction amount`}
                                            amount={item?.total_transaction_amount}
                                            // onClick={() => navigate('/dashboard/transactions')}
                                        />
                                        <Card
                                            id="today_transaction"
                                            isHovered={hoveredCard === 'today_transaction'}
                                            // onMouseEnter={() => handleMouseEnter('today_transaction')}
                                            // onMouseLeave={handleMouseLeave}
                                            title={`Today’s Transaction amount`} amount={item?.today_transaction_amount}
                                            // onClick={() => navigate('/dashboard/transactions')}
                                        />
                                    </div>
                                    <div className="flex w-full gap-5 flex-col  max-lg:flex-row">
                                        <CardCount
                                            title={`Total Orders`}
                                            amount={item?.total_orders}
                                            src={Images.icn_total_order}
                                            // onClick={() => navigate('/dashboard/orders')}
                                        />
                                        <CardCount
                                            title={`Today’s Orders`}
                                            amount={item?.today_orders}
                                            src={Images.icn_total_order}
                                            // onClick={() => navigate('/dashboard/orders')}
                                        />
                                    </div>
                                    <div className="flex w-full gap-5 flex-col  max-lg:flex-row">
                                        <CardCount
                                            title={`Total Product Count`}
                                            amount={item?.total_product_count}
                                            src={Images.icn_total_product}
                                            // onClick={() => navigate('/dashboard/products')}
                                        />
                                        <CardCount
                                            title={`Today’s Product Count`}
                                            amount={item?.today_orders}
                                            src={Images.icn_total_product}
                                            // onClick={() => navigate('/dashboard/products')}
                                        />
                                    </div>
                                    <div className="flex w-full gap-5 flex-col  max-lg:flex-row">
                                        <CardCount
                                            title={`Total Customers`}
                                            amount={item?.total_customers_count}
                                            src={Images.icn_total_customers}
                                            // onClick={() => navigate('/dashboard/customers')}
                                        />
                                        <CardCount
                                            title={`Today’s New Customers`}
                                            amount={item?.today_customer_count}
                                            src={Images.icn_total_customers}
                                            // onClick={() => navigate('/dashboard/customers')}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex  items-center grid grid-cols-2 gap-10 bg-gray-50 my-8">
                                <OrderList orders={item?.recent_order_list} />
                                <div
                                    className="flex flex-col items-center justify-center w-full bg-white h-full p-4 rounded-3xl"
                                >
                                    <div className="flex gap-5 justify-between items-center w-full max-md:flex-wrap max-md:max-w-full">
                                        <div className="my-auto text-xl font-semibold text-neutral-900">Total Revenue</div>
                                        <div className=" relative">
                                            <div
                                                onClick={() => setIsOpen(!isOpen)}
                                                className='font-bold  cursor-pointer flex items-start gap-3 bg-white px-2.5 rounded-lg py-1  border border-neutral-300'
                                            >
                                                <div>
                                                    <div className='text-xs text-neutral-400 '>Yearly</div>
                                                </div>
                                                <i className="fa fa-angle-down text-xs  text-neutral-400"></i>
                                            </div>
                                            {isOpen && (
                                                <div
                                                    id="chart_menu"
                                                    className="absolute left-0  py-2 top-full mt-2 w-full bg-white z-10 rounded-lg border border-gray-200">
                                                    {options && options?.length > 0 && options?.map((option) => (
                                                        <div
                                                            key={option?.id}
                                                            onClick={() => handleOptionClick(option.id)}
                                                            className={`px-4 py-1 font-bold cursor-pointer text-xs ${selectedId === option.id ? 'bg-red-800 text-white' : 'text-neutral-400 hover:bg-red-800 hover:text-white'}`}
                                                        >
                                                            {option.title}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <Bar data={chartData} options={chartOptions} />
                                </div>
                            </div>
                            <div className="flex gap-5 w-full flex-col">
                                <OrderOverview
                                    order_pending={item?.order_overview?.order_pending}
                                    order_return={item?.order_overview?.order_return}
                                    order_completed={item?.order_overview?.order_completed}
                                    ongoing_order={item?.order_overview?.ongoing_order}
                                />

                                <ProductsListing products={item?.product_listing} />
                                {/* <TransactionHistory transactions={transactions} /> */}
                            </div>
                        </>
                    )
                })}
            </div>
        </>
    );
}

export default SellerApprovedDetailpage;
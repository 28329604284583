import React from 'react';
import Navbar from '../../ReusableComp/Layout/NavbarComp/Navbar';
import { Route, Routes } from 'react-router-dom';
import SellerApprovedDetailpage from './SellerList/SellerApprovedList/SellerApprovedDetailpage';
import SellerList from './SellerList';


const Sellerspage = () => {
  return (
    <>
      <Navbar name={'Sellers'} />
      <div className='p-10 bg-gray-50 h-full w-full'>
        <Routes>
          <Route path="/" element={<SellerList />} />
          <Route path="/seller-approved-detail" element={<SellerApprovedDetailpage />} />
        </Routes>
      </div>
    </>
  )
}

export default Sellerspage
import React, { useEffect, useState } from "react";
import Layout from "../../ReusableComp/Layout";
import * as Images from '../../../assets';
import { fetchWithFormData, post, postFormData } from "../../../apiGeneralFunction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImgComp from '../../ReusableComp/ImgComp';
import { useNavigate } from "react-router-dom";
import { isValidEmail, isValidPassword } from "../../../Validation";
import toastPromise from "../../../toastService";

const Register = () => {

    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [newEmail, setNewEmail] = useState('');

    useEffect(() => {
        const handleBodyScroll = () => {

            document.body.style.overflow = isModalOpen ? 'hidden' : 'auto';
        };

        document.addEventListener('scroll', handleBodyScroll, { passive: false });
        return () => {
            document.removeEventListener('scroll', handleBodyScroll);
        };
    }, [isModalOpen]);


    const handleSubmit = async () => {

        toast.dismiss();
        if (!email && !password) {
            toast.error('Please enter your Email ID and password', {
                position: 'top-center',
                autoClose: 2000,
            });
            return;
        }
        else if (!email) {
            toast.error('Please enter your Email ID', {
                position: 'top-center',
                autoClose: 2000,
            });
            return;
        }
        else if (!isValidEmail(email)) {
            toast.error('Please enter a valid email address.', {
                position: 'top-center',
                autoClose: 2000,
            });
            return;
        }
        else if (!password) {
            toast.error('Please enter your password', {
                position: 'top-center',
                autoClose: 2000,
            });
            return;
        }

        // else if (!isValidPassword(password)) {
        //     toast.error('Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character.', {
        //         position: 'top-center',
        //         autoClose: 5000,
        //     });
        //     return;
        // }
        else {

            const abortController = new AbortController();

            try {

                const formdata = new FormData();
                formdata.append("email", email);
                formdata.append("password", password);

                // const result = await fetchWithFormData('login', formdata, null, abortController);
                // if (result?.status === true) {
                //     console.log("login api hit", result);
                //     localStorage.setItem("login data", JSON.stringify(result?.data))
                //     localStorage.setItem("token", JSON.stringify(result?.data?.token))
                //     localStorage.setItem("login api response token", JSON.stringify(result?.data?.token))
                //     toast.success(result?.message, {
                //         position: 'top-center',
                //         autoClose: 2000,
                //         onClose: () => {
                //             navigate('/dashboard');
                //         }
                //     });
                // }
                // else {
                //     toast.error(result?.message, {
                //         position: 'top-center',
                //         autoClose: 3000,
                //         onClose: () => {
                //             navigate('/');
                //         }
                //     });
                //     setEmail('')
                //     setPassword('')

                // }
                const response = await toastPromise(
                    fetchWithFormData('login', formdata, null, abortController),
                    {
                        // pending: "Logging in...",
                        success: (data) => data?.message || "Login successful!",
                        error: (data) => "Failed to log in.",
                    }
                );

                if (response?.status === true) {
                    localStorage.setItem("login data", JSON.stringify(response?.data));
                    localStorage.setItem("token", JSON.stringify(response?.data?.token));
                    localStorage.setItem("login api response token", JSON.stringify(response?.data?.token));
                    setTimeout(() => {
                        navigate('/dashboard');
                    }, 2000);
                } else {
                    setEmail('');
                    setPassword('');
                }
            } catch (error) {
            }
            finally {

                abortController.abort();
            }

        }
    };

    const forgotPassword = async () => {

        toast.dismiss();
        if (!newEmail) {
            toast.error('Please enter your Email ID', {
                position: 'top-center',
                autoClose: 2000,
            });
            return;
        }
        else if (!isValidEmail(email)) {
            toast.error('Please enter a valid email address.', {
                position: 'top-center',
                autoClose: 1500,
            });
            return;
        }
        else {
            const abortController = new AbortController();
            try {
                const formdata = new FormData();
                formdata.append("email", newEmail);

                const result = await fetchWithFormData(`forget_password`, formdata, null, abortController);
                console.log("forget_password api hit", result);
                if (result?.status === true) {

                    toast.success(result?.message, {
                        position: 'top-center',
                        autoClose: 2000,
                        onClose: () => {
                            navigate('/reset');
                        }
                    });

                    localStorage.setItem("reset_emailid", newEmail)
                }
                else {
                    toast.error(result?.message, {
                        position: 'top-center',
                        autoClose: 3000,
                        onClose: () => {
                            navigate('/');
                        }
                    });
                    setNewEmail('')

                }
            } catch (error) {


            }
            finally {

                abortController.abort();
            }

        }
    };
    // const data = [{ id: 1, title: "Privacy Policy", },
    // { id: 2, title: "Terms & Conditions", },];

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            handleSubmit();
        }
    };

    return (

        <main className="flex justify-center items-center px-16 py-36 bg-white max-lg:px-5">
<div className="max-w-full rounded-2xl shadow-2xl w-[1026px] ">
<div className="flex max-lg:flex-col max-lg:gap-0 ">
            <div className="hide-at-1021px self-stretch w-6/12 max-lg:ml-0 max-lg:w-full">
                <ImgComp
                    src={Images.register}
                    srcSet={Images.register}
                    alt={'register'}
                    className={'w-full h-full rounded-r-2xl max-lg:rounded-b-2xl max-lg:rounded-t-none'}
                />
            </div>
            <div className="flex flex-col w-6/12 max-lg:ml-0 max-lg:w-full">
                <section
                    className=" flex flex-col grow justify-between rounded-2xl items-center self-stretch py-5 w-full bg-white max-lg:px-5 max-lg:max-w-full"
                >
                    <div className="w-[80%] mx-auto max-md:w-full">
                        <div className="px-10 hide-logo-at-larger-screens">
                            <ImgComp
                                src={Images.logo}
                                srcSet={Images.logo}
                                alt={'Company logo'}
                                className={'w-36 mx-auto'}

                            />
                        </div>
                        <div className=" text-3xl leading-10 text-center ">
                            <span className="">Welcome to </span> <span className="font-bold mybzb_text_clr">My BzB</span>
                        </div>
                        <p className="self-stretch my-2 text-sm text-center text-neutral-400 max-lg:max-w-full">
                            India's First My Bharatiya Zaveri Bazar Retail Culture Jewellery Store </p>
                        <div
                            className=" self-stretch "
                        >
                            <div>
                                <label htmlFor="emailOrMobile" className="self-stretch  max-md:text-sm  text-base font-medium text-neutral-400 max-lg:mt-10 max-lg:mr-2.5 max-lg:max-w-full" >
                                    Email
                                </label>
                                <div
                                >
                                    <div className="w-full" >
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Enter your email address"
                                            value={email}
                                            required
                                            onChange={(e) => setEmail(e.target.value)}
                                            className="mt-1 py-3  max-md:text-sm  rounded-e-lg border rounded-lg border-solid border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] max-w-full max-lg:max-w-full focus:border-gray-200 focus:ring-0 w-full"
                                            autofocus
                                            onKeyPress={handleKeyPress}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="mt-3">
                                <label htmlFor="emailOrMobile" className="self-stretch   text-base font-medium text-neutral-400 max-lg:mt-10 max-lg:mr-2.5 max-lg:max-w-full" >
                                    Password
                                </label>
                                <div className="w-full relative mt-1" >
                                    <input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        // type="text"
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        required
                                        placeholder="Enter your password"
                                        className="  max-md:text-sm py-3  rounded-e-lg border rounded-lg border-solid border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] max-lg:max-w-full focus:border-gray-200 focus:ring-0 w-full"
                                        onKeyPress={handleKeyPress}
                                    />
                                    <div
                                        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                        onClick={togglePasswordVisibility}
                                    >
                                        <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'} text-gray-500`}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full my-5">
                            <div className="mybzb_text_clr text-end cursor-pointer  max-md:text-xs"
                                onClick={() => setIsModalOpen(true)}
                            >
                                Forgot Password ?
                            </div>
                        </div>
                        <button
                            onClick={handleSubmit}
                            className="max-md:text-sm max-md:py-3 text-center w-full justify-center rounded-lg focus:outline-0 items-center px-16 py-4 text-lg font-medium bg-red-700 text-white rounded max-lg:px-5 max-lg:mx-1.5" >
                            Submit
                        </button>
                    </div>

                    {/* <ul className=" max-md:text-xs flex gap-5 justify-between self-stretch my-5 text-sm text-center text-neutral-400 max-lg:flex-wrap max-lg:max-w-full"> {data.map((item) => (<li key={item.id}>{item.title}</li>))}
                    </ul> */}
                    <div className="mybzb_text_clr mt-2 max-md:text-sm max-md:my-0">
                        Powered by Headway Business Solutions LLP
                    </div>
                </section>
            </div>

            {isModalOpen && (
                <div
                    style={{ backgroundColor: 'rgb(0 0 0 / 40%)' }}
                    className=" backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full"
                >

                    <div 
                    // className=' bg-white rounded-2xl max-md:h-full w-full max-w-lg zoom_in_pp_modal p-6'
                    className=' bg-white rounded-2xl w-full max-w-lg zoom_in_pp_modal p-6'
                    >
                        {/* <div className=" relative bg-white w-full max-w-2xl max-h-full rounded-2xl"> */}
                            {/* <div className="relative bg-white rounded-2xl shadow-lg  p-6"> */}

                                <div className='flex items-center justify-center'>
                                    <div className=' text-2xl font-semibold w-full text-left max-md:text-xl'> Forgot Password</div>
                                    <div className='w-auto text-end '>
                                        <button type="button" className="focus:border-0 focus:ring-0 focus:outline-0 bg-white text-black hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-10 h-10 ms-auto inline-flex justify-center items-center "
                                            onClick={closeModal}
                                            data-modal-hide="popup-modal">
                                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                            </svg>
                                            <span className="sr-only">Close modal</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="my-3 text-base font-medium text-neutral-400 max-md:text-xs">
                                    Enter Your Registered email below to receive password reset instruction
                                </div>
                                <div className='w-full flex flex-row divide-x border p-1 max-md:p-0 rounded-lg max-lg:flex-col max-lg:divide-y max-lg:divide-x-0 '>
                                    <div className="p-2 md:p-5 text-center max-sm:p-0  w-full">
                                        <div
                                            className="flex flex-col max-lg:ml-0 max-lg:w-auto justify-end h-full  "
                                            style={{ maxHeight: '-webkit-fill-available !important' }}
                                        >
                                            <div className='  h-full '
                                                style={{ maxHeight: '-webkit-fill-available !important' }}
                                            >
                                                <div
                                                    className="items-end flex flex-col grow max-md:p-5 w-full bg-white rounded-3xl ">
                                                    <div className="w-full text-start">
                                                        <label htmlFor="emailOrMobile" className="self-stretch max-md:text-sm text-base font-bold text-neutral-400 max-lg:mt-10 max-lg:mr-2.5 max-lg:max-w-full" >
                                                            Email
                                                        </label>
                                                        <div
                                                        >
                                                            <div className="w-full" >
                                                                <input
                                                                    type="email"
                                                                    name="phone"
                                                                    value={newEmail}
                                                                    placeholder="Enter your email address"
                                                                    onChange={(e) => setNewEmail(e.target.value)}
                                                                    required
                                                                    className="mt-2 max-md:text-sm p-3 rounded-e-lg border rounded-lg border-solid border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] max-lg:max-w-full focus:border-gray-200 focus:ring-0 w-full"
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <button
                                                        onClick={() => forgotPassword()}
                                                        className="max-md:text-sm text-center w-full justify-center rounded-lg focus:outline-0 items-center px-16 py-3 text-lg font-bold bg-red-700 text-white rounded max-lg:px-5  mt-5" >
                                                        Submit
                                                    </button>
                                                    <div className="w-full mt-3">
                                                        <div className=" text-base max-md:text-sm font-bold text-neutral-400 text-center">
                                                            Remember password? &nbsp;
                                                            <span className="mybzb_text_clr cursor-pointer" onClick={() => setIsModalOpen(false)}>
                                                                Login
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            {/* </div> */}

                        {/* </div> */}
                    </div>
                </div>
            )}
            </div>
</div>
            <ToastContainer />
        </main>


    );
}

export default Register
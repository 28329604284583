
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../../apiGeneralFunction';
import ImgComp from '../../../ReusableComp/ImgComp';

import NewSellerEnquiry from './NewSellerEnquiry';
import SellerApprovedList from './SellerApprovedList';


const SellerList = () => {

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(0);

    const tabs = ['Sellers', 'New Sellers Enquiry'];

    const content = [
        <SellerApprovedList sellerDetail={navigate} />,
        <NewSellerEnquiry />
    ];

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <>
            <div className="flex border w-fit rounded-lg bg-white ">
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`cursor-pointer px-10 py-2 text-sm font-bold max-md:text-xs max-md:px-6 ${activeTab === index ? 'mybzb_bg_clr text-white border border-b-1 border-x-0 border-t-0 border-red-800 ' : 'text-neutral-500'
                            } ${tab === 'Sellers' ? 'rounded-l-lg' : ' rounded-r-lg'}  `}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab}
                    </div>
                ))}
            </div>
            <div className="mt-5 bg-white rounded-3xl overflow-x-auto w-full max-w-full">{content[activeTab]}</div>
        </>
    )
}

export default SellerList
import logo from './logo.svg';
import './App.css';
import Layout from './component/ReusableComp/Layout';
import { Route, Routes } from 'react-router-dom';
import Register from './component/UserAuth/Register';
import UserAuth from './component/UserAuth';
import Homepage from './component/Pages/Homepage';
import SidebarLayout from './component/ReusableComp/Layout/SidebarLayout';
import ResetPassword from './component/UserAuth/ResetPassword';
import Productspage from './component/Pages/Productspage';
import Sellerspage from './component/Pages/Storespage';
import Orderspage from './component/Pages/Orderspage';
import Reportspage from './component/Pages/Reportspage';
import Reviewspage from './component/Pages/Reviewspage';
import Subscriptionpage from './component/Pages/Subscriptionpage';
import Transactionspage from './component/Pages/Transactionspage';
import Settingspage from './component/Pages/Settingspage';
import Customerspage from './component/Pages/Customerspage';
import Generalpage from './component/Pages/Generalpage';
import CMSpage from './component/Pages/CMSpage';
import UserProfile from './component/Pages/UserProfile';



function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<UserAuth />} />
        <Route path="/reset" element={<ResetPassword />} />
       
        {/* <Route path="/home" element={<Homepage />} /> */}
        {/* <Route path="/dashboard" element={<SidebarLayout />} /> */}
        <Route path="dashboard" element={<SidebarLayout />} >
          <Route index element={<Homepage />} />
          <Route path="dashboard" element={<Homepage />} />
          <Route path="sellers/*" element={<Sellerspage />} />
          <Route path="customers/*" element={<Customerspage />} />
          <Route path="orders/*" element={<Orderspage />} />
          <Route path="products/*" element={<Productspage />} />
          <Route path="reports/*" element={<Reportspage />} />
          <Route path="reviews" element={<Reviewspage />} />
          <Route path="subscription" element={<Subscriptionpage />} />
          <Route path="transactions" element={<Transactionspage />} />
          <Route path="general" element={<Generalpage />} />
          <Route path="cms/*" element={<CMSpage />} />
          <Route path="settings" element={<Settingspage />} />
          <Route path="profile" element={<UserProfile />} />
        </Route> 
      </Routes>
    </>
  );
}

export default App;

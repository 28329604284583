import React from 'react'
import Navbar from '../../ReusableComp/Layout/NavbarComp/Navbar'
import SubscriptionList from './SubscriptionList'



const Subscriptionpage = () => {
  return (
    <>
      <Navbar name={'Subscription'} />
      <div className='p-10 bg-gray-50'>
        <SubscriptionList />
      </div>
    </>
  )
}

export default Subscriptionpage
import React from 'react'
import Navbar from '../../ReusableComp/Layout/NavbarComp/Navbar'
import { Route, Routes } from 'react-router-dom'
import ProductList from './ProductList'
import ProductDetailpage from './ProductDetailpage'



const Productspage = () => {
  return (
    <>
      <Navbar name={'Products'} />
      <div className='p-10 bg-gray-50'>
        <Routes>
          <Route path="/" element={<ProductList />} />
          <Route path="/product-detail" element={<ProductDetailpage />} />
        </Routes>

      </div>
    </>
  )
}

export default Productspage
import React from 'react';

export const FormattedDate = ({ date }) => {
    
  if (!date) return null;

  const datePart = date.split(' ')[0];
  const [year, month , day] = datePart.split('-'); 
  const formattedDate = `${day}-${month}-${year}`;
  const finalFormattedDate = formattedDate.replace(/\//g, '-');

  return <span>{finalFormattedDate}</span>;
}; 

  
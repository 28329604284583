
import { toast } from 'react-toastify';

const toastPromise = (promise, messages) => {
  return toast.promise(promise, {
    pending: {
      render() {
        return messages.pending || "Processing...";
      },
      position: 'top-center',
      autoClose: false,
    },
    success: {
      render({ data }) {
        if (data.status === false) {
          throw new Error(data.message);
        }
        return data.message || "Successful !";
      },
      position: 'top-center',
      autoClose: 2000,
    },
    error: {
      render({ data }) {
        return messages.error(data) || "An error occurred.";
      },
      position: 'top-center',
      autoClose: 2000,
    }
  });
};

export default toastPromise;  

import React, { useEffect, useState } from 'react';
import * as Images from '../../../../assets';
import ModalBoxWrapper from '../../../ReusableComp/ModalBoxWrapper';
import ImgComp from '../../../ReusableComp/ImgComp';
import { fetchDataWithToken, fetchWithFormDataToken } from '../../../../apiGeneralFunction';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteModalBox from '../../../ReusableComp/DeleteModalBox';
import { ExportIcon, SortIcon } from '../../../ReusableComp/Icons';
import TrimmedComponent from '../../../ReusableComp/TrimmedComponent';
import { FormattedDate } from '../../../ReusableComp/FormattedDate';
import TableNoDataFound from '../../../ReusableComp/TableNoDataFound';
import Loader from '../../../ReusableComp/Loader';


const Metals = () => {

    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

    const [metalList, setMetalList] = useState([]);
    const [name, setName] = useState('');
    const [unit, setUnit] = useState('');

    const [nameUpdate, setNameUpdate] = useState('');
    const [idUpdate, setIdUpdate] = useState('');
    const [unitUpdate, setUnitUpdate] = useState('');
    const [loading, setLoading] = useState(true);

    const controller = new AbortController();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openEditModal = () => {
        setIsModalEditOpen(true);
    };

    const closeEditModal = () => {
        setIsModalEditOpen(false);
    };

    const updateItem = (item) => {
        setIdUpdate(item?.id)
        setIsModalEditOpen(true);
        setNameUpdate(item?.name)
        setUnitUpdate(item?.measuring_unit)
    };

    const updateAPI = async () => {
        const tokenValue = localStorage?.getItem('token');
        const storedToken = JSON?.parse(tokenValue);
        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);
        if (!storedToken) {
            navigate('/');
            return;
        }
        const abortController = new AbortController();
        try {
            const formdata = new FormData();

            formdata.append("id", idUpdate);
            formdata.append("name", nameUpdate);
            formdata.append("measuring_unit", unitUpdate);

            const result = await fetchWithFormDataToken('update-product-metal', formdata, storedToken, abortController);

            if (result?.status === true) {
                toast.success(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
                fetchData();
                setIsModalEditOpen(false);
            }
            else {
                toast.error(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    }

    const addAPI = async () => {

        const tokenValue = localStorage?.getItem('token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }

        const abortController = new AbortController();

        try {

            const formdata = new FormData();

            formdata.append("name", name);
            formdata.append("measuring_unit", unit);

            const result = await fetchWithFormDataToken('add-product-metal', formdata, storedToken, abortController);

            console.log("add product-color response", result);
            if (result?.status === true) {
                toast.success(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });

                fetchData();
                setName('');
                setUnit('');
                setIsModalOpen(false)

            }
            else {
                toast.error(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
                setName('');
                setUnit('');
            }
        } catch (error) {

        }
        finally {
            abortController.abort();
        }
    };

    const deleteItem = (item) => {

        console.log("item update", item);
        setIdUpdate(item?.id)
        setIsModalDeleteOpen(true);
        setNameUpdate(item?.name)
    };

    const deleteAPI = async () => {


        const tokenValue = localStorage?.getItem('token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }
        const abortController = new AbortController();

        try {

            const formdata = new FormData();


            formdata.append("id", idUpdate);

            const result = await fetchWithFormDataToken('delete-product-metal', formdata, storedToken, abortController);

            console.log("delete category response", result);
            if (result?.status === true) {
                toast.success(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
                fetchData();
                setIsModalDeleteOpen(false);
            }
            else {
                toast.error(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
                setIsModalDeleteOpen(false);
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }


    };

    const modalContent = (
        <>
            <div className='flex flex-col h-full '>
                <div className='text-left mb-5 font-bold text-xl'>
                    Metal Information
                </div>
                <div className='flex justify-between gap-5 h-full '>

                    <div className='flex flex-col items-start w-full'>
                        <label className='text-neutral-500'>Metal Type
                            <span className='mybzb_text_clr'> *</span>
                        </label>
                        <input
                            className='mt-3 w-full border border-gray-200 rounded-lg h-12 focus:ring-0 focus:border-gray-200'
                            placeholder='Metal Type'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />

                    </div>
                    <div className='flex flex-col items-start w-full'>
                        <label className='text-neutral-500'>Measuring Unit
                            <span className='mybzb_text_clr'> *</span>
                        </label>

                        <input
                            className='mt-3 w-full border border-gray-200 rounded-lg h-12 focus:ring-0 focus:border-gray-200'
                            placeholder='Measuring Unit'
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                        />

                    </div>

                </div>
            </div>

        </>
    );

    const buttonContent = (
        <>
            <div className='flex items-center gap-5 justify-end mt-3'>
                <button
                    onClick={() => setIsModalOpen(false)}
                    className='border border-red-500 mybzb_text_clr text-base px-5 py-2 rounded-lg'
                >
                    Cancel
                </button>
                <button
                    onClick={() => addAPI()}
                    className='border border-red-500 mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg'
                >
                    Submit
                </button>
            </div>
        </>
    )

    const modalEditContent = (
        <>
            <div className='flex flex-col  '>
                <div className='text-left mb-5 font-bold text-xl'>
                    Metal Information
                </div>
                <div className='flex justify-between gap-5 h-full '>

                    <div className='flex flex-col items-start w-full'>
                        <label className='text-neutral-500'>Metal Type
                            {/* <span className='mybzb_text_clr'> *</span> */}
                        </label>
                        <input
                            className='mt-3 w-full border border-gray-200 rounded-lg h-12 focus:ring-0 focus:border-gray-200'
                            placeholder='Enter Name'
                            value={nameUpdate}
                            onChange={(e) => setNameUpdate(e.target.value)}
                        />

                    </div>
                    <div className='flex flex-col items-start w-full'>
                        <label className='text-neutral-500'>Measuring Unit
                            {/* <span className='mybzb_text_clr'> *</span> */}
                        </label>

                        <input
                            className='mt-3 w-full border border-gray-200 rounded-lg h-12 focus:ring-0 focus:border-gray-200'
                            type='text'
                            placeholder='Enter measuring unit'
                            value={unitUpdate}
                            onChange={(e) => setUnitUpdate(e.target.value)}
                        />


                    </div>

                </div>
            </div>

        </>
    );

    const buttonEditContent = (
        <>
            <div className='flex items-center gap-5 justify-end mt-3'>
                <button
                    onClick={() => setIsModalEditOpen(false)}
                    className='border border-red-500 mybzb_text_clr text-base px-5 py-2 rounded-lg'
                >
                    Cancel
                </button>
                <button
                    onClick={() => updateAPI()}
                    className=' mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg'
                >
                    Save Changes
                </button>
            </div>
        </>
    )

    const modalDeleteContent = (
        <>
            <div className='text-center text-xl mb-5 px-5'>
                Are you sure want to delete this {nameUpdate} ?
            </div>

        </>
    );

    const buttonDeleteContent = (
        <>
            <div className='flex items-center gap-5 justify-end mt-3'>
                <button
                    className='border border-red-500 mybzb_text_clr text-base px-5 py-2 rounded-lg w-full'
                    onClick={() => setIsModalDeleteOpen(false)}
                >
                    No
                </button>
                <button
                    className=' mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg w-full'
                    onClick={() => deleteAPI()}
                >
                    Yes
                </button>
            </div>
        </>
    );

    useEffect(() => {
        fetchData();
        return () => {
            controller.abort();
        };
    }, []);

    const fetchData = async () => {
        setLoading(true)
        try {
            const tokenValue = localStorage?.getItem('token');
            const storedToken = JSON?.parse(tokenValue);
            if (!storedToken) {
                navigate('/');
                return;
            }
            const result = await fetchDataWithToken('list-product-metal', storedToken);
            if (result?.status === true) {
                setMetalList(result?.data)
            }
        } catch (error) {
        }
        finally {
            setLoading(false)
        }
    };


    return (
        <>
            <div className='w-full flex justify-between items-center bg-white p-5 rounded-tr-3xl'>
                <div className='font-bold text-2xl'>Metals</div>
                <div className='flex items-center gap-5 text-sm'>
                    {/* <SortIcon /> */}
                    <ExportIcon />
                    <div onClick={openModal} className='mybzb_bg_clr text-white px-3 py-1 rounded-lg cursor-pointer'>
                        <i className='fa fa-plus me-3'></i>
                        Add
                    </div>
                </div>
            </div>


            <div class=" shadow-md sm:rounded-lg">
                <table class="w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead class="text-sm text-gray-700  bg-gray-50  ">
                        <tr className='text-center'>
                            <th scope="col" class="px-6 py-3">
                                No.
                            </th>

                            <th scope="col" class="px-6 py-3">
                                Metal Type
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Measuring Unit
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Created date
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            loading ? (
                                <>
                                    <tr>
                                        <td colSpan={5}>
                                            <Loader />
                                        </td>
                                    </tr>

                                </>
                            ) : (
                                metalList?.length > 0 ?
                                    metalList && metalList?.slice()?.reverse().map((item, index) => {
                                        const createdDate = item?.createdDate;
                                        const dateOnly = new Date(createdDate).toLocaleDateString();
                                        return (
                                            <tr className="bg-white border-red-500  hover:bg-gray-50 text-center">

                                                <td className="px-6 py-4">
                                                    {index + 1}
                                                </td>

                                                <td className="px-6 py-4">
                                                    <TrimmedComponent trimdata={item?.name} />
                                                </td>
                                                <td className="px-6 py-4">
                                                    <TrimmedComponent trimdata={item?.measuring_unit} />
                                                </td>

                                                <td className="px-6 py-4">
                                                    <FormattedDate date={item?.createdDate || "-"} />
                                                </td>
                                                <td className='text-center '>
                                                    <div className='flex justify-center items-center gap-5'>
                                                        <div
                                                            className='cursor-pointer edit_bg px-2 rounded-lg'
                                                            onClick={() => updateItem(item)}
                                                        >
                                                            <i className='fa fa-pencil text-lg'></i>
                                                        </div>
                                                        <div
                                                            className='cursor-pointer inactive_bg px-2 rounded-lg'
                                                            onClick={() => deleteItem(item)}
                                                        >
                                                            <i className="fa fa-trash-o mybzb_text_clr text-lg "></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <TableNoDataFound colSpan={5} />
                            )
                        }
                    </tbody>
                </table>
            </div>

            {isModalOpen && (
                <ModalBoxWrapper
                    title="Add Metal Type"
                    content={modalContent}
                    buttonContent={buttonContent}
                    onClose={closeModal}
                    modalWidth={`max-w-xl`}
                />
            )}

            {isModalEditOpen && (
                <ModalBoxWrapper
                    title="Edit Metal Type"
                    content={modalEditContent}
                    buttonContent={buttonEditContent}
                    onClose={closeEditModal}
                    modalWidth={`max-w-xl`}
                />
            )}

            {isModalDeleteOpen && (
                <DeleteModalBox
                    content={modalDeleteContent}
                    buttonContent={buttonDeleteContent}
                    modalWidth={`max-w-sm`}
                />
            )}

            <ToastContainer />

        </>
    )
}

export default Metals
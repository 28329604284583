import React, { useEffect, useState } from 'react'
import ImgComp from '../../../ReusableComp/ImgComp'
import * as Images from '../../../../assets';
import { ExportIcon, EyeIcon, Search, SortIcon } from '../../../ReusableComp/Icons';
import { useNavigate } from 'react-router-dom';
import { NumberConversion } from '../../../ReusableComp/NumberConversion';
import SortingDropdown from '../../../ReusableComp/SortingDropdown';
import { fetchWithFormDataToken, fetchWithSellerFormDataToken } from '../../../../apiGeneralFunction';
import ProfileImageComponent from '../../../ReusableComp/ProfileImageComponent';
import TrimmedComponent from '../../../ReusableComp/TrimmedComponent';
import { FormattedDate } from '../../../ReusableComp/FormattedDate';
import TableNoDataFound from '../../../ReusableComp/TableNoDataFound';
import Pagination from '../../../ReusableComp/Pagination';
import { SearchBar } from '../../../ReusableComp/SearchBar';

const ProductList = () => {

    const navigate = useNavigate();

    const [selectedId, setSelectedId] = useState(null);
    const [productList, setProductList] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [cardData, setCardData] = useState();
    const [searchQueryData, setSearchQueryData] = useState('');

    // const handleSelectChange = (id) => {
    //     console.log("Selected ID from SortingDropdown:", id);
    //     setSelectedId(id);
    //     fetchProductData(currentPage, id, searchQueryData)
    // };

    const handleSelectChange = (id) => {
        setSelectedId(id);
        fetchProductData(currentPage, id, searchQueryData)
    };

    useEffect(() => {
        const controller = new AbortController();

        fetchProductData(currentPage, selectedId, searchQueryData)

        return () => {
            controller.abort();
        };
    }, [currentPage, selectedId, searchQueryData]);

const fetchProductData = async (page = 1, sortTitle = null, searchQuery = '') => {
    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('login data');
    const userId = JSON.parse(user_id);
    console.log("categories login id", userId);
    console.log("Token:", storedToken);

    if (!storedToken) {
        navigate('/');
        return;
    }

    const abortController = new AbortController();
    try {
        // Dynamically determine selectedId based on sortTitle (sortByValue)
        let selectedId = null;
        if (sortTitle === "asc") {
            selectedId = 4;
        } else if (sortTitle === "desc") {
            selectedId = 5;
        }

        console.log("Derived Selected ID from sortTitle:", selectedId);
        const formdata = new FormData();
        formdata.append("page", page);
        formdata.append("sort_by", sortTitle);
        formdata.append("search", searchQuery || '');

        console.log("Form Data:", [...formdata.entries()]); // Log form data for debugging

        const result = await fetchWithFormDataToken(
            'admin-product-list',
            formdata,
            storedToken,
            abortController
        );

        console.log("API Response:", result); // Debug API response

        if (result?.status === true) {
            setCardData(result?.data?.topData);
            setProductList(result?.data?.productData);
            setTotalPages(result?.data?.pagination?.total_pages);
        } else {
            console.error("API responded with an error:", result);
        }
    } catch (error) {
        console.error("Error during API request:", error);
    } finally {
        abortController.abort();
    }
};



    const handlePageChange = (newPage) => {
        fetchProductData(newPage, selectedId, searchQueryData);
        setCurrentPage(newPage)
    }

    const handleSearch = (searchQuery) => {
        setSearchQueryData(searchQuery)
        fetchProductData(currentPage, selectedId, searchQuery);
    };


    const CardCount = ({ title, amount, src, halfcircle, bgColor, buttonName, onViewClick }) => {
        return (
            <div

                className={`${bgColor} relative h-fit flex flex-col grow px-4 py-6 rounded-3xl shadow-lg max-md:px-5 max-md:mt-6 max-md:w-full max-md:max-w-full `}
            >
                <div>

                    <ImgComp
                        src={halfcircle}
                        className="absolute h-24 right-0 top-0"
                    />
                </div>
                <div className="z-10 flex justify-start items-center gap-3">
                    <div className="flex justify-between ">
                        {src ?
                            <div className="flex justify-center items-center px-3.5  ">

                                <ImgComp
                                    src={src}
                                    className="aspect-square w-24 h-14"
                                />
                            </div>
                            :
                            <></>
                        }
                    </div>
                    <div className="flex justify-between flex-col w-full">
                        <div className="flex-auto my-auto text-sm font-semibold   max-md:max-w-full ">
                            {title}
                        </div>
                        <div className='flex items-end justify-between w-full'>
                            <div className="text-2xl text-black max-md:max-w-full text-left font-bold "
                            >
                                {amount}

                            </div>
                            {buttonName ?
                                <span className='text-sm cursor-pointer mybzb_text_clr font-bold text-end'
                                    onClick={onViewClick}
                                >
                                    {buttonName}
                                    <i className='fa fa-angle-right font-bold ms-3'></i>
                                </span>
                                :
                                <></>
                            }
                        </div>
                    </div>


                </div>

            </div>
        )
    }


    return (
        <>
            <div className="flex items-center justify-between">

                <div className="flex-1 gap-5 w-full max-md:flex-wrap max-md:max-w-full grid grid-cols-4 w-full">

                    <CardCount
                        title={`Total Products`}
                        amount={<NumberConversion priceConvert={cardData?.totalProduct} />}
                        halfcircle={Images.pink_halfcircle_icon}
                        bgColor={`bg-white`}
                    />
                    <CardCount
                        title={`New Registered Products`}
                        amount={cardData?.newRegisterProduct}
                        src={Images.icn_total_product}
                        halfcircle={Images.pink_halfcircle_icon}
                        bgColor={`bg-white`}
                    />
                    <CardCount
                        title={`All Rejected Products`}
                        amount={cardData?.allRejectedProduct}
                        src={Images.icn_total_product}
                        halfcircle={Images.pink_halfcircle_icon}
                        bgColor={`bg-white`}
                    />
                    <CardCount
                        title={`Today's Rejected Products`}
                        amount={cardData?.todaysRejectedProduct}
                        src={Images.icn_total_product}
                        halfcircle={Images.pink_halfcircle_icon}
                        bgColor={`bg-white`}
                    />
                </div>
            </div>
            <div className='w-full flex justify-between items-center mt-5 bg-white p-5 rounded-t-xl'>
                <SearchBar onSearch={handleSearch} searchQueryData={searchQueryData} />
                <div className='flex items-center gap-5'>
                        <SortingDropdown
                            options={[
                                { id: 4, title: 'asc' },
                                { id: 5, title: 'desc' },
                                // { id: 3, title: 'none' },
                            ]}
                            selectedId={selectedId}
                            // onChange={handleSelectChange}
                            onChange={(id) => handleSelectChange(id)}
                        />
                        <div className=''>
                            <ExportIcon disabled={true} />
                        </div>
                    </div>
            </div>


            <div className=" shadow-md sm:rounded-lg mt-2 ">
                <table className="w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead className="text-sm text-gray-700  bg-gray-50  ">
                        <tr className='text-center'>

                            <th scope="col" className="px-6 py-3">
                                No.
                            </th>
                            <th scope="col" className="text-start px-6 py-3">
                                Product Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Sellers Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Categories
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Added Date
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Qty
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Sold
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Remaining
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className='text-xs'>
                        {productList?.length > 0 ?
                             productList && productList?.map((item, index) => {
                                return (
                                    <tr className="bg-white  cursor-pointer hover:bg-gray-50 text-center"
                                        key={index}
                                        onClick={() => navigate('product-detail', { state: { productDetailId: item?.id } })}
                                    >
                                        <td className="px-6 py-4">
                                            {(currentPage - 1) * 10 + index + 1}
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className=' flex items-center gap-4 justify-start'>
                                                <ProfileImageComponent
                                                    profilePicUrl={item?.product_image}
                                                    className={`w-12 h-12 rounded-full border`}
                                                />
                                                <TrimmedComponent trimdata={item?.product_name} />
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 "
                                        >
                                            <TrimmedComponent trimdata={item?.seller_name} />
                                        </td>
                                        <td className="px-6 py-4">
                                            <TrimmedComponent trimdata={item?.category_name} />
                                        </td>
                                        <td className="px-6 py-4">
                                            <FormattedDate date={item?.create_date || "-"} />
                                        </td>
                                        <td className="px-6 py-4">
                                            <TrimmedComponent trimdata={item?.qty} />
                                        </td>
                                        <td className="px-6 py-4">
                                            <TrimmedComponent trimdata={item?.sold} />
                                        </td>
                                        <td className="px-6 py-4">
                                            <TrimmedComponent trimdata={item?.remaining} />
                                        </td>
                                        <td className='text-center '>
                                            <div className='flex justify-center items-center gap-5'>
                                                <EyeIcon />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <TableNoDataFound colSpan={9} />
                        }
                    </tbody>
                </table>
            </div>
            {productList?.length > 0 &&
                <div className='flex w-full justify-center items-center my-5'>
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                </div>
            }
        </>
    )
}

export default ProductList
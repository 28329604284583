import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../../apiGeneralFunction';
import ImgComp from '../../../ReusableComp/ImgComp';
import * as Images from '../../../../assets';
import ModalBoxWrapper from '../../../ReusableComp/ModalBoxWrapper';
import { CustomerEditModal } from '../../../ReusableComp/ModalBox';
import { SearchBar } from '../../../ReusableComp/SearchBar';
import { NumberConversion } from '../../../ReusableComp/NumberConversion';
import TableNoDataFound from '../../../ReusableComp/TableNoDataFound';
import TrimmedComponent from '../../../ReusableComp/TrimmedComponent';
import { FormattedDate } from '../../../ReusableComp/FormattedDate';
import ProfileImageComponent from '../../../ReusableComp/ProfileImageComponent';
import Pagination from '../../../ReusableComp/Pagination';
import SortingDropdown from '../../../ReusableComp/SortingDropdown';
import { ExportIcon } from '../../../ReusableComp/Icons';



const OrderList = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedId, setSelectedId] = useState('');
    const [searchQueryData, setSearchQueryData] = useState('');

    const controller = new AbortController();

    useEffect(() => {
        fetchOrderData(currentPage, selectedId, searchQueryData);;
        return () => {
            controller.abort();
        };
    }, [currentPage, selectedId, searchQueryData]);

    const fetchOrderData = async (page = 1, selectedId = "", searchQuery = '') => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }

        const abortController = new AbortController();

        try {

            let sortByValue = "";
            if (selectedId === 1) {
                sortByValue = "asc";
            } else if (selectedId === 2) {
                sortByValue = "desc";
            }

            const formdata = new FormData();
            //  formdata.append("seller_id", userId?.user_id);
            formdata.append("page", page);
            formdata.append("sort_by", sortByValue);
            formdata.append("search", searchQuery || '');
            const result = await fetchWithFormDataToken(`get-customer-order-list`, formdata, storedToken, abortController);

            console.log("delete category response", result);
            if (result?.status === true) {
                setData(result?.data?.order_list);
                setTotalPages(result?.data?.pagination?.total_pages)
                console.log("delete category response", result);

            }
            else {
                console.log("delete response", result);
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    }

    const handlePageChange = (newPage) => {
        fetchOrderData(newPage, selectedId, searchQueryData);
        setCurrentPage(newPage)
    }

    const handleSelectChange = (id) => {
        setSelectedId(id);
        fetchOrderData(currentPage, id, searchQueryData)
    };

    const handleSearch = (searchQuery) => {
        setSearchQueryData(searchQuery)
        fetchOrderData(currentPage, selectedId, searchQuery);
    };


    return (
        <>

            <div className='bg-white shadow-md rounded-3xl'>
                <div className='w-full flex justify-between items-center p-5 text-xs'>
                    <SearchBar onSearch={handleSearch} searchQueryData={searchQueryData} />
                    <div className='flex items-center gap-5'>
                        <SortingDropdown
                            options={[
                                { id: 1, title: 'asc' },
                                { id: 2, title: 'desc' },
                                // { id: 3, title: 'none' },
                            ]}
                            selectedId={selectedId}
                            // onChange={handleSelectChange}
                            onChange={(id) => handleSelectChange(id)}
                        />
                        <div className=''>
                            <ExportIcon disabled={true} />
                        </div>
                    </div>
                </div>


                <div className="  h-full">

                    <table className="w-full text-xs text-left rtl:text-right text-gray-500 ">
                        <thead className="text-sm text-gray-700  bg-gray-50  ">
                            <tr className='text-center'>

                                <th scope="col" className="px-6 py-3">
                                    No.
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Order ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Customer Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Location
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Seller Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>

                            </tr>
                        </thead>
                        <tbody className='text-xs'>
                            {data?.length > 0 ?
                                data && data?.reverse().map((item, index) => {
                                    const createdDate = item?.create_date;
                                    const dateOnly = new Date(createdDate).toLocaleDateString();

                                    const renderStatus = (status) => {
                                        console.log("status", status);
                                        switch (status) {
                                            case "1":
                                                return <span className="text-amber-600 bg-amber-50  px-3 py-1.5 rounded-lg">Pending</span>;
                                            case "2":
                                                return <span className="text-green-600 bg-green-50 px-3  py-1.5 rounded-lg">Confirm</span>;
                                            case "3":
                                                return <span className="text-blue-600  bg-blue-50 px-3  py-1.5 rounded-lg">Shipping</span>;
                                            case "4":
                                                return <span className="text-green-600 bg-green-50 px-3  py-1.5 rounded-lg">Delivered</span>;
                                            case "5":
                                                return <span className="text-green-600 bg-green-50 px-3  py-1.5 rounded-lg">Completed</span>;
                                            case "6":
                                                return <span className="text-red-600 bg-red-50 px-3  py-1.5 rounded-lg">Cancelled</span>;
                                            case "7":
                                                return <span className="text-amber-600 bg-amber-50  py-1.5  px-3 rounded-lg">Returned</span>;
                                            default:
                                                return <span className="text-gray-500">Unknown</span>;
                                        }
                                    };
                                    return (
                                        <tr className="bg-white cursor-pointer  hover:bg-gray-50 text-center" key={index}
                                            onClick={() => navigate('order-detail',{state : {orderDetailId:item?.id}})}
                                        >
                                            <td className="px-6 py-4">
                                                {(currentPage - 1) * 10 + index + 1}
                                            </td>
                                            <td className="px-6 py-4">
                                                <TrimmedComponent trimdata={item?.order_id} />
                                            </td>
                                            <td className="px-6 py-4 flex items-center gap-3 justify-start ">
                                                <ProfileImageComponent
                                                    profilePicUrl={item?.profile_pic}
                                                    className={`w-12 h-12 rounded-full border`}
                                                />
                                                <TrimmedComponent trimdata={item?.customer_name} />
                                            </td>

                                            <td className="px-6 py-4">
                                                <FormattedDate date={item?.order_date} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <TrimmedComponent trimdata={item?.location} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <TrimmedComponent trimdata={item?.seller_name} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <NumberConversion priceConvert={item?.amount} />
                                            </td>
                                            <td className="px-6 py-4">
                                                {renderStatus(item?.status)}
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <TableNoDataFound colSpan={8} />
                            }
                        </tbody>
                    </table>

                </div>
                {data?.length > 0 &&
                    <div className='flex w-full justify-center items-center my-5'>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>
                }
            </div>
        </>
    )
}

export default OrderList
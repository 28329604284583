
import React from 'react'
import ImgComp from '../ImgComp';
import * as Images from '../../../assets';

export const SuccessIcon = () => {
    return (
        <i class="fa fa-check"></i>
    )
}

export const SortIcon = ({ onClick }) => {
    return (
        <>
            <div
                className='w-max flex items-center gap-3 rounded-lg px-3 py-1 text-gray-500 text-sm  font-bold'
                onClick={onClick}
            >
                <ImgComp
                    src={Images.sort_icon}
                    srcSet={Images.sort_icon}
                    className='w-5'
                />
                Sort by
            </div>
        </>
    )
}

export const AddIcon = ({ onClick }) => {
    return (
        <>
            <div
                onClick={onClick}
                className='mybzb_bg_clr text-white px-3 py-1 rounded-lg cursor-pointer text-sm flex items-center font-bold' >
                <i className='fa fa-plus me-3'></i>
                Add
            </div>
        </>
    )
}

export const ExportIcon = ({ onClick, disabled = false }) => {
    return (
        <>
            <div
            onClick={!disabled ? onClick : undefined} // Prevent click when disabled
            className={`flex items-center gap-3 border rounded-lg px-3 py-1.5 text-xs max-lg:text-xs cursor-pointer ${
                disabled 
                    ? 'text-gray-300 border-gray-300 cursor-not-allowed' 
                    : 'text-gray-500'
            }`}
                // onClick={onClick}
                // className='w-max flex items-center gap-3 border rounded-lg px-3 py-1 text-gray-500 text-sm  font-bold cursor-pointer max-md:text-xs '
            // className='flex items-center gap-3 border rounded-lg px-3 py-1 text-gray-500 text-sm  font-bold cursor-pointer max-md:text-xs '
            >
                <ImgComp
                    src={Images.export_icon}
                    srcSet={Images.export_icon}
                    className={`w-5 ${disabled ? 'opacity-50' : ''}`}
                    // className='w-5 '
                />
                {/* <div className=' '> */}
                    Export
                {/* </div> */}
            </div>
        </>
    )
}

export const Search = () => {
    return (
        <>
            {/* <form className="max-w-md mx-auto"> */}
            <div className='max-w-md w-full'>
                <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only ">Search</label>
                <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input type="search" id="default-search"
                        className="block w-full px-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-0 focus:border-0 "
                        placeholder="Search here..." required />
                    {/* <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button> */}
                </div>
            </div>
            {/* </form> */}
        </>
    )
}

export const EnableIcon = ({ onClick }) => {
    return (
        <div
            onClick={onClick}
            className='cursor-pointer'
        >
            {/* <i className='fa fa-check-square text-xl text-green-700'></i> */}
            <ImgComp
                src={Images.enable_icon}
                srcSet={Images.enable_icon}
                className='w-6'
            />
        </div>
    )
}

export const DisableIcon = ({ onClick }) => {
    return (
        <div
            onClick={onClick}
            className='cursor-pointer'
        >
            {/* <i className='fa fa-times-rectangle text-xl mybzb_text_clr'></i> */}
            <ImgComp
                src={Images.disable_icon}
                srcSet={Images.disable_icon}
                className='w-6'
            />
        </div>
    )
}

export const EditIcon = ({ onClick }) => {
    return (
        <div
            onClick={onClick}
            className='cursor-pointer rounded-lg'
        // className='cursor-pointer edit_bg px-2 rounded-lg'

        >
            {/* <i className='fa fa-pencil text-lg'></i> */}
            <ImgComp
                src={Images.edit_icon}
                srcSet={Images.edit_icon}
                className='w-6'
            />
        </div>
    )
}

export const DeleteIcon = ({ onClick }) => {
    return (
        <div
            onClick={onClick}
            // className='cursor-pointer inactive_bg px-2 rounded-lg'
            className='cursor-pointer rounded-lg'

        >
            {/* <i className="fa fa-trash-o mybzb_text_clr text-lg "></i> */}
            <ImgComp
                src={Images.delete_icon}
                srcSet={Images.delete_icon}
                className='w-6'
            />
        </div>
    )
}

export const EyeIcon = ({ onClick }) => {
    return (
        <div
            onClick={onClick}
            className='cursor-pointer rounded-lg bg-gray-50 px-2 py-0.5'
        >
            <i className="fa fa-eye text-neutral-500 text-lg "></i>
        </div>
    )
}

 


import React, { useEffect, useState } from 'react'
import ImgComp from '../../../ReusableComp/ImgComp';
import * as Images from '../../../../assets';
import { CustomDropdown,CustomProfileDropdown, InputWithAsterisk, InputWithoutAsterisk, InputWithoutBold } from '../../../ReusableComp/InputType';
import { BASE_URL, fetchData, fetchDatas, fetchWithFormDataToken, fetchWithFormDataTokens } from '../../../../apiGeneralFunction';
import { useNavigate } from 'react-router-dom';
import { BgButton } from '../../../ReusableComp/Buttons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormattedDate } from '../../../ReusableComp/FormattedDate';

const Profile = () => {


    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [countrySelectedLabel, setCountrySelectedLabel] = useState('');
    const [stateList, setStateList] = useState([]);
    const [stateSelectedLabel, setStateSelectedLabel] = useState('');
    const [cityList, setCityList] = useState([]);
    const [citySelectedLabel, setCitySelectedLabel] = useState('')
    const [image, setImage] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [show, setShow] = useState(false);
    const [showedit, setShowedit] = useState(true);

    const controller = new AbortController();

    useEffect(() => {

        fetchCustomerData();
        fetchCountry();

        return () => {

            controller.abort();
        };
    }, []);

    const fetchCustomerData = async () => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            // return;
        }
        const abortController = new AbortController();

        try {
            const formdata = new FormData();
            formdata.append("id", userId?.user_id);
            const result = await fetchWithFormDataTokens(`api/v1/seller/get-profile`, formdata, storedToken, abortController);

            console.log("delete category response", result);
            if (result?.status === true) {
                setData(result?.data);
            }
            else {
            }
        } catch (error) {
        }
        finally {
            abortController.abort();
        }
    }

    console.log("get profile darta", data);

    const [formData, setFormData] = useState({
        fullName: '',
        phone: '',
        email: '',
        city: '',
        state: '',
        country: '',
        pincode: ''
    });

    const constructFullName = (first, middle, last) => {
        return [first, middle, last].filter(Boolean).join(' ').trim();
      };
    
      useEffect(() => {
        const fullName = constructFullName(firstName, middleName, lastName);
        if (formData.name !== fullName) {
          setFormData(prevFormData => ({
            ...prevFormData,
            name: fullName,
          }));
        }
      }, [firstName, middleName, lastName]);


    const handleChange = (e) => {

        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        // setFormData(prevData => ({
        //   ...prevData,
        //   [firstname]: value,
        //   [lastName]: value,
        //   [email]: value,
        //   [pan]: value,
        //   [phone]: value,
        //   [storename]: value,
        //   [gst]: value,
        //   [flat]: value,
        //   [area]: value,
        //   [city]: value,
        //   [state]: value,
        //   [country]: value,
        //   [pincode]: value,
        // }));

    };


    console.log("setfoprm profile", formData);

    useEffect(() => {
        // Initialize form data with API data
        setFormData({
            fullName: data?.fullName || '',
            email: data?.email || '',
            phone: data?.phone || '',
            city: data?.city || '',
            state: data?.state || '',
            country: data?.country || '',
            pincode: data?.pincode || '',
        });
    }, [data]);

    const countryName = countryList?.find((item) => item?.value === Number(data?.country))?.label || '';
    const stateName = stateList?.find((item) => item?.value === Number(data?.state))?.label || '';
    const cityName = cityList?.find((item) => item?.value === Number(data?.state))?.label || '';
    console.log("countryName", countryName);


    const [apiDataState, setApiDataState] = useState({
        firstname: data?.firstname || '',
        lastname: data?.lastname || '',
    });

    const fetchCountry = async () => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            // return;
        }

        const abortController = new AbortController();

        try {

            const result = await fetchDatas(`api/v1/seller/list_country`, storedToken, abortController);

            console.log("delete category response", result);
            if (result?.status === true) {

                const formattedOptions = result.data.map(item => ({

                    value: item?.id,
                    label: item?.title
                }));
                setCountryList(formattedOptions);
            }
            else {
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    }

    useEffect(() => {
        if (formData?.country) {
            fetchState(formData?.country);
        }
    }, [formData?.country]);

    useEffect(() => {
        if (formData?.country && formData?.state) {
            fetchCity(formData?.country, formData?.state);
        }
    }, [formData?.country, formData?.state]);

    const fetchState = async (countryId) => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            // return;
        }

        const abortController = new AbortController();

        try {

            const formdata = new FormData();
            formdata.append("country_id", countryId);

            const result = await fetchWithFormDataTokens(`api/v1/seller/list_state`, formdata, storedToken, abortController);

            console.log("delete category response", result);
            if (result?.status === true) {

                const formattedOptions = result.data.map(item => ({

                    value: item?.id,
                    label: item?.title
                }));
                setStateList(formattedOptions);
            }
            else {
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    }

    const fetchCity = async (countryId, stateId) => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            // return;
        }

        const abortController = new AbortController();

        try {

            const formdata = new FormData();
            formdata.append("country_id", countryId);
            formdata.append("state_id", stateId);

            const result = await fetchWithFormDataTokens(`api/v1/seller/list_city`, formdata, storedToken, abortController);

            console.log("delete category response", result);
            if (result?.status === true) {

                const formattedOptions = result.data.map(item => ({

                    value: item?.id,
                    label: item?.title
                }));
                setCityList(formattedOptions);
            }
            else {
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    }






    const handleSubmit = async () => {
        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);
    
        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
    
        if (!storedToken || !userId || !userId.user_id) {
            toast.error("User ID or token is missing. Please log in again.", {
                position: 'top-center',
                autoClose: 3000,
            });
            navigate('/');
            return;
        }
    
        const abortController = new AbortController();
    
        try {
            const formdata = new FormData();
    
            if (selectedFile) {
                formdata.append("profile_pic", selectedFile, selectedFileName);
            } else {
                formdata.append("profile_pic", formData?.profile_pic || '');
            }
    
            formdata.append("id", userId.user_id); // Ensure userId is correct
            console.log(userId.user_id)
            formdata.append("Full Name", formData?.fullName);
            formdata.append("email", formData?.email);
            formdata.append("phone", formData?.phone);
            formdata.append("city_id", formData?.city);
            formdata.append("state_id", formData?.state);
            formdata.append("country_id", formData?.country);
            formdata.append("pincode", formData?.pincode);
            formdata.append("country_code", "+91");
    
            // Log formdata for debugging
            for (let pair of formdata.entries()) {
                console.log(`${pair[0]}: ${pair[1]}`);
            }
    
            const result = await fetchWithFormDataTokens(
                `api/v1/seller/update-profile`, 
                formdata, 
                storedToken, 
                abortController
            );
    
            if (result?.status === true) {
                toast.success(result?.message, {
                    position: 'top-center',
                    autoClose: 3000,
                });
            } else {
                toast.error(result?.message, {
                    position: 'top-center',
                    autoClose: 3000,
                });
            }
        } catch (error) {
            console.error("Error during profile update:", error);
        } finally {
            abortController.abort();
        }
    };
    






    // const handleSubmit = async () => {

    //     const tokenValue = localStorage?.getItem('login api response token');
    //     const storedToken = JSON?.parse(tokenValue);

    //     const user_id = localStorage?.getItem('login data');
    //     const userId = JSON.parse(user_id);
    //     console.log("categories login id", userId);

    //     if (!storedToken) {
    //         navigate('/');
    //     }

    //     const abortController = new AbortController();

    //     try {

    //         const formdata = new FormData();

    //         if (selectedFile) {
    //             formdata.append("profile_pic", selectedFile, selectedFileName);
    //         } else {
    //             formdata.append("profile_pic", formData?.profile_pic || '');
    //         }

    //         formdata.append("id", userId?.user_id);
    //         // formdata.append("firstname", formData?.firstname);
    //         // formdata.append("lastname", formData?.lastName);
    //         formdata.append("Full Name", formData?.fullName);
    //         formdata.append("email", formData?.email);
    //         // formdata.append("pan_no", formData?.pan);
    //         formdata.append("phone", formData?.phone);
    //         // formdata.append("firm_name", formData?.storename);
    //         // formdata.append("gst_no", formData?.gst);
    //         // formdata.append("flatno_building", formData?.flat);
    //         // formdata.append("street_area", formData?.area);
    // //         formdata.append("city", citySelectedLabel );
    // //   formdata.append("state", stateSelectedLabel );
    // //   formdata.append("country", countrySelectedLabel);
    //   formdata.append("city_id", formData?.city);
    //   formdata.append("state_id", formData?.state);
    //   formdata.append("country_id", formData?.country);
    //         formdata.append("pincode", formData?.pincode);
    //         formdata.append("country_code", "+91");

    //         const result = await fetchWithFormDataTokens(`api/v1/seller/update-profile`, formdata, storedToken, abortController);

    //         console.log("delete category response", result);
    //         if (result?.status === true) {
    //             toast.success(result?.message, {
    //                 position: 'top-center',
    //                 autoClose: 3000,

    //             });

    //         }
    //         else {
    //             toast.error(result?.message, {
    //                 position: 'top-center',
    //                 autoClose: 3000,

    //             });
    //         }
    //     } catch (error) {

    //     }
    //     finally {

    //         abortController.abort();
    //     }
    // }

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        handleFile(file);
        const fileName = file;
        console.log("fileName", fileName);
        setSelectedFileName(fileName?.name)
    };


    const handleFile = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(file);
        console.log("hangleFile", file);
        setSelectedFile(file)

    };

    console.log("setSelectedFile", selectedFile);

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFile(file);
    };


    const ProfileImage = () => {
        return (
            <div
                className="flex flex-col p-6 w-full max-h-full  rounded-lg border border-orange-500 border-dashed terms_bg_clr max-md:px-5 max-md:max-w-full"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
            >
                <label htmlFor="file-input" className="cursor-pointer">
                    {image ? (
                        <div className="text-center  mx-auto">
                            <img
                                loading="lazy"
                                src={image}
                                alt="Profile"
                                className="self-center border-white border-solid aspect-square border-[6px] mx-auto"
                            />
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileSelect}
                                className="hidden"
                                id="file-input"
                            />

                        </div>
                    ) : (
                        <div className="text-center mx-auto">
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileSelect}
                                className="hidden"
                                id="file-input"
                            />
                        </div>
                    )}
                    <div className="w-full text-end">
                        <i className="fa fa-edit text-orange-500"></i>
                    </div>
                    {!image &&
                        <>
                            <div className="mt-1 text-xs text-center text-neutral-400 max-md:max-w-full">
                                SVG, PNG, JPG or GIF (max. 800x400px)
                            </div>
                        </>
                    }
                </label>
            </div>
        );

    }

    const NewSellerEnquiry = () => {

        const navigate = useNavigate();

        const [data, setData] = useState([]);
        const [totalPages, setTotalPages] = useState();
        const controller = new AbortController();


        useEffect(() => {
            fetchSellerData();
            return () => {
                controller.abort();
            };
        }, []);

        const fetchSellerData = async (page = 1) => {

            const tokenValue = localStorage?.getItem('login api response token');
            const storedToken = JSON?.parse(tokenValue);
            const user_id = localStorage?.getItem('login data');
            const userId = JSON.parse(user_id);
            console.log("categories login id", userId);

            if (!storedToken) {
                navigate('/');
                return;
            }
            const abortController = new AbortController();
            try {
                const formdata = new FormData();
                formdata.append("page", page);
                const result = await fetchWithFormDataToken(`seller_enquiry_list`, formdata, storedToken, abortController);
                if (result?.status === true) {
                    setData(result?.data?.seller_enquiry_list);
                    setTotalPages(result?.data?.pagination?.total_pages)
                }
                else {
                }
            } catch (error) {
            }
            finally {
                abortController.abort();
            }
        }

        const handlePageChange = (newPage) => {
            fetchSellerData(newPage);
        }

        const handleApprovalChange = (approvalStatus, item) => {
            console.log("handleApprovalChange", approvalStatus, item);
            let isSellerApproved;
            switch (item.is_approved) {
                case '0':
                    isSellerApproved = "0"; // pending
                    break;
                case '1':
                    isSellerApproved = "1"; // Approve
                    break;
                case '2':
                    isSellerApproved = "2";  // Reject
                    break;
                default:
                    isSellerApproved = 0; // Pending
            }

            const tokenValue = localStorage?.getItem('login api response token');
            const storedToken = JSON?.parse(tokenValue);


            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${storedToken}`);

            const raw = JSON.stringify({
                "user_id": item?.user_id,
                "is_approved": approvalStatus
            });

            const requestOptions = {
                method: "PATCH",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${BASE_URL}/approv_reject_seller`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    console.log("approv_reject_seller", result)
                    fetchSellerData();
                }
                )
                .catch((error) => console.error(error));
        }

        const SearchBar = ({ }) => {


            const onSearch = (e) => {
                e.preventDefault();
            };

            const handleKeyPress = (e) => {
                if (e.key === 'Enter' || e.key === 'Tab') {
                    onSearch(e);
                }
            };

            return (
                <>
                    <div
                        className="xl:w-[40%] 2xl:w-[40%] max-lg:w-full  flex gap-3 self-stretch justify-between px-5 py-2 my-auto  leading-5 bg-white rounded-lg  ms-0 border "
                    >

                        <div className="flex gap-5 items-center justify-between w-full max-md:w-full">
                            <div
                            // onClick={onSearch}
                            >
                                <button className=" focus:border-0 focus:outline-0 focus:ring-0">
                                    <i className="fa fa-search text-stone-300"></i>
                                </button>
                            </div>
                            <input
                                className="text-xs p-0 border-0 w-full focus:border-0 focus:outline-0 focus:ring-0"
                                placeholder="Search here..."
                                // value={query}
                                // onChange={(e) => setQuery(e.target.value)}
                                onKeyPress={(e) => {

                                    handleKeyPress(e);
                                }}
                            />
                        </div>
                    </div>
                </>
            );
        }

        return (
            <>
                <div className='w-full flex flex-col justify-between gap-5 p-5'>
                    <div className='text-left font-bold'>
                        New Store Enquiry List
                    </div>
                    <div className='flex w-full justify-between items-center  text-xs'>
                        <SearchBar />
                        <div className='flex items-center gap-5'>
                            <div className='flex items-center gap-3 border rounded-lg px-3 py-1 text-gray-500 '>
                                <ImgComp
                                    src={Images.sort_icon}
                                    srcSet={Images.sort_icon}
                                    className='w-5'
                                />
                                Sort
                            </div>
                        </div>
                    </div>
                </div>


                <div className=" h-full">
                    <table className="w-full  text-xs  text-left rtl:text-right text-gray-500 ">
                        <thead className=" text-gray-700  bg-gray-50  ">
                            <tr className='text-center'>

                                <th scope="col" className="px-6 py-3">
                                    No.
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Sellers Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Owner Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Phone Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    City
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Enquiry Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Enquiry Status
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Verified Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Approved/Decline
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0 ?
                                data && data?.reverse().map((item, index) => {
                                    // const enquiryDate = item?.enquiry_date;
                                    // const enquiryDateOnly = new Date(enquiryDate).toLocaleDateString();
                                    const enquiryDate = item?.enquiry_date;
                                    const datePart = enquiryDate.split(' ')[0];
                                    const [year, month, day] = datePart.split('-');
                                    const enquiryDateOnly = `${day}-${month}-${year}`;
                                    const formattedDate = enquiryDateOnly.replace(/\//g, '-');
                                    console.log(formattedDate);


                                    const verifyDate = item?.verified_date;
                                    const verifyDateOnly = new Date(verifyDate).toLocaleDateString();
                                    return (
                                        <tr className="bg-white   hover:bg-gray-50 text-center" key={index}>

                                            <td className="px-6 py-4">
                                                {index + 1}
                                            </td>

                                            <td className="px-6 py-4 flex items-center gap-3 justify-start ">
                                                <ImgComp
                                                    src={item?.profile_pic}
                                                    className={`w-10 h-10 rounded-full border`}
                                                />
                                                {item?.seller_name}
                                            </td>
                                            <td className="px-6 py-4">
                                                {item?.owner_name}
                                            </td>
                                            <td className="px-6 py-4">
                                                {item?.phone}
                                            </td>
                                            <td className="px-6 py-4 capitalize">
                                                {item?.city}
                                            </td>
                                            <td className="px-6 py-4">
                                                <FormattedDate date={item?.enquiry_date} />
                                            </td>
                                            <td className="px-6 py-4 text-red-800">
                                                {item?.enquiry_status}
                                            </td>
                                            <td className="px-6 py-4">
                                                <FormattedDate date={item?.verified_date} />
                                            </td>
                                            <td className='text-center '>
                                                <div className='flex justify-center items-center gap-5 '>
                                                    {item?.is_approved === "2" &&
                                                        <div
                                                            onClick={() => handleApprovalChange("1", item)}
                                                            className='cursor-pointer '
                                                        >
                                                            <i className='fa fa-check-square text-xl text-green-700'></i>
                                                        </div>
                                                    }
                                                    {item?.is_approved === "1" &&
                                                        <div className='cursor-pointer '
                                                            onClick={() => handleApprovalChange("2", item)}
                                                        >
                                                            <i className='fa fa-times-rectangle text-xl mybzb_text_clr'></i>
                                                        </div>
                                                    }
                                                    {item?.is_approved === "0" &&
                                                        <>
                                                            <div
                                                                onClick={() => handleApprovalChange("1", item)}
                                                                className='cursor-pointer '
                                                            >
                                                                <i className='fa fa-check-square text-xl text-green-700'></i>
                                                            </div>
                                                            <div className='cursor-pointer '
                                                                onClick={() => handleApprovalChange("2", item)}
                                                            >
                                                                <i className='fa fa-times-rectangle text-xl mybzb_text_clr'></i>
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <></>
                            }
                        </tbody>
                    </table>

                </div>
                <div className='flex w-full justify-center items-center my-5'>
                    {/* {pageLinks} */}
                    {Array.from({ length: totalPages }, (_, index) => (
                        <div key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className='px-3 py-1 border rounded-full text-black text-center hover:bg-red-800 hover:text-white my-5 me-5 cursor-pointer'
                        >
                            {index + 1}
                        </div>
                    ))}
                </div>
            </>
        )
    }

    return (
        <>
            <section className="flex flex-col self-stretch rounded-b-3xl bg-white p-5 gap-5 ">
                <div className="w-full max-md:max-w-full ">
                    {/* {showedit ?
                        <div className=' w-full text-end cursor-pointer' onClick={() => setShowedit(false)}>
                            <i className='fa fa-edit text-xl text-red-800'></i>
                        </div>
                        :
                        <div className='w-full  flex items-center justify-end'>
                            <div className='mb-5 w-fit text-end'>
                                <BgButton
                                    onClick={() => handleSubmit()}
                                    buttonName={`Save Changes`}
                                />
                            </div>
                        </div>
                    } */}
                      
                       
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 ">
                        <div className="flex flex-col w-[20%] max-md:ml-0 max-md:w-full">
                            <figure className="flex flex-col justify-center items-center w-full bg-rose-50 aspect-square rounded-[31.676px] max-md:mt-10">
                                <figcaption className="flex flex-col pt-11 px-5 pb-4  bg-white bg-opacity-10 rounded-[45.781px] max-md:pl-5">
                                    {!showedit ?
                                        <ProfileImage />
                                        :
                                        <>
                                            <ImgComp
                                                src={data?.profile_pic ? data?.profile_pic : Images.logo}
                                                className="mx-auto"
                                            />
                                            {/* <div className="flex justify-center items-center self-end px-1.5 mt-1.5 w-6 h-6 bg-white rounded-[50px]"
                                                onClick={() => setShowedit(true)}
                                            >
                                                <i className='fa fa-edit text-red-800'></i>
                                            </div> */}
                                        </>
                                    }
                                </figcaption>
                            </figure>
                        </div>

                        <form className="flex flex-col w-full max-md:ml-0 max-md:w-full">
  <div className="flex flex-col gap-5 text-base font-medium  max-md:max-w-full justify-between items-start grid grid-cols-3">
    {/* Full Name */}
    <div className="flex flex-col">
      <label className="font-bold text-gray-700">{`Full Name`}</label>
      <div className="text-gray-500">{formData?.fullName || "Admin Panel"}</div>
    </div>

    {/* Phone Number */}
    <div className="flex flex-col">
      <label className="font-bold text-gray-700">{`Phone number`}</label>
      <div className="text-gray-500">{formData?.phone || "7874800096"}</div>
    </div>

    {/* Email */}
    <div className="flex flex-col">
      <label className="font-bold text-gray-700">{`Email`}</label>
      <div className="text-gray-500">{formData?.email || "headwaybusinesssolutions@gmail.com"}</div>
    </div>

    {/* Country */}
    <div className="flex flex-col">
      <label className="font-bold text-gray-700">{`Country`}</label>
      <div className="text-gray-500">{countrySelectedLabel || "India"}</div>
    </div>

    {/* State */}
    <div className="flex flex-col">
      <label className="font-bold text-gray-700">{`State`}</label>
      <div className="text-gray-500">{stateSelectedLabel || "Gujarat"}</div>
    </div>

    {/* City */}
    <div className="flex flex-col">
      <label className="font-bold text-gray-700">{`City`}</label>
      <div className="text-gray-500">{citySelectedLabel || "Ahmedabad"}</div>
    </div>

    {/* Pincode */}
    <div className="flex flex-col">
      <label className="font-bold text-gray-700">{`Pincode`}</label>
      <div className="text-gray-500">{formData?.pincode || "382345"}</div>
    </div>
  </div>
</form>



                        {/* <form className="flex flex-col w-full  max-md:ml-0 max-md:w-full">
                            <div className="flex flex-col gap-5 text-base font-medium  max-md:max-w-full justify-between items-start grid grid-cols-3">
                                <InputWithoutBold
                                    labelName={`Full Name`}
                                    placeholder={`Enter your full name`}
                                    value={formData?.fullName}
                                    onChange={handleChange}
                                    type="text"
                                    name="fullName"
                                />
                                <InputWithoutBold
                                    labelName={`Phone number`}
                                    placeholder={`Enter phone number`}
                                    value={formData?.phone}
                                    onChange={handleChange}
                                    type="number"
                                    name="phone"
                                />
                                <InputWithoutBold
                                    labelName={`Email`}
                                    placeholder={`Enter your email address`}
                                    value={formData?.email}
                                    onChange={handleChange}
                                    type="email"
                                    name="email"
                                />
                                <InputWithoutBold
                                    labelName={`Country`}
                                    placeholder={`Enter country`}
                                    value={formData?.country}
                                    onChange={handleChange}
                                    type="text"
                                    name="country"
                                />
                                 <CustomProfileDropdown
                      value={formData?.country}
                      //  onChange={(value) => setFormData(prev => ({ ...prev, country: value }))}
                      onChange={(value) => {
                        setFormData((prev) => ({
                          ...prev,
                          country: value,
                        }));

                        const matchingCountryLabel = countryList.find((country) => country?.value === Number(value));

                        if (matchingCountryLabel) {
                          console.log("Matching State Label:", matchingCountryLabel?.label);
                          setCountrySelectedLabel(matchingCountryLabel?.label)
                        }
                      }}
                      options={countryList}
                      placeholder="Select Country"
                      labelName={`Country`}
                    />

                                <InputWithoutBold
                                    labelName={`State`}
                                    placeholder={`Enter State`}
                                    value={formData?.state}
                                    onChange={handleChange}
                                    type="text"
                                    name="state"
                                />

<CustomProfileDropdown
                      value={formData?.state}
                      // onChange={(value) => setFormData(prev => ({ ...prev, state: value }))}
                      onChange={(value) => {
                        setFormData((prev) => ({
                          ...prev,
                          state: value,
                        }));

                        const matchingStateLabel = stateList.find((state) => state?.value === Number(value));

                        if (matchingStateLabel) {
                          console.log("Matching State Label:", matchingStateLabel?.label);
                          setStateSelectedLabel(matchingStateLabel?.label)
                        }
                      }}
                      options={stateList}
                      placeholder="Select State"
                      labelName={`State`}
                    />
                                <InputWithoutBold
                                    labelName={`City`}
                                    placeholder={`Enter city`}
                                    value={formData?.city}
                                    onChange={handleChange}
                                    type="text"
                                    name="city"
                                />

<CustomProfileDropdown
                      value={formData?.city}
                      // onChange={(value) => setFormData(prev => ({ ...prev, city: value }))}
                      onChange={(value) => {
                        setFormData((prev) => ({
                          ...prev,
                          city: value,
                        }));

                        const matchingCityLabel = cityList.find((city) => city?.value === Number(value));

                        if (matchingCityLabel) {
                          console.log("Matching State Label:", matchingCityLabel?.label);
                          setCitySelectedLabel(matchingCityLabel?.label)
                        }
                      }}
                      options={cityList}
                      placeholder="Select City"
                      labelName={`City`}
                    />

                                <InputWithoutBold
                                    labelName={`Pincode`}
                                    placeholder={`Enter pincode`}
                                    value={formData?.pincode}
                                    onChange={handleChange}
                                    type="number"
                                    name="pincode"
                                />
                            </div>
                        </form> */}
                    </div>
                </div>
            </section>
            <section className='bg-gray-50 py-5'>
                <div className=" bg-white p-5 shadow-md rounded-3xl">

                    <NewSellerEnquiry />
                </div>
            </section>

            <ToastContainer />
        </>
    )
}

export default Profile
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { fetchSellerGetData, fetchWithFormDataToken, fetchWithSellerFormDataToken } from '../../../../../apiGeneralFunction';
import ImgComp from '../../../../ReusableComp/ImgComp';
import * as Images from '../../../../../assets';
import { AddIcon, ExportIcon, EyeIcon } from '../../../../ReusableComp/Icons';
import ModalBoxWrapper from '../../../../ReusableComp/ModalBoxWrapper';
import { SellerModalBoxWrapper } from '../../../../ReusableComp/ModalBox';
import { CustomDropdown, GreyInputWithAsterisk, InputWithAsterisk } from '../../../../ReusableComp/InputType';
import Pagination from '../../../../ReusableComp/Pagination';
import toastPromise from '../../../../../toastService';
import ProfileImageComponent from '../../../../ReusableComp/ProfileImageComponent';
import { FormattedDate } from '../../../../ReusableComp/FormattedDate';
import TableNoDataFound from '../../../../ReusableComp/TableNoDataFound';
import SortingDropdown from '../../../../ReusableComp/SortingDropdown';
import Loader from '../../../../ReusableComp/Loader';
import { SearchBar } from '../../../../ReusableComp/SearchBar';


const SellerApprovedList = ({ sellerDetail }) => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [sellerDetailData, setSellerDetailData] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [errors, setErrors] = useState({});
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [loading, setLoading] = useState(true);
    const [searchQueryData, setSearchQueryData] = useState('');
    const [showedit, setShowedit] = useState(true);


    const [formData, setFormData] = useState({
        sellerName: '',
        email: '',
        phoneNumber: '',
        ownerName: '',
        gstNumber: '',
        panNumber: '',
        home: '',
        apartment: '',
        landmark: '',
        country: '',
        countryLabel: '',
        city: '',
        cityLabel: '',
        state: '',
        stateLabel: '',
        pincode: '',
    });

    const controller = new AbortController();

    const validateForm = () => {
        // const errors = {};
        let validationErrors = {};

        if (!formData.sellerName) {
            validationErrors.sellerName = 'Seller Name is required';
        }
        if (!formData.email) {
            validationErrors.email = 'Email is required';
        }
        if (!formData.phoneNumber) {
            validationErrors.phoneNumber = 'Phone Number is required';
        }
        if (!formData.ownerName) {
            validationErrors.ownerName = 'Owner Name is required';
        }
        if (!formData.gstNumber) {
            validationErrors.gstNumber = 'GSTIN Number is required';
        }
        if (!formData.panNumber) {
            validationErrors.panNumber = 'PAN Number is required';
        }
        if (!formData.home) {
            validationErrors.home = 'Home is required';
        }
        if (!formData.apartment) {
            validationErrors.apartment = 'Apartment is required';
        }
        if (!formData.country) {
            validationErrors.country = 'Country is required';
        }
        if (!formData.city) {
            validationErrors.city = 'City are required';
        }
        if (!formData.state) {
            validationErrors.state = 'State is required';
        }
        if (!formData.pincode) {
            validationErrors.pincode = 'Pincode is required';
        }

        setErrors(validationErrors);

        return Object.keys(validationErrors).length === 0;
    };

    const handleInputChange = (event) => {
        const { name, sellerName, email, phoneNumber, ownerName, gstNumber, panNumber, home, apartment, landmark, city, state, pincode, value } = event.target;
        setFormData((prevValues) => ({
            ...prevValues,
            [name]: value,
            [sellerName]: value,
            [email]: value,
            [phoneNumber]: value,
            [ownerName]: value,
            [gstNumber]: value,
            [panNumber]: value,
            [home]: value,
            [apartment]: value,
            [landmark]: value,
            [city]: value,
            [state]: value,
            [pincode]: value,
        }));
    };

    console.log("addsubmitformData", formData);

    const fetchSellerData = async (page = 1, sortTitle = null, searchQuery = '') => {
        setLoading(false);
        console.log("Fetching data for page:", page, "searchQuery:", searchQuery);

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }

        const abortController = new AbortController();

        try {

            let sortByValue = "";
            if (selectedId === 1) {
                sortByValue = "asc";
            } else if (selectedId === 2) {
                sortByValue = "desc";
            }

            const formdata = new FormData();
            formdata.append("page", page);
            formdata.append("sort_by", sortByValue);
            // formdata.append("sort_by", sortTitle?.title || "" || (selectedId === 1 ? "asc" : "desc"));
            formdata.append("search", searchQuery || '');
            const result = await fetchWithFormDataToken(`seller_list`, formdata, storedToken, abortController);

            console.log("delete category response", result);
            if (result?.status === true) {
                setData(result?.data?.seller_data);
                setTotalPages(result?.data?.pagination?.total_pages)

            }
            else {
                setData(result?.data);

            }
        } catch (error) {

        }
        finally {
            setLoading(false);
            abortController.abort();
        }
    }

    const fetchSellerDetail = async (sellerId) => {

        console.log("fetchSellerDetail----", sellerId);


        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);

        if (!storedToken) {
            navigate('/');
            // return;
        }
        const abortController = new AbortController();
        try {
            const formdata = new FormData();
            formdata.append("id", sellerId);
            const result = await fetchWithSellerFormDataToken(`seller/get-profile`, formdata, storedToken, abortController);
            if (result?.status === true) {
                setSellerDetailData(result?.data);
            }
            else {
            }
        } catch (error) {
        }
        finally {
            abortController.abort();

        }
    }

    console.log("fetchWithSellerFormDataToken", selectedFileName);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage)
        fetchSellerData(newPage, selectedId, searchQueryData);
    }

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFile(file);
    };

    const handleFile = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(file);
        console.log("hangleFile", file);
        setSelectedFile(file)
        setSelectedFileName(file?.name)
    };

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        handleFile(file);
        const fileName = file;
        console.log("fileName------", fileName);
        // setSelectedFileName(fileName?.name)
    };

    const openModal = () => {
        setIsModalOpen(true);
        fetchCountry();
    };

    useEffect(() => {
        console.log("formData.country", formData);
        if (formData?.country) {
            fetchState(formData?.country);
        }
    }, [formData.country])

    useEffect(() => {
        if (formData?.country && formData?.state) {
            fetchCity(formData?.country, formData?.state);
        }
    }, [formData?.country, formData?.state]);

    const fetchCountry = async () => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);

        if (!storedToken) {
            navigate('/');
            // return;
        }
        const abortController = new AbortController();
        try {
            const result = await fetchSellerGetData(`seller/list_country`, storedToken, abortController);
            if (result?.status === true) {
                const formattedOptions = result.data.map(item => ({
                    value: item?.id,
                    label: item?.title
                }));
                setCountryList(formattedOptions);
            }
            else {
            }
        } catch (error) {
        }
        finally {
            abortController.abort();
        }
    }

    const fetchState = async (countryId) => {

        console.log("fetchStatecountryId", countryId);
        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);
        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        if (!storedToken) {
            navigate('/');
        }
        const abortController = new AbortController();
        try {
            const formdata = new FormData();
            formdata.append("country_id", countryId);
            const result = await fetchWithSellerFormDataToken(`seller/list_state`, formdata, storedToken, abortController);
            if (result?.status === true) {
                const formattedOptions = result.data.map(item => ({
                    value: item?.id,
                    label: item?.title
                }));
                setStateList(formattedOptions);
            }
            else {
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    }

    const fetchCity = async (countryId, stateId) => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);

        if (!storedToken) {
            navigate('/');
        }

        const abortController = new AbortController();

        try {

            const formdata = new FormData();
            formdata.append("country_id", countryId);
            formdata.append("state_id", stateId);

            const result = await fetchWithSellerFormDataToken(`seller/list_city`, formdata, storedToken, abortController);

            if (result?.status === true) {

                const formattedOptions = result.data.map(item => ({

                    value: item?.id,
                    label: item?.title
                }));
                setCityList(formattedOptions);
            }
            else {
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    }

    const modalContent = (
        <>
            <div className='flex flex-col h-full '>
                <div className='text-left font-bold text-xl'>
                    Sellers Information
                </div>
                <div className='flex justify-between gap-5 h-full '>

                    <div className=' items-start w-full grid grid-cols-3 gap-5'>
                        <div>
                            <GreyInputWithAsterisk
                                labelName={`Sellers Name`}
                                placeholder={`Enter Seller name`}
                                // value={formData?.sellerName}
                                // onChange={handleInputChange}
                                type="text"
                                name="sellerName"
                                readOnly={true}
                                onChange={() => setShowedit(false)}
                            />
                            {errors?.sellerName && (
                                <div className="text-red-600 text-xs">{errors?.sellerName}</div>
                            )}
                        </div>
                        <div>
                            <GreyInputWithAsterisk
                                labelName={`Email`}
                                placeholder={`Enter Email`}
                                value={formData?.email}
                                onChange={handleInputChange}
                                type="email"
                                name="email"
                            />
                            {errors?.email && (
                                <div className="text-red-600 text-xs">{errors?.email}</div>
                            )}
                        </div>
                        <div
                            className="  text-sm text-left mt-2.5"
                        >
                            <label htmlFor="phoneNumber" className=" text-neutral-500" >
                                Phone Number
                            </label>
                            <div
                                className={`mt-2.5 hover:border-red-700 focus:border-red-700 focus:ring-0 flex gap-1.5 items-start py-1 px-2 text-base  rounded-lg border border-gray-200 border-solid text-zinc-800
                                    }`}>
                                <div className="flex  my-auto w-full">

                                    <div className="flex gap-1 items-center text-sm ">
                                        <div className="">+91</div>
                                        <i className="fa fa-angle-down"></i>

                                    </div>
                                    <input
                                        type="text"
                                        name="phoneNumber"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        onInput={(e) => (e.currentTarget.value = e.currentTarget.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1").replace(/^0[^.]/, "0"))}
                                        // defaultValue={formData.phoneNumber}
                                        value={formData?.phoneNumber}
                                        onChange={handleInputChange}
                                        className={`w-full focus:border-red-700 focus:ring-0 justify-center px-4 py-1 focus:border-0 text-sm rounded-lg border-0 text-neutral-900 `}
                                    />

                                </div>
                            </div>
                            {errors?.phoneNumber && (
                                <div className="text-red-600 text-sm">{errors?.phoneNumber}</div>
                            )}

                        </div>
                        <div className='w-full flex flex-col text-sm h-full '>
                            <div className='mb-3 text-neutral-500 text-left'>Sellers logo</div>
                            <div
                                className="flex flex-col grow px-6 py-5 h-full justify-around rounded-lg border border-red-700 border-dashed terms_bg_clr max-md:px-5 mybzb_bg_pinkclr "
                                onDrop={handleDrop}
                                onDragOver={(e) => e.preventDefault()}
                            >
                                <label htmlFor="file-input" className="cursor-pointer">

                                    <div className="text-center  mx-auto h-full">
                                        <ImgComp
                                            src={image ? image : Images.upload_icon}
                                            srcSet={image ? image : Images.upload_icon}
                                            className="self-center w-10 border-white  mx-auto"
                                        />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleFileSelect}
                                            className="hidden"
                                            id="file-input"
                                        />
                                    </div>


                                </label>
                                {!image &&
                                    <>
                                        <span className='mybzb_text_clr mt-2 text-center'>Click to upload</span>
                                        <span className="mt-1 text-xs text-center text-neutral-400 ">
                                            SVG, PNG, JPG or GIF (max. 800px*400px)
                                        </span>
                                    </>
                                }
                            </div>
                        </div>
                        <div className='w-full col-span-2 justify-between h-full'>
                            <div>
                                <GreyInputWithAsterisk
                                    labelName={`Owner Name`}
                                    placeholder={`Enter Owner name`}
                                    value={formData?.ownerName}
                                    onChange={handleInputChange}
                                    type="text"
                                    name="ownerName"
                                />
                                {errors?.ownerName && (
                                    <div className="text-red-600 text-xs">{errors?.ownerName}</div>
                                )}
                            </div>
                            <div className='grid grid-cols-2 gap-5'>
                                <div>
                                    <GreyInputWithAsterisk
                                        labelName={`GSTIN number`}
                                        placeholder={`Enter GSTIN number`}
                                        value={formData?.gstNumber}
                                        onChange={handleInputChange}
                                        type="text"
                                        name="gstNumber"
                                    />
                                    {errors?.gstNumber && (
                                        <div className="text-red-600 text-xs">{errors?.gstNumber}</div>
                                    )}
                                </div>
                                <div>
                                    <GreyInputWithAsterisk
                                        labelName={`PAN`}
                                        placeholder={`Enter PAN`}
                                        value={formData?.panNumber}
                                        onChange={handleInputChange}
                                        type="text"
                                        name="panNumber"
                                    />
                                    {errors?.panNumber && (
                                        <div className="text-red-600 text-xs">{errors?.panNumber}</div>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )

    const secondContent = (
        <>
            <div className='flex flex-col h-full '>
                <div className='text-left  font-bold text-xl'>
                    Address Information
                </div>
                <div className='flex justify-between gap-5 h-full '>

                    <div className=' items-start w-full grid grid-cols-3 gap-5'>
                        <div>
                            <GreyInputWithAsterisk
                                labelName={`Home/Flat/Floor no.`}
                                placeholder={`Enter Seller name`}
                                value={formData?.home}
                                onChange={handleInputChange}
                                type="text"
                                name="home"
                            />
                            {errors?.home && (
                                <div className="text-red-600 text-xs">{errors?.home}</div>
                            )}
                        </div>
                        <div>
                            <GreyInputWithAsterisk
                                labelName={`Apartment/Road/Area`}
                                placeholder={`Enter Apartment/Road/Area`}
                                value={formData?.apartment}
                                onChange={handleInputChange}
                                type="text"
                                name="apartment"
                            />
                            {errors?.apartment && (
                                <div className="text-red-600 text-xs">{errors?.apartment}</div>
                            )}
                        </div>
                        <div>
                            <GreyInputWithAsterisk
                                labelName={`Pincode`}
                                placeholder={`Enter Pincode`}
                                value={formData?.pincode}
                                onChange={handleInputChange}
                                type="number"
                                name="pincode"
                            />
                            {errors?.pincode && (
                                <div className="text-red-600 text-xs">{errors?.pincode}</div>
                            )}
                        </div>
                        <div>
                            <CustomDropdown
                                value={formData?.country}
                                onChange={(value) => {
                                    setFormData(prev => ({ ...prev, country: value.id, countryLabel: value.label }));
                                }}
                                // onChange={handleCountrySelect}
                                options={countryList}
                                placeholder="Select Country"
                                labelName={`Country`}
                            />
                            {errors?.country && (
                                <div className="text-red-600 text-xs">{errors?.country}</div>
                            )}
                        </div>
                        <div>
                            <CustomDropdown
                                value={formData?.state}
                                onChange={(value) => {
                                    setFormData(prev => ({ ...prev, state: value.id, stateLabel: value.label }));
                                }}
                                // onChange={handleStateSelect}
                                options={stateList}
                                placeholder="Select State"
                                labelName={`State`}
                            />
                            {errors?.state && (
                                <div className="text-red-600 text-xs">{errors?.state}</div>
                            )}
                        </div>
                        <div>
                            <CustomDropdown
                                value={formData?.city}
                                onChange={(value) => {
                                    setFormData(prev => ({ ...prev, city: value.id, cityLabel: value.label }));
                                }}
                                // onChange={handleCitySelect}
                                options={cityList}
                                placeholder="Select City"
                                labelName={`City`}
                            />
                            {errors?.city && (
                                <div className="text-red-600 text-xs">{errors?.city}</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

    const closeModal = () => {
        setIsModalOpen(false);
        setFormData({})
    };

    console.log("selectedFile, selectedFileName", selectedFile, selectedFileName);

    const handleSubmit = async () => {
        setLoading(true);
        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);


        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            // return;
        }

        if (!validateForm()) {
            console.log("Form validation failed. Please fill out all required fields.");
            return;
        }
        const abortController = new AbortController();
        try {
            const formdata = new FormData();

            if (selectedFile) {
                formdata.append("profile_pic", selectedFile, selectedFileName);
            }

            formdata.append("firm_name", formData?.sellerName);
            formdata.append("email", formData?.email);
            formdata.append("phone", formData?.phoneNumber);
            formdata.append("owner_name", formData?.ownerName);
            // formdata.append("first_name", formData?.ownerName);
            formdata.append("gst_no", formData?.gstNumber);
            formdata.append("pan_no", formData?.panNumber);
            formdata.append("flatno_building", formData?.home);
            formdata.append("street_area", formData?.apartment);
            formdata.append("pincode", formData?.pincode);
            formdata.append("country_code", "+91");
            formdata.append("country_id", formData?.country);
            formdata.append("state_id", formData?.state);
            formdata.append("city_id", formData?.city);
            formdata.append("country", formData?.countryLabel);
            formdata.append("state", formData?.stateLabel);
            formdata.append("city", formData?.cityLabel);
            formdata.append("password", "123456");
            formdata.append("password_confirmation", "123456");
            formdata.append("latitude", "1");
            formdata.append("longitude", "1");

            // const result = await fetchWithSellerFormDataToken('seller/register', formdata, storedToken, null, abortController);

            // console.log("add seller register response", result);
            // if (result?.status === true) {
            //     setErrors({})
            //     setIsModalOpen(false)
            // }
            // else {
            // }
            const response = await toastPromise(
                fetchWithSellerFormDataToken('seller/register', formdata, storedToken, abortController, null),
                {
                    pending: "Adding seller ...",
                    success: (data) => data.message || "Seller is added Successfully !",
                    error: (data) => data.message || "Failed to add the seller",
                }
            );

            if (response.status === true) {
                setErrors({})
                setIsModalOpen(false)
                fetchSellerData();
            }
        } catch (error) {
        }
        finally {
            setLoading(false);
        }
        // } else {
        // }
        console.log("api is not hitting");
    };

    const buttonContent = (
        <>
            <div className='flex items-center gap-5 justify-end mt-3'>
                <button
                    className='border border-red-700 mybzb_text_clr text-base px-5 py-2 rounded-lg focus:border-red-800 focus:ring-0 focus:outline-0'
                    // onClick={() => setIsModalOpen(false)}
                    onClick={closeModal}
                >
                    Cancel
                </button>
                <button
                    onClick={() => handleSubmit()}
                    className='border border-red-700 mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg focus:border-red-800 focus:ring-0 focus:outline-0'
                >
                    Submit
                </button>
            </div>
        </>
    );

    const modalViewContent = (
        <>
            <div className='flex flex-col h-full '>
                <div className='text-left mb-5 font-bold text-xl'>
                    Seller Information
                </div>
                <div className='flex justify-between gap-5 h-full '>

                    <div className=' items-start w-full grid grid-cols-3 gap-5'>
                        <GreyInputWithAsterisk
                            labelName={`Sellers Name`}
                            value={sellerDetailData?.sellersname}
                            type="text"
                            name="name"
                        />
                        <GreyInputWithAsterisk
                            labelName={`Email`}
                            value={sellerDetailData?.email}
                            type="email"
                            name="name"
                        />
                        <div
                            className="  text-sm text-left mt-2.5"
                        >
                            <label htmlFor="phoneNumber" className=" text-neutral-500" >
                                Phone Number
                            </label>
                            <div
                                className={`mt-2.5 focus:border-red-800 focus:ring-0 flex gap-1.5 items-start py-1 px-2 text-base  rounded-lg border border-gray-200 border-solid text-zinc-800
                                    }`}>
                                <div className="flex  my-auto w-full">

                                    <div className="flex gap-1 items-center text-sm ">
                                        <div className="">+91</div>
                                        <i className="fa fa-angle-down"></i>

                                    </div>
                                    <input
                                        type="text"
                                        name="phoneNumber"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        value={sellerDetailData?.phone}
                                        readOnly={true}
                                        onChange={() => setShowedit(false)}
                                        className={`w-full read-only-input focus:border-red-800 focus:ring-0 justify-center px-4 py-1 focus:border-0 text-sm rounded-lg border-0 text-neutral-900 `}
                                    />

                                </div>
                            </div>

                        </div>
                        <div className='w-full flex flex-col text-sm h-full'>
                            <div className='mb-3 text-neutral-500 text-left'>Sellers logo</div>
                            <div
                                className="h-full flex flex-col grow px-6 py-5 justify-around rounded-lg border border-red-500 border-dashed terms_bg_clr max-md:px-5 mybzb_bg_pinkclr "
                                onDrop={handleDrop}
                                onDragOver={(e) => e.preventDefault()}
                            >
                                <label htmlFor="file-input" className="h-full">

                                    <div className="text-center mx-auto">
                                        <ImgComp
                                            src={sellerDetailData?.profile_pic ? sellerDetailData?.profile_pic : Images.logo}
                                            srcSet={sellerDetailData?.profile_pic ? sellerDetailData?.profile_pic : Images.logo}
                                            // className="self-center w-10 border-white  mx-auto"
                                            className={`h-24 w-24 mx-auto`}
                                        />
                                        {/* <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleFileSelect}
                                            className="hidden"
                                            id="file-input"
                                        /> */}
                                    </div>


                                </label>
                                {/* {!image &&
                                    <>
                                        <span className='mybzb_text_clr mt-2 text-center'>Click to upload</span>
                                        <span className="mt-1 text-xs text-center text-neutral-400 ">
                                            SVG, PNG, JPG or GIF (max. 800px*400px)
                                        </span>
                                    </>
                                } */}
                            </div>
                        </div>
                        <div className='w-full col-span-2 justify-between h-full'>
                            <GreyInputWithAsterisk
                                labelName={`Owner Name`}
                                value={sellerDetailData?.ownername}
                                type="text"
                                name="name"
                            />
                            <div className='grid grid-cols-2 gap-5'>
                                <GreyInputWithAsterisk
                                    labelName={`GSTIN number`}
                                    value={sellerDetailData?.gst_no}
                                    type="text"
                                    name="name"
                                />
                                <GreyInputWithAsterisk
                                    labelName={`PAN`}
                                    value={sellerDetailData?.pan_no}
                                    type="text"
                                    name="name"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );

    const secondViewContent = (
        <>
            <div className='flex flex-col h-full '>
                <div className='text-left mb-5 font-bold text-xl'>
                    Address Information
                </div>
                <div className='flex justify-between gap-5 h-full '>

                    <div className=' items-start w-full grid grid-cols-3 gap-5'>
                        <GreyInputWithAsterisk
                            labelName={`Home/Flat/Floor no.`}
                            value={sellerDetailData?.flatno_building}
                            type="text"
                            name="name"
                        />
                        <GreyInputWithAsterisk
                            labelName={`Apartment/Road/Area`}
                            value={sellerDetailData?.street_area}
                            type="text"
                            name="name"
                        />
                        <GreyInputWithAsterisk
                            labelName={`Pincode`}
                            value={sellerDetailData?.pincode}
                            type="number"
                            name="name"
                        />
                        <GreyInputWithAsterisk
                            labelName={`Country`}
                            value={sellerDetailData?.country}
                            type="text"
                            name="name"
                        />

                        <GreyInputWithAsterisk
                            labelName={`State`}
                            value={sellerDetailData?.state}
                            type="text"
                            name="name"
                        />
                        <GreyInputWithAsterisk
                            labelName={`City`}
                            value={sellerDetailData?.city}
                            type="text"
                            name="name"
                        />

                    </div>
                </div>
            </div>

        </>
    );

    const buttonViewContent = (
        <>
            <div className='flex items-center gap-5 justify-end mt-3'>
                <button
                    className='border border-red-700 mybzb_text_clr text-base px-5 py-2 rounded-lg  focus:border-red-800 focus:ring-0 focus:outline-0'
                    onClick={() => setIsViewModalOpen(false)}
                >
                    Cancel
                </button>
                <button
                    onClick={() => setIsViewModalOpen(false)}
                    className='border border-red-700 mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg focus:border-red-800 focus:ring-0 focus:outline-0'
                >
                    Back to Seller List
                </button>
            </div>
        </>
    );

    const closeViewModal = () => {
        setIsViewModalOpen(false);
    };

    const openViewModal = (item) => {
        console.log("openViewModal", item);
        setIsViewModalOpen(true);
        fetchSellerDetail(item?.user_id)
    };

    const handleDetailClick = (sellerId) => {
        console.log("handleDetailClick", sellerId);
        // navigate(`seller-approved-detail/${sellerId}`);
        navigate('seller-approved-detail', { state: { sellerId: sellerId } })
    };


    const fetchSellerAbleDisableData = async (status, item) => {

        console.log("fetchSellerAbleDisableData", status, item);

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            // return;
        }

        const abortController = new AbortController();

        try {
            const formdata = new FormData();
            formdata.append("seller_id", item?.user_id);
            formdata.append("status", status);

            // const result = await fetchWithSellerFormDataToken(`seller/enable-disable-seller`, formdata, storedToken, abortController);

            // console.log("delete category response", result);
            // if (result?.status === true) {

            //     fetchSellerData();
            //     setIsStatusOpen(false)
            // }
            // else {
            // }
            const response = await toastPromise(
                fetchWithSellerFormDataToken('seller/enable-disable-seller', formdata, storedToken, abortController, null),
                {
                    pending: "Updating seller status...",
                    success: (data) => data.message || "Status is changed successful !",
                    error: (data) => data.message || "Failed to change the seller list",
                }
            );

            if (response.status === true) {
                fetchSellerData();
                setIsStatusOpen(false)
            }
        } catch (error) {

        }
        finally {
            abortController.abort();
        }
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const targetElement = event.target;
            if (isStatusOpen !== null && !targetElement.closest(`#status_menu_${isStatusOpen}`) && !targetElement.closest('.relative')) {
                setIsStatusOpen(null);
            }
        };
        document.body.addEventListener('click', handleOutsideClick);
        return () => {
            document.body.removeEventListener('click', handleOutsideClick);
        };
    }, [isStatusOpen]);

    const handleSelectChange = (id) => {
        console.log("selectedId", id);
        setSelectedId(id);
        fetchSellerData(currentPage, id, searchQueryData)
    };

    const handleSearch = (searchQuery) => {
        console.log("Search query from SearchBar:", searchQuery);
        setSearchQueryData(searchQuery)
        fetchSellerData(currentPage, selectedId, searchQuery);
    };

    useEffect(() => {
        fetchSellerData(currentPage, selectedId, searchQueryData);
        return () => {
            controller.abort();
        };
    }, [currentPage, selectedId, searchQueryData]);

    return (
        <>
            <div className=' flex justify-between items-center text-xs p-5 max-lg:gap-5 w-full max-w-full '>
                {/* <div className='w-full max-w-full  '> */}
                <SearchBar onSearch={handleSearch} searchQueryData={searchQueryData} />
                {/* </div> */}
                <div className='flex items-center gap-5 max-lg:w-full'>
                    <SortingDropdown
                        options={[
                            { id: 1, title: 'asc' },
                            { id: 2, title: 'desc' },
                            { id: 3, title: 'none' },
                        ]}
                        selectedId={selectedId}
                        // onChange={handleSelectChange}
                        onChange={(id) => handleSelectChange(id)}
                    />
                    <div className=''>
                        <ExportIcon disabled={true} />
                    </div>
                    <AddIcon onClick={openModal} />
                </div>
            </div>

            <table className="w-full text-xs text-left rtl:text-right text-gray-500 ">
                <thead className="text-sm max-lg:text-xs text-gray-700  bg-gray-50  ">
                    <tr className='text-center'>

                        <th scope="col" className="px-6 py-3">
                            No.
                        </th>
                        <th scope="col" className="px-6 py-3 text-left">
                            Sellers Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Phone Number
                        </th>
                        <th scope="col" className="px-6 py-3">
                            City
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Enquiry Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Products
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Verified Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Status
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? (
                            <>
                                <tr>
                                    <td colSpan={9}>
                                        <Loader />
                                    </td>
                                </tr>

                            </>
                        ) : (
                            data?.length > 0 ?
                                (
                                    data && data?.map((item, index) => {
                                        const createdDate = item?.create_date;
                                        const dateOnly = new Date(createdDate).toLocaleDateString();
                                        const renderStatus = (status, index, item) => {
                                            console.log("renderStatusstatus", status, item);
                                            let textColor, bgColor, iconColor;
                                            switch (status) {
                                                case "active":
                                                    textColor = "text-green-600";
                                                    bgColor = "bg-green-50";
                                                    iconColor = "text-green-600";
                                                    break;
                                                case "deactive":
                                                    textColor = "text-red-700";
                                                    bgColor = "bg-red-50";
                                                    iconColor = "text-red-700";
                                                    break;
                                                default:
                                                    textColor = "text-gray-500";
                                                    bgColor = "";
                                                    iconColor = "text-gray-500";
                                            }

                                            return (
                                                <>

                                                    <div className='relative'>
                                                        <div className={`${bgColor} ${textColor} px-3 py-1 rounded-lg cursor-pointer`}
                                                            onClick={() => {
                                                                setSelectedStatus(status);
                                                                setIsStatusOpen(prevState => (prevState === index ? null : index));
                                                            }}
                                                        >
                                                            {status === "active" ? "Active" : status === "deactive" ? "Inactive" : "Unknown"}
                                                            <i className={`fa fa-angle-down ${iconColor} ms-2 `} />
                                                        </div>

                                                        {isStatusOpen === index && (
                                                            <div
                                                                id={`status_menu_${index}`}
                                                                className="absolute right-0 z-20 mt-2 origin-top-right  rounded-md  focus:outline-none"
                                                                role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1"
                                                            >
                                                                <ul className=" text-xs" aria-labelledby="user-menu-button">
                                                                    {status === "active" ?
                                                                        <li onClick={() => fetchSellerAbleDisableData('deactive', item)}>
                                                                            <div className="block px-5 py-2 text-red-800 bg-red-50 hover:text-red-700 cursor-pointer rounded-lg">
                                                                                Inactive
                                                                            </div>
                                                                        </li>
                                                                        :
                                                                        <li onClick={() => fetchSellerAbleDisableData('active', item)}>
                                                                            <div className="block px-5 py-2 text-green-600 bg-green-50 hover:text-red-700 cursor-pointer  rounded-lg">
                                                                                Active
                                                                            </div>
                                                                        </li>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </>
                                            );
                                        };
                                        return (
                                            <tr className="bg-white border border-t-0 border-x-0 hover:bg-gray-50 text-center" key={index}
                                            >
                                                <td className="px-6 py-4">
                                                    {(currentPage - 1) * 10 + index + 1}
                                                </td>
                                                <td className="px-6 py-4 flex items-center gap-3 justify-start cursor-pointer"
                                                    onClick={() => handleDetailClick(item?.user_id)}
                                                >
                                                    <ProfileImageComponent
                                                        profilePicUrl={item?.profile_pic}
                                                        className={`w-12 h-12 rounded-full border`}
                                                    />
                                                    {item?.seller_name || "-"}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item?.phone_number || "-"}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item?.city || "-"}
                                                </td>
                                                <td className="px-6 py-4">
                                                    <FormattedDate date={item?.enquiry_date || "-"} />
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item?.products || "-"}
                                                </td>
                                                <td className="px-6 py-4">
                                                    <FormattedDate date={item?.verified_at || "-"} />
                                                </td>
                                                <td className="px-6 py-4 capitalize font-bold">
                                                    {renderStatus(item?.status, index, item) || "-"}
                                                </td>
                                                <td className='text-center cursor-pointer'
                                                    onClick={() => openViewModal(item)}
                                                >
                                                    <div className='flex justify-center items-center gap-5 '>
                                                        <EyeIcon />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                )
                                :
                                (
                                    <TableNoDataFound colSpan={9} />
                                )

                        )
                    }
                </tbody>
            </table>
            {data?.length > 0 &&
                <div className='flex w-full justify-center items-center '>
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                </div>
            }
            {
                isModalOpen && (
                    <SellerModalBoxWrapper
                        title="Add Sellers"
                        firstContent={modalContent}
                        secondContent={secondContent}
                        buttonContent={buttonContent}
                        onClose={closeModal}
                        modalWidth={`max-w-5xl`}
                    />
                )
            }

            {isViewModalOpen && (
                <SellerModalBoxWrapper
                    title="View Sellers Details"
                    firstContent={modalViewContent}
                    secondContent={secondViewContent}
                    buttonContent={buttonViewContent}
                    onClose={closeViewModal}
                    modalWidth={`max-w-5xl`}
                />
            )}


        </>
    )
}

export default SellerApprovedList
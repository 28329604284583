import React from 'react'
import Navbar from '../../ReusableComp/Layout/NavbarComp/Navbar'
import CustomersList from './CustomersList'
import CustomerDetail from './CustomerDetail'
import { Route, Routes } from 'react-router-dom'



const Customerspage = () => {
  return (
    <>
      <Navbar name={'Customers'} />
      <div className='p-10 bg-gray-50'>
        <Routes>
          <Route path="/" element={<CustomersList />} />
          <Route path="/customer-detail" element={<CustomerDetail />} />
        </Routes>

      </div>
    </>
  )
}

export default Customerspage
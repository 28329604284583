import React from 'react'
import Navbar from '../../ReusableComp/Layout/NavbarComp/Navbar'
import Generalcontent from './Generalcontent'



const Generalpage = () => {
  return (
    <>
        <Navbar name={'General'} />
       <Generalcontent/>
    </>
  )
}

export default Generalpage
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Profile from "./Profile";
import Notification from "./Notification";
import Navbar from "../../ReusableComp/Layout/NavbarComp/Navbar";

const UserProfile = () => {

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [activeTab, setActiveTab] = useState(0);

    const tabs = ['Account Settings', 'Notification'];

    const content = [
        <Profile />,
        <Notification />,
    ];

    const handleTabClick = (index) => {
        setActiveTab(index);
    };


    return (
        <>
            <Navbar name={`Profile`} />
            <div className='p-10 bg-gray-50 h-full gap-5'>
                <div className="flex pt-5 px-5 bg-white rounded-t-3xl">
                    <div className="flex w-full border border-b-1 border-x-0 border-t-0 ">
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`cursor-pointer px-10 py-2 font-bold  ${activeTab === index ? 'mybzb_text_clr border border-b-2 border-x-0 border-t-0 border-red-800 ' : 'text-neutral-500'
                                    } ${tab === 'Account Settings' ? '' : ''}  `}
                                onClick={() => handleTabClick(index)}
                            >
                                {tab}
                            </div>
                        ))}
                    </div>
                </div>
                {content[activeTab]}
            </div>
        </>
    );
}

export default UserProfile
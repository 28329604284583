import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchWithSellerFormDataToken, fetchWithFormDataTokens } from '../../../../apiGeneralFunction';
import { TwoBreadcrumb } from '../../../ReusableComp/Breadcrumb';
import ProfileImageComponent from '../../../ReusableComp/ProfileImageComponent';
import TrimmedComponent from '../../../ReusableComp/TrimmedComponent';
import { FormattedDate } from '../../../ReusableComp/FormattedDate';
import { FilterIcon } from '../../../ReusableComp/FilterIcon';
import { ExportIcon } from '../../../ReusableComp/Icons';
import ImgComp from '../../../ReusableComp/ImgComp';
import { NumberConversion } from '../../../ReusableComp/NumberConversion';
import * as Images from '../../../../assets';
import Pagination from '../../../ReusableComp/Pagination';
import TableNoDataFound from '../../../ReusableComp/TableNoDataFound';

const CustomerDetail = () => {

    const location = useLocation();
    // const { customerId } = location?.state;

    // console.log("customerId", customerId);
    // const { customerId } = useParams();
    const navigate = useNavigate();
    const customerId = location?.state?.customerDetailId;

    console.log("customerId", customerId);

    const [customerOrderData, setCustomerOrderData] = useState([]);
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState(null);
    // const [searchQueryData, setSearchQueryData] = useState('');
    const [totalPages, setTotalPages] = useState();
    const [selectedId, setSelectedId] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const controller = new AbortController();

    useEffect(() => {
        fetchCustomerData()
        fetchCustomerDetail()
        return () => {

            controller.abort();
        };
    }, [customerId]);

    useEffect(() => {
        if (userData) {
            const fullName = [userData?.firstname, userData?.middlename, userData?.lastname]
                .filter(Boolean) // Remove null/undefined values
                .join(' '); // Join with space

            const formattedAddress = userData?.address?.user_addresses?.[0]
                ? `${userData.address.user_addresses[0].flatno_building}, ${userData.address.user_addresses[0].street_area}, ${userData.address.user_addresses[0].city}, ${userData.address.user_addresses[0].state}, ${userData.address.user_addresses[0].country} - ${userData.address.user_addresses[0].pincode}`
                : '-';

            setUserData({
                ...userData,
                fullName,
                formattedAddress,
            });
        }
    }, [userData]);


    const fetchCustomerDetail = async () => {

        const tokenValue = localStorage?.getItem('login api response token');
        // const storedToken = JSON?.parse(tokenValue);

        // const user_id = localStorage?.getItem('user_data') || '';
        // const userId = user_id ? JSON.parse(user_id) : {};

        try {
            // const tokenValue = localStorage?.getItem('login api response token') || '';

            const formdata = new FormData();
            // formdata.append("seller_id", userId?.user_id);
            formdata.append("user_id", customerId);
            // formdata.append("page", page);

            if (tokenValue) {
                try {
                    const storedToken = JSON.parse(tokenValue);
                    if (storedToken) {
                        const result = await fetchWithFormDataTokens('api/v1/get_profile', formdata, storedToken);
                        // c
                        if (result?.status === true) {
                            setUserData(result?.data)
                        }
                        else {
                        }
                    }
                } catch (error) {
                }
            } else {

            }
        } catch (error) {
        }
    };

    const fetchCustomerData = async (currentPage = 1, sortid = 0) => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);
        // const user_id = localStorage?.getItem('login data');
        // const userId = JSON.parse(user_id);
        // console.log("categories login id", userId);
        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        //console.log("categories login id", userId);
        if (!storedToken) {
            navigate('/');
            // return;
        }

        const abortController = new AbortController();
        try {

            let sortByFilter = "";
            if (sortid === 1) {
                sortByFilter = "today";
            } else if (sortid === 2) {
                sortByFilter = "yesterday";
            } else if (sortid === 3) {
                sortByFilter = "week";
            } else if (sortid === 4) {
                sortByFilter = "month";
            } else if (sortid === 5) {
                sortByFilter = "3_month";
            } else if (sortid === 6) {
                sortByFilter = "6_month";
            } else if (sortid === 7) {
                sortByFilter = "year";
            } else if (sortid === 8) {
                sortByFilter = "all";
            }

            const formdata = new FormData();
            formdata.append("id", customerId);
            formdata.append("filter", sortByFilter);
            formdata.append("page", currentPage);

            const result = await fetchWithSellerFormDataToken(`seller/get-customer-order-list`, formdata, storedToken, abortController);

            if (result?.status === true) {
                setData(result?.data?.userData);
                setCustomerOrderData(result?.data?.orderList)
                setTotalPages(result?.data?.pagination?.total_pages)
            }
            else {
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    }


    // const fetchCustomerData = async (currentPage = 1, filterValue = "") => {

    //     console.log("filterValue", filterValue);

    //     const tokenValue = localStorage?.getItem('login api response token');
    //     const storedToken = JSON?.parse(tokenValue);
    //     const user_id = localStorage?.getItem('login data');
    //     const userId = JSON.parse(user_id);
    //     console.log("categories login id", userId);
    //     if (!storedToken) {
    //         navigate('/');
    //         // return;
    //     }
    //     const abortController = new AbortController();
    //     try {

    //         const formdata = new FormData();
    //         formdata.append("id", customerId);
    //         formdata.append("filter", filterValue);
    //         formdata.append("page", currentPage);
    //         // formdata.append("seller_id", userId?.user_id);
    //         const result = await fetchWithSellerFormDataToken(`seller/get-customer-order-list`, formdata, storedToken, abortController);

    //         if (result?.status === true) {
    //             setData(result?.data?.userData);
    //             setCustomerOrderData(result?.data?.orderList)
    //             setTotalPages(result?.data?.pagination?.total_pages)
    //         }
    //         else {
    //         }
    //     } catch (error) {

    //     }
    //     finally {

    //         abortController.abort();
    //     }
    // }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchCustomerData(pageNumber, 0);
    };

    // const handleFilterSelect = (filterValue) => {
    //     console.log("Selected filter:", filterValue);
    //     fetchCustomerData(filterValue)
    // };

    const handleSelectChange = (id) => {
        console.log("Hello", id);
        //setSelectedId(id);
        //console.log("setSelectedId", setSelectedId);

        fetchCustomerData(currentPage, id);
        // setSelectedId = id
    };

    return (
        <>
            <TwoBreadcrumb
                href1={`/dashboard/customers`}
                name1={`Customer`}
                href2={``}
                name2={`Customer details`}

            />

            <section className="flex gap-5 justify-between self-stretch p-6 bg-white rounded-3xl max-md:flex-wrap max-md:px-5 shadow-md">
                <div className="flex  justify-between max-md:flex-wrap divide-x gap-5">
                    <ProfileImageComponent
                        profilePicUrl={userData?.profile_pic}
                        className={`shrink-0 max-w-full aspect-square w-[250px] rounded-lg`}
                    />
                    <div className="flex flex-col max-md:max-w-full ps-5">
                        <h2 className="text-2xl font-semibold leading-9 text-neutral-900 max-md:max-w-full">
                            <TrimmedComponent trimdata={userData?.fullName} />
                        </h2>
                        <div className="mt-4 max-md:max-w-full">
                            <section className="flex gap-5 max-md:flex-col max-md:gap-0 grid grid-cols-2 flex-row">

                                <div className="flex flex-col">
                                    <div className="text-xs leading-5 text-neutral-400">Phone Number</div>
                                    <div className="text-base text-zinc-800 font-semibold">
                                        {userData?.mobile_number || '-'}
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="text-xs leading-5 text-neutral-400">Email</div>
                                    <div className="text-base text-zinc-800 font-semibold">
                                        <TrimmedComponent trimdata={userData?.email} />
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="text-xs leading-5 text-neutral-400">Register Date</div>
                                    <div className="text-base text-zinc-800 font-semibold">
                                        <FormattedDate date={userData?.register_date} />
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="text-xs leading-5 text-neutral-400">Address</div>
                                    <div className="text-base text-zinc-800 font-semibold">
                                        <TrimmedComponent trimdata={userData?.formattedAddress} />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <span
                    className="justify-center self-start px-5 py-2 text-sm font-medium text-red-700 whitespace-nowrap bg-rose-50 rounded-lg max-md:px-5"
                    tabIndex="0"
                >
                    Customer
                </span>
            </section>

            <div className='bg-white rounded-3xl mt-5'>
                <div className='w-full flex justify-end items-center gap-5 p-5'>
                    <ExportIcon disabled={true} />
                    {/* <FilterIcon onFilterSelect={handleFilterSelect} /> */}
                    <FilterIcon
                        onClick={(id) => handleSelectChange(id)} />
                </div>

                <div className="">
                    <table className="w-full text-xs text-left rtl:text-right  text-neutral-800 ">
                        <thead className="text-sm max-lg:text-xs  text-neutral-800  bg-gray-50  ">
                            <tr className='text-center'>

                                <th scope="col" className="px-6 py-3  max-lg:px-3">
                                    No.
                                </th>
                                <th scope="col" className="px-6 py-3  max-lg:px-3">
                                    Order ID
                                </th>
                                <th scope="col" className="px-6 py-3  max-lg:px-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3  max-lg:px-3">
                                    Payment Method
                                </th>
                                <th scope="col" className="px-6 py-3  max-lg:px-3">
                                    Location
                                </th>
                                <th scope="col" className="px-6 py-3  max-lg:px-3">
                                    Seller Name
                                </th>
                                {/* <th scope="col" className="px-6 py-3  max-lg:px-3">
                                    Total Items
                                </th> */}
                                <th scope="col" className="px-6 py-3  max-lg:px-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3  max-lg:px-3">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3  max-lg:px-3">
                                    Invoice
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-xs'>
                            <>
                                {customerOrderData?.length > 0 ?
                                    customerOrderData && customerOrderData?.map((item, index) => {

                                        const renderStatus = (status) => {
                                            console.log("status", status);
                                            switch (status) {
                                                case "1":
                                                    return <span className="text-amber-600 bg-amber-50  px-3 rounded-lg">Pending</span>;
                                                case "2":
                                                    return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Confirm</span>;
                                                case "3":
                                                    return <span className="text-blue-600  bg-blue-50 px-3 rounded-lg">Shipping</span>;
                                                case "4":
                                                    return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Delivered</span>;
                                                case "5":
                                                    return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Completed</span>;
                                                case "6":
                                                    return <span className="text-red-600 bg-red-50 px-3 rounded-lg">Cancelled</span>;
                                                case "7":
                                                    return <span className="text-amber-600 bg-amber-50  px-3 rounded-lg">Returned</span>;
                                                default:
                                                    return <span className="text-gray-500">Unknown</span>;
                                            }
                                        };

                                        return (
                                            <>
                                                <tr
                                                    className="bg-white cursor-pointer  hover:bg-gray-50 text-center" key={index}
                                                >
                                                    <td className="px-6 py-4">
                                                        {(currentPage - 1) * 10 + index + 1}
                                                    </td>

                                                    <td className="px-6 py-4">

                                                        <TrimmedComponent trimdata={item?.order_id} />
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <FormattedDate date={item?.date || "-"} />
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <TrimmedComponent trimdata={item?.payment_method} />
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <TrimmedComponent trimdata={item?.location} />
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <TrimmedComponent trimdata={item?.seller_name} />
                                                    </td>
                                                    {/* <td className="px-6 py-4">
                                                        <TrimmedComponent trimdata={item?.payment_method} />
                                                    </td> */}
                                                    <td className="px-6 py-4">
                                                        <NumberConversion priceConvert={item?.amount} />
                                                    </td>
                                                    <td className="px-6 py-4 font-bold">
                                                        {renderStatus(item?.status)}
                                                    </td>
                                                    <td className="text-center cursor-pointer px-6 py-4">
                                                        <a
                                                            href={item?.invoice?.invoice_link}
                                                            download
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <ImgComp
                                                                src={Images.pdf_icn}
                                                                className="w-8 mx-auto"
                                                                trimdata={item?.invoice?.invoice_link}
                                                            />
                                                        </a>
                                                    </td>

                                                    {/* <td className='text-center cursor-pointer px-6 py-4'
                                                    >
                                                        <ImgComp
                                                            src={Images.pdf_icn}
                                                            className='w-8 mx-auto'
                                                            trimdata={item?.invoice?.invoice_link}
                                                        />

                                                    </td> */}
                                                </tr>

                                            </>
                                        )
                                    })
                                    :
                                    <TableNoDataFound colSpan={7} />
                                }
                            </>
                        </tbody>

                    </table>
                    {customerOrderData?.length > 0 &&
                        <div className='flex w-full justify-center items-center my-5'>
                            <Pagination
                                totalPages={totalPages}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    }
                </div>

                {/* {data?.length > 0 &&
                    <div className='flex w-full justify-center items-center my-5'>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>
                } */}
            </div>
        </>
    )
}

export default CustomerDetail
import React from 'react'

const TableNoDataFound = ({colSpan}) => {
    return (
        <tr>
            <td colSpan={colSpan}>
                <div className="w-full p-8 text-center uppercase ">
                    No Data Found
                </div>
            </td> 
        </tr>
    )
}

export default TableNoDataFound  
export const SearchBar = ({ onSearch, searchQueryData }) => {


    const onInputSearch = (e) => {
        const newQuery = e.target.value;
        onSearch(newQuery);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            onInputSearch(e);
        }
    };
 
    
    return (
        <>
            <div
                className="xl:w-[40%] 2xl:w-[40%] max-lg:w-full  flex gap-3 self-stretch justify-between px-5 py-2 my-auto  leading-5 bg-white rounded-lg  ms-0 border "
            >

                <div className="flex gap-5 items-center justify-between w-full max-md:w-full">
                    <div
                    // onClick={onSearch}
                    >
                        <button className=" focus:border-0 focus:outline-0 focus:ring-0">
                            <i className="fa fa-search text-stone-300"></i>
                        </button>
                    </div>
                    <input
                        className="text-xs p-0 border-0 w-full focus:border-0 focus:outline-0 focus:ring-0"
                        placeholder="Search here..."
                        value={searchQueryData}
                        onChange={onInputSearch}
                        onKeyPress={handleKeyPress}
                    />
                </div>
            </div>
        </>
    );
}
import React from 'react';

const TrimmedComponent = ({ trimdata }) => {
    // const trimmed = trimdata?.trim() ? trimdata?.trim() : '-';
    const trimmed = typeof trimdata === 'string' && trimdata?.trim() ? trimdata?.trim() : '-';
    return (
        <span>
            {trimmed}
        </span>
    );
}

export default TrimmedComponent;  

import React, { useEffect, useState } from "react";
import ImgComp from "../../../ImgComp";
import * as Images from '../../../../../assets';
import { fetchWithFormDataToken, fetchWithFormDataWithoutBody, fetchWithFormDataWithoutBodys, fetchWithSellerFormDataToken } from "../../../../../apiGeneralFunction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Drawermodal from "../Drawermodal";

const Navbar = ({ name }) => {

    const navigate = useNavigate();

    const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
    const [data, setData] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const controller = new AbortController();

    useEffect(() => {
        fetchProfileData();
        return () => {
            controller.abort();
        };
    }, []);

    const fetchProfileData = async () => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);
        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);
        if (!storedToken) {
            navigate('/');
            // return;
        }
        const abortController = new AbortController();
        try {
            const formdata = new FormData();
            formdata.append("id", userId?.user_id);
            const result = await fetchWithSellerFormDataToken(`seller/get-profile`, formdata, storedToken, abortController);
            if (result?.status === true) {
                setData(result?.data);
            }
            else {
            }
        } catch (error) {
        }
        finally {
            abortController.abort();
        }
    }

    const clearCacheAndCookies = async () => {
        // Clear cache
        if (caches) {
            const cacheNames = await caches.keys();
            cacheNames.forEach(cacheName => {
                caches.delete(cacheName);
            });
        }

        // Clear cookies
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        }
    };


    // const handleLogout = () => {
    //     const fetchAPI = async () => {
    //         try {
    //             const tokenValue = localStorage.getItem('login api response token');
    //             const storedToken = JSON.parse(tokenValue);
    //             console.log(tokenValue);
    //             console.log(storedToken);
    
    //             if (!storedToken) {
    //                 navigate('/register');
    //                 return;
    //             }
    
    //             const result = await fetchWithFormDataWithoutBody('api/v1/logout', storedToken);
    
    //             if (result?.status === true) {
    //                 toast.success(result?.message, {
    //                     position: 'top-center',
    //                     autoClose: 2000,
    //                 });
    
    //                 // Clear user data
    //                 localStorage.clear();
    //                 await clearCacheAndCookies();
    
    //                 // Navigate to login page
    //                 navigate('/');
    //             } else {
    //                 toast.error(result?.message, {
    //                     position: 'top-center',
    //                     autoClose: 3000,
    //                 });
    //             }
    //         } catch (error) {
    //             console.error('Logout error:', error);
    //             toast.error(`Error: ${error.message || 'An unexpected error occurred.'}`, {
    //                 position: 'top-center',
    //                 autoClose: 3000,
    //             });
    //         }
            
    //     };
    
    //     fetchAPI();
    // };
    

    const handleLogout = () => {
        const fetchAPI = async () => {
            const tokenValue = localStorage.getItem('login api response token');
            const storedToken = JSON.parse(tokenValue);

            if (!storedToken) {
                navigate('/');
                return;
            }

            else {
                try {

                    const result = await fetchWithFormDataWithoutBodys('api/v1/logout', storedToken);
                    // console.log('API logout response:', result);
                    if (result?.status === true) {
                        // toast.success(result?.message, {
                        //     position: 'top-center',
                        //     autoClose: 2000,
                        //     onClose: () => {
                        //         navigate('/');

                        //     }
                        // })
                        localStorage.clear();

                        // Clear cache and cookies
                        await clearCacheAndCookies();

                        // Navigate to login page
                        navigate('/');

                    }
                    else {
                        toast.error(result?.message, {
                            position: 'top-center',
                            autoClose: 3000,
                        });
                    }



                } catch (error) {

                }
            }


        };
        fetchAPI();
    }

    const handleDrawerToggle = () => {
        setIsDrawerOpen((prevState) => !prevState);
    };

    return (
        <>
            <div className="flex justify-center items-center px-16 py-8  text-base bg-white shadow-2xl text-neutral-900 max-md:px-5 ">
                <div className="flex justify-between items-center max-md:flex-col max-md:justify-end w-full max-md:gap-5">
                    <div className="flex items-center gap-5 max-md:w-full">
                        <div
                            className="lg:hidden">
                            <button
                                className="text-white mybzb_bg_clr px-3 py-1 focus:border-red-800 focus:ring-0 focus:outline-0 self-end text-end cursor-pointer font-bold leading-5 rounded-md w-full"
                                onClick={handleDrawerToggle}
                            >
                                <i className="fa fa-bars text-lg"></i>
                            </button>
                        </div>
                        <div className="flex justify-between items-center font-bold text-2xl max-lg:text-xl max-md:flex-wrap">
                            {name}
                        </div>
                    </div>
                    <div className=' flex gap-5 items-center max-md:justify-end max-md:w-full'>
                        <div
                            className='relative'
                        >
                            <div
                                
                                className='cursor-pointer font-bold flex items-center justify-around  bg-white rounded-lg py-2 '
                            >
                                <ImgComp
                                    src={Images.India_flag}
                                    srcSet={Images.India_flag}
                                    alt={'India_flag'}
                                    className={'w-[15%] '}
                                />
                                <div>
                                    <div className='text-sm max-sm:text-xs'>{'ENG (IND)'}</div>
                                </div>
                                <i className="fa fa-angle-down text-neutral-500 font-bold"></i>
                            </div>
                        </div>
                        <div className=' py-1.5 px-2.5 rounded-xl cursor-pointer bg-amber-50'>
                            <div className='relative'>
                                <i className='fa fa-bell-o text-xl text-amber-500 max-sm:text-lg'></i>
                                <span className="absolute top-1.5 left-2.5 transform -translate-y-1/2 w-2 h-2 bg-red-500  rounded-full"></span>
                            </div>
                        </div>

                        <div
                            className='relative'
                        >
                            <div
                            onClick={() => setIsUserProfileOpen(!isUserProfileOpen)}
                                // onClick={() => setIsUserProfileOpen(!isUserProfileOpen)}
                                // onClick={() => navigate('/dashboard/profile')}
                                className='font-bold cursor-pointer flex items-start gap-3 bg-white px-2.5 rounded-lg py-1 border border-neutral-300'
                            >
                                <span className="sr-only">Open user menu</span>
                                <i className='fa fa-user-circle text-3xl text-red-800 max-md:text-2xl'></i>
                                <div>
                                    <div className='text-sm max-md:text-xs'>{'MyBzB'}</div>

                                    <div className='text-xs text-neutral-400 max-md:text-[10px]'>Admin</div>
                                </div>
                                <i className="fa fa-angle-down"></i>
                            </div>
                            {isUserProfileOpen &&
                                <div
                                    id="user_menu"
                                    className="absolute right-0 z-20 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1"
                                >
                                    <ul className="py-2" aria-labelledby="user-menu-button">
                                        <li>
                                            <div
                                            onClick={() => navigate('/dashboard/profile')}
                                                className="block px-4 py-2 text-sm text-zinc-800 hover:terms_bg_clr hover:text-orange-500 cursor-pointer"
                                            >
                                                My Account
                                            </div>
                                        </li>
                                        <li>
                                            <div
                                                className="block px-4 py-2 text-sm text-zinc-800 hover:terms_bg_clr hover:text-orange-500 cursor-pointer"
                                                onClick={() => handleLogout()}
                                            >
                                                Logout
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Drawermodal
                isOpen={isDrawerOpen}
                handleDrawerToggle={handleDrawerToggle}
            />
        </>
    );
}


export default Navbar
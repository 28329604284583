import React, { useEffect, useState } from 'react'
import ImgComp from '../../../ReusableComp/ImgComp'
import * as Images from '../../../../assets';
import { ExportIcon, EyeIcon, Search, SortIcon } from '../../../ReusableComp/Icons';
import { useNavigate } from 'react-router-dom';
import StarRating from '../../../ReusableComp/StarRating';
import { fetchDataWithToken, fetchWithFormDataToken, fetchWithSellerFormDataToken } from '../../../../apiGeneralFunction';
import { SearchBar } from '../../../ReusableComp/SearchBar';
import SortingDropdown from '../../../ReusableComp/SortingDropdown';
import Pagination from '../../../ReusableComp/Pagination';
import ProfileImageComponent from '../../../ReusableComp/ProfileImageComponent';
import TrimmedComponent from '../../../ReusableComp/TrimmedComponent';
import { FormattedDate } from '../../../ReusableComp/FormattedDate';
import ReviewModalBox from './ReviewModalBox';
import TableNoDataFound from '../../../ReusableComp/TableNoDataFound';

const ReviewsList = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedId, setSelectedId] = useState('');
    const [searchQueryData, setSearchQueryData] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState();

    const controller = new AbortController();

    useEffect(() => {
        fetchReviewData(currentPage, selectedId, searchQueryData);;
        return () => {
            controller.abort();
        };
    }, [currentPage, selectedId, searchQueryData]);


    const fetchReviewData = async (page = 1, selectedId = "", searchQuery = '') => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }

        const abortController = new AbortController();

        try {

            let sortByValue = "";
            if (selectedId === 1) {
                sortByValue = "asc";
            } else if (selectedId === 2) {
                sortByValue = "desc";
            }

            const formdata = new FormData();
            formdata.append("user_id", userId?.user_id);
            formdata.append("page", page);
            formdata.append("sort_by", sortByValue);
            // formdata.append("search", searchQuery || '');
            const result = await fetchWithFormDataToken(`list_review_rating`, formdata, storedToken, abortController);

            console.log("delete category response", result);
            if (result?.status === true) {
                setData(result?.data?.review_data);
                setTotalPages(result?.data?.pagination?.total_pages)
            }
            else {
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    }

    const handleSearch = (searchQuery) => {
        setSearchQueryData(searchQuery)
        fetchReviewData(currentPage, selectedId, searchQuery);
    };


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchReviewData(currentPage, selectedId, searchQueryData);
    };

    const handleSelectChange = (id) => {
        setSelectedId(id);
        fetchReviewData(currentPage, id, searchQueryData)
    };

    const modalBoxData = (item) => {
        console.log("review data", item);
        setIsModalOpen(true)
        setModalData(item)
    }


    const Previeworder = () => {

        return (
            <>
                <div className='flex flex-col items-end h-full overflow-y-auto'>
                    <div className='w-full'>
                        <section
                            className=" flex flex-col grow justify-center rounded-xl max-md:max-w-full"
                        >
                            <div className='flex justify-between w-full max-md:flex-col' >
                                <div className='flex gap-5 w-full'>
                                    {/* <ImgComp
                                        className={`w-28 h-24 border rounded-xl`}
                                        src={modalData?.product_image ? modalData?.product_image : Images.logo}
                                    /> */}
                                    <ProfileImageComponent
                                        profilePicUrl={modalData?.product_image}
                                        className={`w-12 h-12 rounded-full border`}
                                    />
                                    <div className='flex justify-between w-full max-md:flex-col max-lg:gap-3'>
                                        <div className='flex justify-start gap-1 items-start flex-col max-md:gap-0 max-md:w-full'>
                                            <div className='mybzb_text_clr font-bold text-xs '
                                            >
                                                Order ID:&nbsp;
                                                <span>{modalData?.product_id_display} </span>
                                            </div>
                                            <div
                                                className='text-lg font-medium max-md:text-base '
                                            >
                                                {modalData?.product_name}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <hr className='mt-5' />
                        <div className='flex flex-col items-center justify-center py-3 gap-1'>
                            <div
                                className='  text-xs text-neutral-500'
                            >
                                Product Rating
                            </div>

                            <StarRating value={modalData?.rating} />
                        </div>
                        <hr />
                        <div className='flex flex-col py-3 gap-2'>
                            <div>Comment</div>
                            <div
                                className='rounded-lg text-neutral-500 border p-2 border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] '
                            >
                                {modalData?.comment}
                            </div>
                        </div>
                        {modalData?.images?.length > 0 &&
                            <div className='py-3 gap-2 border border-gray-300 border-dashed terms_bg_clr w-full rounded-lg flex flex-row items-center justify-center'>
                                {modalData?.images.map((image, index) =>
                                (
                                    <ProfileImageComponent
                                        profilePicUrl={image}
                                        className={`w-12 h-12 rounded-full border`}
                                    />
                                ))
                                }
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <div className='w-full flex justify-between items-center mt-5 bg-white p-5 rounded-t-xl'>
                {/* <SearchBar onSearch={handleSearch} searchQueryData={searchQueryData} /> */}
                <div className='flex items-center gap-5 ms-auto'>
                    <SortingDropdown
                        options={[
                            // { id: 1, title: 'asc' },
                            // { id: 2, title: 'desc' },
                            { id: 1, title: 'Low to High' },
                            { id: 2, title: 'High to Low' },
                        ]}
                        selectedId={selectedId}
                        onChange={(id) => handleSelectChange(id)}
                    />
                    <ExportIcon disabled={true} />
                </div>
            </div>
            <div className=' bg-white rounded-b-xl shadow-md'>
                <div className="  sm:rounded-lg mt-2  ">
                    <table className="w-full text-xs text-left rtl:text-right text-gray-500 ">
                        <thead className="text-sm text-gray-700  bg-gray-50  ">
                            <tr className='text-center'>

                                <th scope="col" className="px-6 py-3">
                                    No.
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Customers
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Product Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Sellers Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Categories
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Added Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Reviews
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-xs'>
                            {data?.length > 0 ?
                                data && data?.reverse().map((item, index) => {
                                    const createdDate = item?.create_date;
                                    const dateOnly = new Date(createdDate).toLocaleDateString();
                                    return (
                                        <tr className="bg-white   hover:bg-gray-50 text-center"
                                        // key={index}
                                        // onClick={() => navigate('category-productdetail')}
                                        >
                                            <td className="px-6 py-4">
                                                {(currentPage - 1) * 10 + index + 1}
                                            </td>
                                            <td className="px-6 py-4 ">
                                                <div className='flex items-center gap-5'>
                                                    <ProfileImageComponent
                                                        profilePicUrl={item?.user_image}
                                                        className={`w-12 h-12 rounded-full border`}
                                                    />
                                                    <TrimmedComponent trimdata={item?.customer_name} />
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 cursor-pointer"
                                            // onClick={() => navigate('product-detail', { state: { productId: item?.id } })}
                                            >
                                                <div className='flex items-center gap-5'>
                                                    <ProfileImageComponent
                                                        profilePicUrl={item?.product_image}
                                                        className={`w-12 h-12 rounded-full border`}
                                                    />
                                                    <TrimmedComponent trimdata={item?.product_name} />
                                                </div>
                                            </td>
                                            <td className="px-6 py-4">
                                                <div className='flex items-center gap-5'>
                                                    <ProfileImageComponent
                                                        profilePicUrl={item?.seller_image}
                                                        className={`w-12 h-12 rounded-full border`}
                                                    />
                                                    <TrimmedComponent trimdata={item?.seller_name} />
                                                </div>
                                            </td>
                                            <td className="px-6 py-4">
                                                <TrimmedComponent trimdata={item?.category_name} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <FormattedDate date={item?.date} />
                                            </td>
                                            <td className='px-6 py-8 mx-auto flex items-center justify-center'>
                                                <StarRating value={item?.rating} />
                                            </td>
                                            <td className="px-6 py-4 ">
                                                <div className='flex items-center justify-center gap-5'>
                                                    {/* <ImgComp
                                                    src={Images.edit_icon}
                                                    className='w-7 bg-gray-50 p-1 rounded-lg '
                                                />
                                                <ImgComp
                                                    src={Images.delete_icon}
                                                    className='w-6 '
                                                /> */}
                                                    <EyeIcon
                                                        onClick={() => modalBoxData(item)}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <TableNoDataFound colSpan={8} />
                            }
                        </tbody>
                    </table>
                </div>
                <hr/>
                {data?.length > 0 &&
                    <div className='flex w-full justify-center items-center mb-5'>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>
                }
            </div>
            {isModalOpen && (
                <ReviewModalBox
                    title="Detail Review Rating"
                    content={<Previeworder />}
                    onClose={() => setIsModalOpen(false)}
                    modalWidth={`max-w-lg`}
                />
            )}
        </>
    )
}

export default ReviewsList
import React, { memo } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = memo(({ onSubmit, value, onChange, labelName }) => {

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];

    return (
        <>
            <form onSubmit={onSubmit}>
                <div className='text-black font-bold mb-3'>
                    {labelName}
                    <span className='mybzb_text_clr'> *</span>

                </div>
                <div className='p-3 shadow-lg'>
                    <ReactQuill
                        theme='snow'
                        value={value}
                        onChange={onChange}
                        style={{ border: '0px !important' }}
                        modules={modules}
                        formats={formats}
                    />
                </div>
            </form>
        </>
    )
})

export default RichTextEditor
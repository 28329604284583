import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDataWithToken, fetchWithFormDataToken } from '../../../../apiGeneralFunction';
import ModalBoxWrapper from '../../../ReusableComp/ModalBoxWrapper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../../ReusableComp/Loader';


const Generalcontent = () => {

  const navigate = useNavigate();
  const controller = new AbortController();

  const [generalList, setGeneralList] = useState([]);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [nameUpdate, setNameUpdate] = useState('');
  const [idUpdate, setIdUpdate] = useState('');
  const [descUpdate, setDescUpdate] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
    return () => {
      controller.abort();
    };
  }, []);

  const headings = [
    {
      id: 15,
      title: 'Safety Information'
    },
    {
      id: 16,
      title: 'Legal Disclaimer'
    },
    {
      id: 1,
      title: 'Support Email'
    },
    {
      id: 2,
      title: 'My BzB Website Link'
    },
    {
      id: 3,
      title: 'My BzB Contact Number'
    },
    {
      id: 4,
      title: 'My BzB Address'
    },
    {
      id: 5,
      title: 'Latitude'
    },
    {
      id: 6,
      title: 'Longitude'
    },
    {
      id: 7,
      title: 'Become a Seller Form Link'
    },
    {
      id: 9,
      title: 'Privacy Policy Link'
    },
    {
      id: 10,
      title: 'Terms & Condition Link'
    },
    {
      id: 8,
      title: 'About Us Link'
    },
    {
      id: 11,
      title: 'FAQs Link'
    },
    {
      id: 12,
      title: 'Return Policy Link'
    },
    {
      id: 13,
      title: 'Key'

    },
    {
      id: 14,
      title: 'Secret Key'

    },
  ]

  const closeEditModal = () => {
    setIsModalEditOpen(false);
  };

  const fetchData = async () => {
    setLoading(true)
    try {
      const tokenValue = localStorage?.getItem('token');
      const storedToken = JSON?.parse(tokenValue);
      if (!storedToken) {
        navigate('/');
        return;
      }
      const result = await fetchDataWithToken('list-general-settings', storedToken, navigate);

      if (result?.status === true) {
        setGeneralList(result?.data)
      }
    } catch (error) {
    }
    finally {
      setLoading(false)
    }
  };

  const InfoCardTitle = ({ title }) => {
    return (
      <>
        {/* {heading?.length > 0 && heading.map((item, index) => (
          <>  */}

        <h2 className="text-base font-semibold leading-8 ">
          {title}
          <span className="text-red-700">*</span></h2>
        {/* </>
        ))} */}
      </>
    )
  }


  const InfoCard = ({ title, id, imageSrc, description, descId }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(description);

    const updateItem = (id, title, description) => {

      console.log("item general update", id, title, description);
      setIdUpdate(id)

      //setIsModalEditOpen(true);
      setNameUpdate(title)
      setInputValue(description)

    };

    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };

    const handleEditClick = () => {
      setIsEditing(true);
    };

    // const handleSaveClick = () => {
    //   setIsEditing(false);
    //   // Here you can handle the save functionality, like sending the updated description to the backend
    //   updateItem(id, title, inputValue);
    // };

    const updateAPI = async () => {
      setIsEditing(false);
      updateItem(id, title, inputValue);
      const tokenValue = localStorage?.getItem('token');
      const storedToken = JSON?.parse(tokenValue);

      const user_id = localStorage?.getItem('login data');
      const userId = JSON.parse(user_id);
      console.log("categories login id", userId);

      if (!storedToken) {
        navigate('/');
        return;
      }


      const abortController = new AbortController();

      try {

        const formdata = new FormData();


        formdata.append("id", idUpdate);
        formdata.append("title", nameUpdate);
        // formdata.append("description", descUpdate);

        formdata.append("description", inputValue);
        const result = await fetchWithFormDataToken('update-general-settings', formdata, storedToken, abortController);

        console.log("delete category response", result);
        if (result?.status === true) {
          toast.success(result?.message, {
            position: 'top-center',
            autoClose: 2000,
          });
          fetchData();

          setDescUpdate('');
          setNameUpdate('')
        }
        else {
          toast.error(result?.message, {
            position: 'top-center',
            autoClose: 2000,
          });

        }
      } catch (error) {

      }
      finally {

        abortController.abort();
      }
    }

    console.log("InfoCard id", title, id);
    return (
      <section className="flex flex-col grow p-4 rounded-xl border border-gray-200 border-solid max-md:mt-6 max-md:max-w-full">
        <header className="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full">
          {/* <h2 className="text-base font-semibold leading-8 ">{title}<span className="text-red-700">*</span></h2> */}

          <InfoCardTitle title={title} />
          {/* <div
            onClick={() => updateItem(id, title, description)}
            className="flex justify-center items-center px-1 py-0.5 my-auto w-6 h-6 bg-neutral-50 rounded-[360px]">
           
            <i
              className='fa fa-pencil text-sm text-gray-400'></i>
          </div> */}
          <div
            onClick={isEditing ? updateAPI : handleEditClick}
            className="flex justify-center items-center px-1 py-0.5 my-auto w-6 h-6 bg-neutral-50 rounded-[360px]"
          >
            <i
              onClick={() => updateItem(id, title, description)}
              className={`fa ${isEditing ? 'fa-check-circle' : 'fa-pencil'} text-base ${isEditing ? 'text-green-800' : 'text-gray-400'}`}></i>
          </div>
        </header>

        {isEditing ? (

          <textarea
            value={inputValue}
            className='mt-3 w-full border border-gray-200 rounded-lg h-full h-32'
            onChange={handleInputChange}
          >
          </textarea>
        ) : (
          <p className="justify-center px-3.5 py-3 mt-4 text-sm rounded-lg bg-neutral-50 text-neutral-900 max-md:max-w-full">{description}</p>
        )}

        {/* <p className="justify-center px-3.5 py-3 mt-4 text-sm rounded-lg bg-neutral-50 text-neutral-900 max-md:max-w-full">{description}</p> */}
      </section>
    );
  };

  const modalEditContent = (
    <>
      <div className='flex flex-col  '>
        <div className='text-left mb-5 font-bold text-xl'>
          General Information
        </div>
        <div className='flex justify-between gap-10 '>
          <div className='w-full flex flex-col  gap-5 justify-around'>
            <div className='flex flex-col items-start'>
              <label className='text-neutral-500'>Title

              </label>
              <input
                className='mt-3 w-full border border-gray-200 rounded-lg bg-gray-100'
                placeholder='Enter Name'
                type='text'
                value={nameUpdate}
                onChange={(e) => setNameUpdate(e.target.value)}
                readOnly
              />

            </div>

            <div className='flex flex-col items-start'>
              <label className='text-neutral-500'>Description

              </label>
              <textarea
                value={descUpdate}
                className='mt-3 w-full border border-gray-200 rounded-lg h-full h-32'
                onChange={(e) => setDescUpdate(e.target.value)}
              >

              </textarea>
              {/* <input
                className='mt-3 w-full border border-gray-200 rounded-lg h-full'
                placeholder='Enter Name'
                type='text'
                value={descUpdate}
                onChange={(e) => setDescUpdate(e.target.value)}
              /> */}

            </div>

          </div>

        </div>
      </div>

    </>
  );

  const buttonEditContent = (
    <>
      <div className='flex items-center gap-5 justify-end mt-3'>
        <button
          className='border border-red-500 mybzb_text_clr text-base px-5 py-2 rounded-lg'
          onClick={() => setIsModalEditOpen(false)}
        >
          Cancel
        </button>
        <button
          className=' mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg'
        //onClick={() => updateAPI()}
        >
          Save Changes
        </button>
      </div>
    </>
  )
  const mergeTitleAndDescription = (headings, data) => {
    return data.map(item => {
      const heading = headings.find(h => h.id === item.id);
      //const heading = headings.map(h => h.title);
      return heading ? { ...item, title: heading.title } : item;
    });
  };

  const mergedData = mergeTitleAndDescription(headings, generalList);

  const filterById = (id) => {
    return mergedData.filter(item => item.id === id);
  };

  const renderInfoCards = (ids) => {
    return ids.map(id => (
      filterById(id).map(item => (
        <InfoCard key={item.id} {...item} />
      ))
    ));
  };


  return (
    <>
      {
        loading ?
          <Loader />
          :
          <main className="flex flex-col justify-center self-stretch p-8 bg-gray-50 max-md:px-5">
            {generalList?.length > 0 ?
              <section className="flex flex-col pb-8 bg-white rounded-3xl max-md:max-w-full">
                <header className="items-start px-5 pt-3.5 pb-3 text-2xl font-semibold border-b border-gray-200 border-solid text-neutral-900 max-md:pr-5 max-md:max-w-full">
                  General
                </header>
                <div className="self-center mt-4 w-full px-5">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0 grid grid-cols-2">
                    {renderInfoCards([15, 16])}
                  </div>
                </div>

                <header className="items-start px-5 pt-3.5 pb-3 mt-8 text-2xl bg-white font-semibold border-t border-b border-gray-200 border-solid text-neutral-900 max-md:pr-5 max-md:max-w-full">
                  Contact Us Page Setting
                </header>

                <section className="flex flex-col px-5 pt-4 pb-8 max-md:max-w-full">
                  <div className="max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 grid grid-cols-3">
                      {renderInfoCards([1, 2, 3])}
                    </div>
                  </div>

                  <div className="max-md:max-w-full mt-6">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 grid grid-cols-3">
                      {renderInfoCards([4, 5, 6])}
                    </div>
                  </div>
                </section>

                <header className="items-start px-5 pt-3.5 pb-3 text-2xl font-semibold border-t border-b border-gray-200 border-solid text-neutral-900 max-md:pr-5 max-md:max-w-full">
                  CMS Pages Setting
                </header>

                <section className="flex flex-col px-5 pt-4 pb-8 max-md:max-w-full">
                  <div className="max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 grid grid-cols-3">
                      {renderInfoCards([7, 9, 10])}
                    </div>
                  </div>

                  <div className="mt-6 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 grid grid-cols-3">
                      {renderInfoCards([8, 11, 12])}
                    </div>
                  </div>
                </section>

                <header className="items-start px-5 pt-3.5 pb-3 text-2xl font-semibold border-t border-b border-gray-200 border-solid text-neutral-900 max-md:pr-5 max-md:max-w-full">
                  Payment Gateway Setting (Razor Pay)
                </header>

                <section className="self-center mt-4 w-full max-w-[1474px] max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0  grid grid-cols-2">
                    {renderInfoCards([13, 14])}
                  </div>
                </section>
              </section>
              :
              <></>
            }

          </main>
      }
      {isModalEditOpen && (
        <ModalBoxWrapper
          title="General Categories"
          content={modalEditContent}
          buttonContent={buttonEditContent}
          onClose={closeEditModal}
          modalWidth={`max-w-xl`}
        />
      )}

      <ToastContainer />
    </>
  );
}

export default Generalcontent;

// // api.js

// const BASE_URL = 'http://18.212.173.237/api/v1/admin';

// // Function to handle GET request
// export const get = async (endpoint) => {
//   try {
//     const response = await fetch(`${BASE_URL}/${endpoint}`);
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error('Error while fetching data:', error);
//     throw error;
//   }
// };

// // Function to handle POST request
// export const post = async (endpoint, body) => {
//   try {
//     const response = await fetch(`${BASE_URL}/${endpoint}`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(body),
//     });
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error('Error while posting data:', error);
//     throw error;
//   }
// };

// // Function to handle PUT request
// export const put = async (endpoint, body) => {
//   try {
//     const response = await fetch(`${BASE_URL}/${endpoint}`, {
//       method: 'PUT',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(body),
//     });
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error('Error while updating data:', error);
//     throw error;
//   }
// };

// // Function to handle DELETE request
// export const remove = async (endpoint) => {
//   try {
//     const response = await fetch(`${BASE_URL}/${endpoint}`, {
//       method: 'DELETE',
//     });
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error('Error while deleting data:', error);
//     throw error;
//   }
// };


// export const postFormData = async (endpoint, formData) => {
//     try {
//       const response = await fetch(`${BASE_URL}/${endpoint}`, {
//         method: 'POST',
//         body: formData,
//       });
//       const data = await response.json();
//       return data;
//     } catch (error) {
//       console.error('Error while posting data:', error);
//       throw error;
//     }
//   };



// const BASE_URL = 'http://18.212.173.237/api/v1/admin';
// export const BASE_URL =  'http://192.168.2.179/mybzb/public/api/v1/admin';
// export const VENDOR_BASE_URL =  'http://192.168.2.179/mybzb/public/api/v1';


//  export const BASE_URL =  'http://192.168.0.101/mybzb/public/api/v1/admin';
//  export const VENDOR_BASE_URL =  'http://192.168.0.101/mybzb/public/api/v1';

// export const BASE_URL_2 =  'http://192.168.1.12/mybzb/public';
// export const BASE_URL =  'http://192.168.1.12/mybzb/public/api/v1/admin';
// export const VENDOR_BASE_URL =  'http://192.168.1.12/mybzb/public/api/v1';

export const BASE_URL_2 =  'https://api.mybzb.com';
export const BASE_URL =  'https://api.mybzb.com/api/v1/admin';
export const VENDOR_BASE_URL =  'https://api.mybzb.com/api/v1';


const checkInternetConnection = () => {
  return navigator.onLine;
};

const handleResponse = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response.json();
};

export const fetchData = async (endpoint, token = null) => {

  // if (!checkInternetConnection()) {
  //     // throw new Error('No internet connection');
  //     //   alert('No internet connection');
  //       return <CustomAlert message="No internet connection" />;

  // }
  const url = `${BASE_URL}/${endpoint}`;
  const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
  try {
    const response = await fetch(url, { headers });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await handleResponse(response);
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }

  // return fetch(url, { headers })
  //     .then(handleResponse)
  //     .catch(error => console.error('Error:', error));
};

export const fetchSellerGetData = async (endpoint, token = null) => {

  // if (!checkInternetConnection()) {
  //     // throw new Error('No internet connection');
  //     //   alert('No internet connection');
  //       return <CustomAlert message="No internet connection" />;

  // }
  const url = `${VENDOR_BASE_URL}/${endpoint}`;
  const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
  try {
    const response = await fetch(url, { headers });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await handleResponse(response);
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }

  // return fetch(url, { headers })
  //     .then(handleResponse)
  //     .catch(error => console.error('Error:', error));
};

export const addData = async (endpoint, data, token = null) => {
  const url = `${BASE_URL}/${endpoint}`; 
  const headers = token ? { 'Authorization': `Bearer  ${token}` } : {};
  return fetch(url, {
    method: 'POST',
    headers: {      
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(handleResponse)
    .catch(
      error => console.error('Error:', error)
    );
};

export const deleteData = async (endpoint, id, token = null) => {
  const url = `${BASE_URL}/${endpoint}/${id}`;
  const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
  return fetch(url, {
    method: 'DELETE',
    headers,
  })
    .then(handleResponse)
    .catch(
      error => console.error('Error:', error)
    );
};

export const updateData = async (endpoint, id, newData, token = null) => {
  const url = `${BASE_URL}/${endpoint}/${id}`;
  const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
  return fetch(url, {
    method: 'PUT',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newData),
  })
    .then(handleResponse)
    .catch(
      error =>
        console.error('Error:', error)
    );
};



//register append fortm data post api 

export const fetchWithFormData = async (endpoint, formData, token = null, abortController = null) => {
  const url = `${BASE_URL}/${endpoint}`;

  try {
    // const headers = token ? { 'Authorization': `Bearer ${token}` } : {};

    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow"
    };

    if (abortController) {
      requestOptions.signal = abortController.signal;
    }

    const response = await fetch(url, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const fetchWithFormDataToken = async (endpoint, formdata, token = null, abortController = null) => {
  const url = `${BASE_URL}/${endpoint}`;

  try {
    const headers = { 'Authorization': `Bearer ${token}` }

    const requestOptions = {
      method: "POST",
      body: formdata,
      headers: headers,
      redirect: "follow"
    };

    if (abortController) {
      requestOptions.signal = abortController.signal;
    }

    const response = await fetch(url, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const fetchWithSellerFormDataToken = async (endpoint, formdata, token = null, abortController = null) => {
  const url = `${VENDOR_BASE_URL}/${endpoint}`;

  try {
    const headers = { 'Authorization': `Bearer ${token}` }

    const requestOptions = {
      method: "POST",
      body: formdata,
      headers: headers,
      redirect: "follow"
    };

    if (abortController) {
      requestOptions.signal = abortController.signal;
    }

    const response = await fetch(url, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchDataWithToken = async (endpoint, token = null,navigate) => {
 
  const controller = new AbortController();
  const { signal } = controller;

  const url = `${BASE_URL}/${endpoint}`;
  const headers = { 'Authorization': `Bearer ${token}` };



  try {
    const response = await fetch(url, { headers, signal });
    console.log("result category listing", response);
    if (!response.ok) {
       navigate('/');
      throw new Error('Network response was not ok');
      
    }
    // else if (response.status === 401) {
    //   navigate('/');
    // }
    return await handleResponse(response);
  } catch (error) {
    console.error('Error:', error);
    throw error;
  } finally {
    controller.abort();
  }
};

export const fetchWithFormDataWithoutBody = async (endpoint, token = null) => {
  const url = `${BASE_URL}/${endpoint}`;

  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: headers,
    };

    const response = await fetch(url, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchWithFormDataWithoutBodys = async (endpoint, token = null) => {
  const url = `${BASE_URL_2}/${endpoint}`;

  try {
    const headers = { 'Authorization': `Bearer ${token}` };

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: headers,
    };

    const response = await fetch(url, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const fetchWithFormDataTokens = async (endpoint, formdata, token = null, abortController = null) => {
  const url = `${BASE_URL_2}/${endpoint}`;

  try {
    const headers = { 'Authorization': `Bearer ${token}` }

    const requestOptions = {
      method: "POST",
      body: formdata,
      headers: headers,
      redirect: "follow"
    };

    if (abortController) {
      requestOptions.signal = abortController.signal;
    }

    const response = await fetch(url, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const fetchDatas = async (endpoint, token = null) => {

  // if (!checkInternetConnection()) {
  //     // throw new Error('No internet connection');
  //     //   alert('No internet connection');
  //       return <CustomAlert message="No internet connection" />;

  // }
  const url = `${BASE_URL_2}/${endpoint}`;
  const headers = token ? { 'Authorization': `Bearer ${token}` } : {};
  try {
    const response = await fetch(url, { headers });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await handleResponse(response);
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }

  // return fetch(url, { headers })
  //     .then(handleResponse)
  //     .catch(error => console.error('Error:', error));
};
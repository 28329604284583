import React, { useEffect, useState } from "react";
import Layout from "../../ReusableComp/Layout";
import * as Images from '../../../assets';
import { fetchWithFormData, post, postFormData } from "../../../apiGeneralFunction";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImgComp from '../../ReusableComp/ImgComp';
import { useNavigate } from "react-router-dom";
import { isValidEmail, isValidPassword } from "../../../Validation";

const ResetPassword = () => {

    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [newPassword, setNewPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);


    const handleSubmit = async () => {
        console.log("kLPANA");

        if (!newPassword && !password) {
            toast.error('Please enter your password', {
                position: 'top-center',
                autoClose: 2000,
            });
            // return;
        }

        else {
            const reset = localStorage.getItem("reset_emailid");
            // const resetEmail = JSON.parse(reset);
            console.log("resetEmail", reset, password, newPassword);

            const abortController = new AbortController();

            try {

                const formdata = new FormData();
                formdata.append("email", reset);
                formdata.append("password", password);
                formdata.append("password_confirmation", newPassword);



                const result = await fetchWithFormData(`reset_password`, formdata, null, abortController);
                if (result?.status === true) {
                    console.log("login api hit", result);

                    toast.success(result?.message, {
                        position: 'top-center',
                        autoClose: 2000,
                        onClose: () => {
                            navigate('/');
                        }
                    });
                }
                else {
                    toast.error(result?.message, {
                        position: 'top-center',
                        autoClose: 3000,
                        // onClose: () => {
                        //     navigate('/');
                        // }
                    });

                    setPassword('')
                    setNewPassword('')

                }
            } catch (error) {

            }
            finally {

                abortController.abort();
            }

        }
    };
    const data = [{ id: 1, title: "Privacy Policy", },
    { id: 2, title: "Terms & Conditions", },];

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (

        <main className="flex justify-center items-center w-full h-full">

            <div className=" w-full self-stretch">


                <ImgComp
                    src={Images.register}
                    srcSet={Images.register}
                    alt={'register'}
                    className={'w-full h-full'}

                />


            </div>
            <div className="flex flex-col w-full border ">
                <section
                    className="flex flex-col grow justify-between rounded-2xl items-center self-stretch px-20 py-12 w-full bg-white max-lg:px-5 max-lg:max-w-full"

                >
                    <div className="w-[80%] mx-auto">
                        <div className="">
                            <ImgComp
                                src={Images.logo}
                                srcSet={Images.logo}
                                alt={'Company logo'}
                                className={'mx-auto w-[50%] mt-10 '}

                            />
                        </div>
                        <div className=" text-3xl leading-10 text-center ">
                            <span className="">Reset </span> <span className="font-bold mybzb_text_clr">Password</span>
                        </div>
                        <p className="self-stretch my-5 text-sm text-center text-neutral-400 max-lg:max-w-full">
                            India's First My Bharatiya Zaveri Bazar Retail Culture Jewellery Store </p>
                        <div
                            className=" self-stretch "
                        >
                            <div>
                                <label htmlFor="emailOrMobile" className="self-stretch   text-base font-medium text-neutral-400 max-lg:mt-10 max-lg:mr-2.5 max-lg:max-w-full" >
                                    New Password
                                </label>
                                <div
                                >
                                    <div className="w-full relative mt-3" >
                                        <input
                                            type={showNewPassword ? 'text' : 'password'}
                                            name="email"
                                            value={newPassword}
                                            placeholder="Enter your New Password"

                                            required
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            className=" h-14  rounded-e-lg border rounded-lg border-solid border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] max-lg:max-w-full focus:border-gray-200 focus:ring-0 w-full"
                                        //className="mt-3 h-14  rounded-e-lg border rounded-lg border-solid border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] max-lg:max-w-full focus:border-gray-200 focus:ring-0 w-full"
                                        />
                                        <div
                                            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                        >
                                            <i className={`fa ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'} text-gray-500`}></i>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="mt-5">
                                <label htmlFor="emailOrMobile" className="self-stretch   text-base font-medium text-neutral-400 max-lg:mt-10 max-lg:mr-2.5 max-lg:max-w-full" >
                                    Confirm Password
                                </label>
                                <div className="w-full relative mt-3" >
                                    <input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        // type="text"
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        required
                                        placeholder="Enter your Confirm Password"
                                        className=" h-14  rounded-e-lg border rounded-lg border-solid border-[color:var(--grey-scale-outline-e-8-e-8-e-8,#E8E8E8)] max-lg:max-w-full focus:border-gray-200 focus:ring-0 w-full"
                                    />
                                    <div
                                        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                        onClick={togglePasswordVisibility}
                                    >
                                        <i className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'} text-gray-500`}></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={() => handleSubmit()}
                            className="text-center mt-5 w-full justify-center rounded-lg focus:outline-0 items-center px-16 py-4 text-lg font-medium bg-red-700 text-white rounded max-lg:px-5 max-lg:mx-1.5" >
                            Submit
                        </button>
                    </div>

                    <ul className="flex gap-5 justify-between self-stretch my-5 text-sm text-center text-neutral-400 max-lg:flex-wrap max-lg:max-w-full"> {data.map((item) => (<li key={item.id}>{item.title}</li>))}
                    </ul>
                    <div className="mybzb_text_clr my-5">
                        Powered by Headway Business Solutions LLP
                    </div>
                </section>
            </div>



            <ToastContainer />
        </main>


    );
}

export default ResetPassword
import React, { useEffect, useState } from 'react'
import Loader from '../Loader';


export const CustomerEditModal = ({ title, content, onClose, buttonContent, modalWidth }) => {

    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
        setIsOpen(false);
        onClose();
    };

    return (
        <div
            style={{ backgroundColor: 'rgb(0 0 0 / 40%)' }}
            // onClick={closeModal}
            className=" backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full"
        >

            <div
                // className=' bg-white rounded-2xl max-md:h-full w-full max-w-2xl zoom_in_pp_modal' 
                className={`bg-white rounded-2xl max-md:h-full w-full ${modalWidth} zoom_in_pp_modal`}
            // onClick={(e) => e.stopPropagation()}
            >
                <div className={` relative bg-white w-full ${modalWidth} max-h-full rounded-2xl`}>
                    <div className="relative bg-white rounded-2xl shadow-lg  p-6">

                        <div className='flex items-center justify-center pb-5'>
                            <div className=' text-2xl font-semibold w-full text-left'>
                                {title}
                            </div>
                            <div className='w-auto text-end '>
                                <button type="button" className="bg-white text-black hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-10 h-10 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={closeModal}
                                    data-modal-hide="popup-modal">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                        </div>

                        {content}
                        {buttonContent}


                    </div>

                </div>
            </div>
        </div>
    )
}

export const SellerModalBoxWrapper = ({ title, firstContent, secondContent, onClose, buttonContent, modalWidth }) => {

    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const closeModal = () => {
        setIsOpen(false);
        onClose();
    };

    useEffect(() => {
        setIsLoading(true);
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div
            style={{ backgroundColor: 'rgb(0 0 0 / 40%)' }}
            // onClick={closeModal}
            className=" backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full"
        >

            <div
                // className=' bg-white rounded-2xl max-md:h-full w-full max-w-2xl zoom_in_pp_modal' 
                className={`bg-white rounded-2xl max-md:h-full w-full ${modalWidth} zoom_in_pp_modal`}
            // onClick={(e) => e.stopPropagation()}
            >
                <div className={` relative bg-white w-full ${modalWidth} max-h-full rounded-2xl`}>
                    <div className="relative bg-white rounded-2xl shadow-lg  p-6">

                        <div className='flex items-center justify-center pb-5'>
                            <div className=' text-2xl font-semibold w-full text-left'>
                                {title}
                            </div>
                            <div className='w-auto text-end '>
                                <button type="button" className="bg-white text-black hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-10 h-10 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={closeModal}
                                    data-modal-hide="popup-modal">
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                        </div>
                        {/* {isLoading ? (
                            <Loader />
                        ) : ( */}
                        <>
                            <div className='w-full flex flex-col gap-5 '>
                                <div className='border p-4 rounded-lg'>
                                    {firstContent}
                                </div>
                                <div className='border p-4 rounded-lg'>
                                    {secondContent}
                                </div>
                            </div>

                            {buttonContent}
                        </>
                        {/* )
                        } */}

                    </div>
                </div>
            </div>
        </div>
    )
}

export const LogoutModalBox = ({ title, content, onClose, buttonContent, modalWidth }) => {

    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
        setIsOpen(false);
        onClose();
    };

    return (
        <div
            style={{ backgroundColor: 'rgb(0 0 0 / 80%)' }}
            onClick={closeModal}
            className=" backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full"
        >
            <div
                className={`bg-white rounded-2xl max-md:h-full w-full ${modalWidth} zoom_in_pp_modal`}
            // onClick={(e) => e.stopPropagation()}
            >
                <div className={`relative bg-white w-full ${modalWidth} max-h-full rounded-2xl`}>
                    <div className="relative bg-white rounded-2xl shadow-lg  p-6">
                        <div className='w-full flex flex-row divide-x rounded-lg max-lg:flex-col max-lg:divide-y max-lg:divide-x-0 '>
                            <div className="p-2 md:p-5 text-center max-sm:p-1.5  w-full">
                                <div
                                    className="flex flex-col max-lg:ml-0 max-lg:w-auto justify-end h-full  "
                                    style={{ maxHeight: '-webkit-fill-available !important' }}
                                >
                                    <div className='  h-full '
                                        style={{ maxHeight: '-webkit-fill-available !important' }}
                                    >
                                        {content}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {buttonContent}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const AlertModalBox = ({ title, content, onClose, alertButton, modalWidth, message, buttonContent }) => {

    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
        setIsOpen(false);
        onClose();
    };

    return (
        <div
            style={{ backgroundColor: 'rgb(0 0 0 / 40%)' }}
            className=" backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full"
        >
            <div
                className={`bg-white rounded-2xl max-md:h-full w-full ${modalWidth} zoom_in_pp_modal`}
                onClick={(e) => e.stopPropagation()}>
                <div className={`relative bg-white w-full ${modalWidth} max-h-full rounded-2xl`}>
                    <div className="relative rounded-2xl shadow-lg oultine_mybzb bg-rose-50 p-5">
                        <div className='flex items-center justify-center'>
                            <div className='flex items-center justify-center gap-3 text-xl font-semibold w-full text-center'>
                                <div className='rounded-full border border-red-700 w-fit px-2.5 '>
                                    <i className='fa fa-exclamation'></i>
                                </div>
                                Alert
                            </div>
                        </div>
                        <div>
                            <div className='text-neutral-600 my-3 text-center'>
                                {`Are you sure want to ${title} this product ?`}
                            </div>
                            <div>
                                {buttonContent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import ImgComp from '../../../ReusableComp/ImgComp'
import * as Images from '../../../../assets';
import { ExportIcon, EyeIcon, Search, SortIcon } from '../../../ReusableComp/Icons';
import { useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken, fetchWithSellerFormDataToken } from '../../../../apiGeneralFunction';
import { SearchBar } from '../../../ReusableComp/SearchBar';
import Pagination from '../../../ReusableComp/Pagination';
import TrimmedComponent from '../../../ReusableComp/TrimmedComponent';
import { FormattedDate } from '../../../ReusableComp/FormattedDate';
import { NumberConversion } from '../../../ReusableComp/NumberConversion';
import SortingDropdown from '../../../ReusableComp/SortingDropdown';
import TableNoDataFound from '../../../ReusableComp/TableNoDataFound';

const TransactionList = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedId, setSelectedId] = useState('');
    const [searchQueryData, setSearchQueryData] = useState('');

    const controller = new AbortController();

    useEffect(() => {
        fetchTransactionData(currentPage, selectedId, searchQueryData);;
        return () => {
            controller.abort();
        };
    }, [currentPage, selectedId, searchQueryData]);


    const fetchTransactionData = async (page = 1, selectedId = "", searchQuery = '') => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }

        const abortController = new AbortController();

        try {

            let sortByValue = "";
            if (selectedId === 1) {
                sortByValue = "asc";
            } else if (selectedId === 2) {
                sortByValue = "desc";
            }

            const formdata = new FormData();
            formdata.append("user_id", userId?.user_id);
            formdata.append("page", page);
            formdata.append("sort_by", sortByValue);
            formdata.append("search", searchQuery || '');
            formdata.append("flag", 0);
            const result = await fetchWithSellerFormDataToken(`seller/transaction-list`, formdata, storedToken, abortController);

            console.log("delete category response", result);
            if (result?.status === true) {
                setData(result?.data?.transaction_list);
                setTotalPages(result?.data?.pagination?.total_pages)

            }
            else {
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    }

    const handleSearch = (searchQuery) => {
        setSearchQueryData(searchQuery)
        fetchTransactionData(currentPage, selectedId, searchQuery);
    };


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        fetchTransactionData(currentPage, selectedId, searchQueryData);
    };

    const handleSelectChange = (id) => {
        setSelectedId(id);
        fetchTransactionData(currentPage, id, searchQueryData)
    };

    return (
        <>
            <div className='w-full flex justify-between items-center mt-5 bg-white p-5 rounded-t-xl'>
                <SearchBar onSearch={handleSearch} searchQueryData={searchQueryData} />
                <div className='flex items-center gap-5'>
                    <SortingDropdown
                        options={[
                            { id: 1, title: 'asc' },
                            { id: 2, title: 'desc' },
                        ]}
                        selectedId={selectedId}
                        onChange={(id) => handleSelectChange(id)}
                    />
                    <ExportIcon disabled={true} />
                </div>
            </div>
            <div className=' bg-white rounded-b-xl shadow-md'>
                <div className=" sm:rounded-lg mt-2 ">
                    <table className="w-full text-xs text-left rtl:text-right text-gray-500 ">
                        <thead className="text-sm text-gray-700  bg-gray-50  ">
                            <tr className='text-center'>

                                <th scope="col" className="px-6 py-3">
                                    No.
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Transaction ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Product Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Order ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Sellers Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Payment Method
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Download PDF
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-xs'>
                            {data?.length > 0 ?
                                data && data?.reverse().map((item, index) => {
                                    const fullName = [
                                        item?.customer_data?.firstname,
                                        item?.customer_data?.middlename,
                                        item?.customer_data?.lastname
                                    ].filter(Boolean)?.join(' ');
                                    return (
                                        <tr className="bg-white  border border-t-0 border-x-0  hover:bg-gray-50 text-center"
                                            key={index}
                                        >
                                            <td className="px-6 py-4">
                                                {(currentPage - 1) * 10 + index + 1}
                                            </td>
                                            <td className="px-6 py-4 ">
                                                #<TrimmedComponent trimdata={item?.transaction_id} />
                                            </td>
                                            <td className="px-6 py-4"
                                            >
                                                <TrimmedComponent trimdata={item?.product_name} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <NumberConversion priceConvert={item?.order_id} />
                                            </td>
                                            <td className="px-6 py-4"
                                            >
                                                <TrimmedComponent trimdata={item?.seller_name} />
                                            </td>
                                            <td className="px-6 py-4">
                                                {item?.order_type === "0" ? "COD" : "Online"}
                                            </td>
                                            <td className="px-6 py-4 text-green-500 font-bold">
                                                ₹ <NumberConversion priceConvert={item?.amount} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <FormattedDate date={item?.date} />
                                            </td>
                                            <td className="text-center cursor-pointer px-6 py-4">
                                                <a
                                                    href={item?.invoice?.invoice_link}
                                                    download
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <ImgComp
                                                        src={Images.pdf_icn}
                                                        className="w-8 mx-auto"
                                                        trimdata={item?.invoice?.invoice_link}
                                                    />
                                                </a>
                                            </td>
                                            {/* <td className='px-6 py-4 text-center '>
                                                <EyeIcon
                                                    // onClick={() => navigate('customer-detail', { state: { customerId: item?.user_id } })}
                                                    onClick={() => navigate('/dashboard/customers/customer-detail')}
                                                />
                                            </td> */}
                                        </tr>
                                    )
                                })
                                :
                                <TableNoDataFound colSpan={9} />
                            }
                        </tbody>
                    </table>
                </div>
                {data?.length > 0 &&
                    <div className='flex w-full justify-center items-center my-5'>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>
                }
            </div>
        </>
    )
}

export default TransactionList
import React from 'react'
import Navbar from '../../ReusableComp/Layout/NavbarComp/Navbar'
import OrderList from './OrderList'
import { Route, Routes } from 'react-router-dom'
import OrderDetailpage from './OrderDetailpage'



const Orderspage = () => {
  return (
    <>
      <Navbar name={'Orders'} />
      <div className='p-10 bg-gray-50'>
      <Routes>
          <Route path="/" element={<OrderList />} />
          <Route path="/order-detail" element={<OrderDetailpage />} />  
        </Routes>
      </div>
    </>
  )
}

export default Orderspage
import React, { useEffect, useState } from "react";
import * as Images from '../../../../assets';
import ImgComp from "../../../ReusableComp/ImgComp";
import { fetchDataWithToken, fetchWithFormDataToken } from "../../../../apiGeneralFunction";
import { useNavigate } from "react-router-dom";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DashboardPrice, InputWithoutBold } from "../../../ReusableComp/InputType";
import { EnableIcon } from "../../../ReusableComp/Icons";
import toastPromise from "../../../../toastService";
import TrimmedComponent from "../../../ReusableComp/TrimmedComponent";
import { FormattedDate } from "../../../ReusableComp/FormattedDate";
import { NumberConversion } from "../../../ReusableComp/NumberConversion";
import ProfileImageComponent from "../../../ReusableComp/ProfileImageComponent";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {

    const [hoveredCard, setHoveredCard] = useState(null);

    const handleMouseEnter = (id) => {
        setHoveredCard(id);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [revenueData, setRevenueData] = useState({});
    const [totalRevenue, setTotalRevenue] = useState({});
    const [goldRate, setGoldRate] = useState();
    const [silverRate, setSilverRate] = useState();
    const [platinumRate, setPlatinumRate] = useState();
    const [getPrice, setGetPrice] = useState();
    const [selectedId, setSelectedId] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionClick = (id) => {
        setSelectedId(id);
        setIsOpen(false);
    };

    const options = [
        { id: 1, title: 'Yearly' },
        { id: 2, title: 'Monthly' },
        { id: 3, title: 'Weekly' },
    ]

    const controller = new AbortController();

    useEffect(() => {
        fetchCustomerData();
        getGoldRate();
        return () => {
            controller.abort();
        };
    }, []);

    const fetchCustomerData = async () => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            // return;
        }

        const abortController = new AbortController();

        try {
            const formdata = new FormData();
            formdata.append("user_id", userId?.user_id);

            const result = await fetchWithFormDataToken(`dashboard-data`, formdata, storedToken, abortController);

            console.log("dashboard response", result);
            if (result?.status === true) {
                setData(result?.data);
                // setChartData(result?.data[0]?.total_revenue)
                // setTotalRevenue(result?.data[0]?.total_revenue);
            }
            else {
            }
        } catch (error) {

        }
        finally {
            abortController.abort();
        }
    }

    const saveGoldRate = async (rate, metal_id) => {

        console.log("rate,metal_id", rate, metal_id);

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            // return;
        }

        const abortController = new AbortController();

        try {
            const formdata = new FormData();
            formdata.append("metal_type", metal_id);
            formdata.append("gold_rate", rate);

            // const result = await fetchWithFormDataToken(`save_gold_rate`, formdata, storedToken, abortController);

            // console.log("delete category response", result);
            // if (result?.status === true) {
            //     getGoldRate()
            // }
            // else {
            // }

            const response = await toastPromise(
                fetchWithFormDataToken('save_gold_rate', formdata, storedToken, abortController, null),
                {
                    pending: "Updating Rate...",
                    success: (data) => data.message || "Rate Status is changed successful !",
                    error: (data) => data.message || "Failed to change the rate status",
                }
            );

            if (response.status === true) {
                getGoldRate()
            }
        } catch (error) {
        }
        finally {
            abortController.abort();
        }
    }

    const getGoldRate = async () => {
        try {
            const tokenValue = localStorage?.getItem('login api response token');
            const storedToken = JSON?.parse(tokenValue);
            const user_id = localStorage?.getItem('login data');
            const userId = JSON.parse(user_id);
            if (!storedToken) {
                navigate('/');
                return;
            }
            const result = await fetchDataWithToken('get_gold_rate', storedToken, navigate);
            console.log("getGoldRate", result);
            if (result?.status === true) {
                setGetPrice(result?.data)
            }
        } catch (error) {
        }
        finally {
        }
    };

    const chartData = {
        labels: Object.keys(totalRevenue),
        datasets: [
            {
                label: 'Revenue',
                data: Object.values(totalRevenue).map(item => item.revenue),
                backgroundColor: 'rgba(172, 53, 51,0.8)',
                borderColor: 'rgba(172, 53, 51)',
                borderWidth: 1,
                barThickness: 10,
                borderRadius: 10,
                marginRight: 5,
            },
            {
                label: 'Profit',
                data: Object.values(totalRevenue).map(item => item.profit),
                backgroundColor: 'rgba(80, 171, 46,0.9)',
                borderColor: 'rgb(80, 171, 46)',
                borderWidth: 1,
                barThickness: 10,
                borderRadius: 10,
            }
        ]
    };

    const chartOptions = {
        scales: {
            x: {
                type: 'category',
                barPercentage: 0.5,
                categoryPercentage: 0.8,
                maxBarThickness: 50,
            },
            y: {
                beginAtZero: true,
            }
        },
    };

    const Card = ({ title, amount, isHovered, onMouseEnter, onMouseLeave }) => {
        return (
            <>
                <div
                    className={` bg-white relative h-fit flex flex-col grow px-4 py-4 rounded-3xl shadow-orange-50 shadow-lg max-md:px-5 max-md:mt-6 max-md:w-full max-md:max-w-full `}
                >
                    <div>
                        <ImgComp
                            src={Images.half_circle}
                            className="absolute h-24 right-0 top-0 "
                        />
                    </div>
                    <div className="z-10 flex justify-start items-center ">
                        <div className="flex justify-between flex-col items-start gap-3">
                            <div
                                className="flex-auto my-auto text-base font-medium text-center text-neutral-500 max-md:max-w-full "
                            >
                                {title}
                            </div>
                            <div
                                className="text-2xl text-black max-md:max-w-full text-center font-bold"
                                style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 4 }}
                            >₹ {Number(amount).toLocaleString('en-IN')}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const CardCount = ({ title, amount, src }) => {
        return (
            <div

                className={`bg-white relative h-fit flex flex-col grow px-4 py-4 rounded-3xl shadow-orange-50 shadow-lg max-md:px-5 max-md:mt-6 max-md:w-full max-md:max-w-full `}
            >
                <div>

                    <ImgComp
                        src={Images.half_circle}
                        className="absolute h-24 right-0 top-0"
                    />
                </div>
                <div className="z-10 flex justify-start items-center ">
                    <div className="flex gap-5 justify-between max-md:flex-wrap">
                        <div className="flex justify-center items-center px-3.5 w-24 h-24 terms_bg_clr">

                            <ImgComp
                                src={src}
                                className="aspect-square"
                            />
                        </div>

                    </div>
                    <div className="flex justify-between flex-col items-center">
                        <div className="flex-auto my-auto text-sm font-medium text-center text-neutral-500 max-md:max-w-full ">
                            {title}
                        </div>
                        <div className="text-xl text-black max-md:max-w-full text-center font-bold"
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 4 }}
                        >{amount}</div>
                    </div>

                </div>

            </div>
        )
    }

    const OrderOverview = ({ order_pending, order_return, order_completed, ongoing_order }) => {
        console.log("OrderOverview-------", order_pending, order_return, order_completed, ongoing_order);
        return (
            <section className="flex flex-col w-full">
                <div className="flex flex-col grow self-stretch px-8 py-6 mx-auto w-full bg-white rounded-3xl max-md:px-5 max-md:mt-8">
                    <h2 className="text-xl font-semibold text-neutral-900">Order Overview</h2>
                    <div className="grid gap-5 grid-cols-4">
                        <div className="flex flex-col flex-1 justify-center items-start p-4 bg-rose-50 rounded-2xl max-md:pr-5">
                            <div className="flex gap-1.5">
                                <div className="flex justify-center items-center px-2 w-10 h-10 bg-red-700 rounded-full">
                                    <ImgComp
                                        src={Images.dashboard_shop}
                                        className="w-6 aspect-square"
                                    />

                                </div>
                                <div className="my-auto text-base font-bold text-neutral-900">{order_pending}</div>
                            </div>
                            <div className="mt-4 text-sm font-bold text-neutral-900">Order Pending</div>
                        </div>
                        <div className="flex flex-col flex-1 justify-center items-start p-4 bg-orange-100 rounded-2xl max-md:pr-5">
                            <div className="flex gap-2.5">
                                <div className="flex justify-center items-center px-2 w-10 h-10 bg-red-400 rounded-full">
                                    <ImgComp
                                        src={Images.dashboard_shop}
                                        className="w-6 aspect-square"
                                    />
                                </div>
                                <div className="my-auto text-lg font-semibold text-neutral-900">{order_return}</div>
                            </div>
                            <div className="mt-4 text-sm font-medium text-neutral-900">Order Return</div>
                        </div>
                        <div className="flex flex-col flex-1 justify-center p-4 bg-green-100 rounded-2xl">
                            <div className="flex gap-2.5">
                                <div className="flex justify-center items-center px-2 w-10 h-10 bg-green-500 rounded-full">
                                    <ImgComp
                                        src={Images.dashboard_shop}
                                        className="w-6 aspect-square"
                                    />
                                </div>
                                <div className="my-auto text-lg font-semibold text-neutral-900">{order_completed}</div>
                            </div>
                            <div className="mt-4 text-sm font-medium text-neutral-900">Order Completed</div>
                        </div>
                        <div className="flex flex-col flex-1 justify-center p-4 bg-purple-100 rounded-2xl">
                            <div className="flex gap-2.5">
                                <div className="flex justify-center items-center px-2 w-10 h-10 bg-purple-400 rounded-full">
                                    <ImgComp
                                        src={Images.dashboard_shop}
                                        className="w-6 aspect-square"
                                    />
                                </div>
                                <div className="my-auto text-lg font-semibold text-neutral-900">{ongoing_order}</div>
                            </div>
                            <div className="mt-4 text-sm font-medium text-neutral-900">On Going Order</div>
                        </div>
                        </div>
                </div>
            </section>
        );
    }

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls the page to the top
    }, []); 

    const handleOrderList = () => {
        navigate('/dashboard/sellers'); // This will redirect the user to the '/orders' page
    }

    const OrderList = ({ orders }) => {
        return (
            <section className="bg-white flex flex-col p-4 grow w-full bg-white rounded-3xl max-md:px-5 max-md:max-w-full">
                <div className="flex gap-5 justify-between items-center max-md:flex-wrap max-md:max-w-full">
                    <h2 className="my-auto text-xl font-semibold text-neutral-900">Sellers Listing</h2>
                    <div className="justify-center px-2 py-1 text-xs font-medium rounded-lg border border-gray-200 border-solid text-neutral-400 cursor-pointer" onClick={handleOrderList}>See all</div>
                </div>
                <div className="flex gap-3 justify-between mt-8 text-sm font-medium max-md:flex-wrap">
                    <table className="w-full text-xs text-left rtl:text-right text-gray-500 ">
                        <thead className="text-sm text-gray-600  ">
                            <tr>
                                <th scope="col" className="px-3 py-2">
                                    Sellers Name
                                </th>
                                <th scope="col" className="px-3 py-2">
                                    GST Number
                                </th>
                                <th scope="col" className="px-3 py-2">
                                    Register At
                                </th>
                                <th scope="col" className="px-3 py-2">
                                    Active Product
                                </th>
                                <th scope="col" className="px-3 py-2">
                                    Sales
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders?.length > 0 ?
                                orders && orders?.reverse().map((item, index) => {
                                    const createdDate = item?.create_date;
                                    const dateOnly = new Date(createdDate).toLocaleDateString();
                                    const renderStatus = (status) => {
                                        console.log("status", status);
                                        switch (status) {
                                            case "1":
                                                return <span className="text-amber-600 bg-amber-50  px-3 rounded-lg">Pending</span>;
                                            case "2":
                                                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Confirm</span>;
                                            case "3":
                                                return <span className="text-blue-600  bg-blue-50 px-3 rounded-lg">Shipping</span>;
                                            case "4":
                                                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Delivered</span>;
                                            case "5":
                                                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Completed</span>;
                                            case "6":
                                                return <span className="text-red-600 bg-red-50 px-3 rounded-lg">Cancelled</span>;
                                            case "7":
                                                return <span className="text-amber-600 bg-amber-50  px-3 rounded-lg">Returned</span>;
                                            default:
                                                return <span className="text-gray-500">Unknown</span>;
                                        }
                                    };

                                    return (
                                        <tr className="bg-white text-black  hover:bg-gray-50" key={index}>
                                            <td className="px-3 py-3">
                                                <TrimmedComponent trimdata={item?.seller_name} />
                                            </td>
                                            <td className="px-3 py-3">
                                                <TrimmedComponent trimdata={item?.GST} />
                                            </td>
                                            <td className="px-3 py-3">
                                                <FormattedDate date={item?.registered_date} />
                                            </td>
                                            <td className="px-3 py-3">
                                                {item?.product_added || "-"}
                                            </td>
                                            <td className="px-3 py-3">
                                                <TrimmedComponent trimdata={item?.sales} />
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <></>
                            }
                        </tbody>
                    </table>
                </div>
            </section>
        );
    }
    const handleOrderHistory = () => {
        navigate('/dashboard/orders'); // This will redirect the user to the '/orders' page
    }
    const ProductsListing = ({ products }) => {
        return (
            <section className="flex flex-col grow px-8 py-6 bg-white rounded-3xl max-md:px-5 max-md:mt-8 max-md:max-w-full">
                <div className="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full">
                    <h2 className="my-auto text-xl font-semibold text-neutral-900">Order History</h2>
                    <div className="justify-center px-4 py-3 text-sm font-medium rounded-lg border border-gray-200 border-solid text-neutral-400 cursor-pointer" onClick={handleOrderHistory}>See all</div>
                </div>
                <div className="flex gap-3 justify-between mt-5 font-medium text-neutral-900 max-md:flex-wrap">
                    <table className="w-full text-xs text-left rtl:text-right text-gray-500 ">
                        <thead className="text-xs text-gray-700   ">
                            <tr className='text-center'>
                                <th scope="col" className="px-6 py-3">
                                    Order ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Customer Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {products?.length > 0 ?
                                products && products?.slice(0, 6).map((item, index) => {

                                    const renderStatus = (status) => {
                                        console.log("status", status);
                                        switch (status) {
                                            case "1":
                                                return <span className="text-amber-600 bg-amber-50  px-3 rounded-lg">Pending</span>;
                                            case "2":
                                                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Confirm</span>;
                                            case "3":
                                                return <span className="text-blue-600  bg-blue-50 px-3 rounded-lg">Shipping</span>;
                                            case "4":
                                                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Delivered</span>;
                                            case "5":
                                                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Completed</span>;
                                            case "6":
                                                return <span className="text-red-600 bg-red-50 px-3 rounded-lg">Cancelled</span>;
                                            case "7":
                                                return <span className="text-amber-600 bg-amber-50  px-3 rounded-lg">Returned</span>;
                                            default:
                                                return <span className="text-gray-500">Unknown</span>;
                                        }
                                    };

                                    return (
                                        <tr className="bg-white hover:bg-gray-50 text-center" key={index}>
                                            <td className="px-6 py-4">
                                                <TrimmedComponent trimdata={`#${item?.order_id}`} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <TrimmedComponent trimdata={item?.customer_name} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <TrimmedComponent trimdata={item?.customer_name} />
                                            </td>
                                            {/* <td className="px-6 py-4 capitalize ">
                                                <div className="bg-green-50 text-green-700 w-fit mx-auto px-2.5 py-1.5">
                                                    {item?.status}
                                                </div>
                                            </td> */}
                                            <td className="px-3 py-3 font-bold">
                                                {renderStatus(item?.status)}
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <></>
                            }
                        </tbody>
                    </table>
                </div>
            </section>
        );
    }

    useEffect(() => {
        if (getPrice?.gold_amount) {
            setGoldRate(getPrice?.gold_amount);
        }
        if (getPrice?.silver_amount) {
            setSilverRate(getPrice?.silver_amount);
        }
        if (getPrice?.platinum_amount) {
            setPlatinumRate(getPrice?.platinum_amount);
        }
    }, [getPrice]);

    console.log("price changing", data);

    const handleTransactionHistory = () => {
        navigate('/dashboard/transactions'); // This will redirect the user to the '/orders' page
    }

    const TransactionHistory = ({ transactions }) => {
        return (
            <section className="flex flex-col grow px-8 py-6 bg-white rounded-3xl max-md:px-5 max-md:mt-8 max-md:max-w-full">
                <div className="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full">
                    <h2 className="my-auto text-xl font-semibold text-neutral-900">Transaction History</h2>
                    <div className="justify-center px-4 py-3 text-sm font-medium rounded-lg border border-gray-200 border-solid text-neutral-400 cursor-pointer" onClick={handleTransactionHistory}>See all</div>
                </div>
                <div className="flex gap-3 justify-between mt-5 font-medium text-neutral-900 max-md:flex-wrap">
                    <table className="w-full text-xs text-left rtl:text-right text-gray-500 ">
                        {/* <thead className="text-xs text-gray-700   ">
                            <tr className='text-center'>
                                <th scope="col" className="px-6 py-3">
                                    Order ID
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Customer Name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                            </tr>
                        </thead> */}
                        <tbody className="">
                            {transactions?.length > 0 ?
                                transactions && transactions?.slice(0, 6).map((item, index) => {

                                    const renderStatus = (status) => {
                                        console.log("status", status);
                                        switch (status) {
                                            case "1":
                                                return <span className="text-amber-600 bg-amber-50  px-3 rounded-lg">Pending</span>;
                                            case "2":
                                                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Confirm</span>;
                                            case "3":
                                                return <span className="text-blue-600  bg-blue-50 px-3 rounded-lg">Shipping</span>;
                                            case "4":
                                                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Delivered</span>;
                                            case "5":
                                                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Completed</span>;
                                            case "6":
                                                return <span className="text-red-600 bg-red-50 px-3 rounded-lg">Cancelled</span>;
                                            case "7":
                                                return <span className="text-amber-600 bg-amber-50  px-3 rounded-lg">Returned</span>;
                                            default:
                                                return <span className="text-gray-500">Unknown</span>;
                                        }
                                    };

                                    return (
                                        <tr className="bg-white hover:bg-gray-50 text-center" key={index}>
                                            <td
                                                className="px-6 py-4 flex items-center gap-3 justify-start "
                                                onClick={() => navigate('customer-detail', { state: { customerDetailId: item?.id } })}
                                            >
                                                <ProfileImageComponent
                                                    profilePicUrl={item?.profile_pic}
                                                    className={`w-12 h-12 rounded-full border`}
                                                />
                                                <TrimmedComponent trimdata={item?.customer_name} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <FormattedDate date={item?.date} />
                                            </td>
                                            <td className="px-6 py-4 capitalize ">
                                                <div className="bg-green-50 text-green-700 w-fit mx-auto px-2.5 py-1.5">
                                                    <NumberConversion priceConvert={item?.amount} />
                                                    {item?.status}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <></>
                            }
                        </tbody>
                    </table>
                </div>
            </section>
        );
    }

    return (
        <>
            <div>
                <div className="flex items-end justify-between w-full gap-10">
                    <DashboardPrice
                        labelName={`Gold Rate (1 gm) (24 Karat)`}
                        value={goldRate}
                        // onChange={(e) => setGoldRate(e.target.value)}
                        onChange={(e) => {
                            const cleanValue = e.target.value.replace(/,/g, '');
                            setGoldRate(cleanValue);
                        }}
                        name={goldRate}
                        placeholder={`Enter Gold Rate`}
                        // onSaveClick={() => saveGoldRate(goldRate, 5)}
                        onSaveClick={() => {
                            const cleanGoldRate = goldRate.replace(/,/g, '');
                            saveGoldRate(cleanGoldRate, 5);
                        }}
                    />
                    <DashboardPrice
                        labelName={`Silver Rate (1 gm)`}
                        value={silverRate}
                        // onChange={(e) => setSilverRate(e.target.value)}
                        onChange={(e) => {
                            const cleanValue = e.target.value.replace(/,/g, '');
                            setSilverRate(cleanValue);
                        }}
                        name={silverRate}
                        placeholder={`Enter Silver Rate`}
                        // onSaveClick={() => saveGoldRate(silverRate, 19)}
                        onSaveClick={() => {
                            // Remove commas before passing the value to the API
                            const cleanGoldRate = silverRate.replace(/,/g, '');
                            saveGoldRate(cleanGoldRate, 19);
                        }}
                    />
                    <DashboardPrice
                        labelName={`Platinum Rate (1 gm)`}
                        value={platinumRate}
                        // onChange={(e) => setPlatinumRate(e.target.value)}
                        onChange={(e) => {
                            const cleanValue = e.target.value.replace(/,/g, '');
                            setPlatinumRate(cleanValue);
                        }}
                        name={platinumRate}
                        placeholder={`Enter Platinum Rate`}
                        // onSaveClick={() => saveGoldRate(platinumRate, 18)}
                        onSaveClick={() => {
                            // Remove commas before passing the value to the API
                            const cleanGoldRate = platinumRate.replace(/,/g, '');
                            saveGoldRate(cleanGoldRate, 18);
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-col self-stretch  rounded-xl max-md:px-5">
                {/* {data && data?.length > 0 && data?.map((item, index) => {
                    return ( */}
                <>
                    <div className="flex items-center justify-between mt-8" >
                        <div className="flex-1 gap-5 w-full max-md:flex-wrap max-md:max-w-full grid grid-cols-4 w-full">
                            <div className="flex flex-col gap-5">
                                <Card
                                    id="total_transaction"
                                    isHovered={hoveredCard === 'total_transaction'}
                                    onMouseEnter={() => handleMouseEnter('total_transaction')}
                                    onMouseLeave={handleMouseLeave}
                                    title={`Total Transaction amount`}
                                    amount={data?.totalTansactionAmount}
                                />
                                <Card
                                    id="today_transaction"
                                    isHovered={hoveredCard === 'today_transaction'}
                                    onMouseEnter={() => handleMouseEnter('today_transaction')}
                                    onMouseLeave={handleMouseLeave}
                                    title={`Today’s Transaction amount`} amount={data?.todaysTransactionAmount} />
                            </div>
                            <div className="flex flex-col gap-5">
                                <CardCount title={`Total Orders`} amount={data?.totalOrder} src={Images.icn_total_order} />
                                <CardCount title={`Today’s Orders`} amount={data?.todaysOrder} src={Images.icn_total_order} />
                            </div>

                            <div className="flex flex-col gap-5">
                                <CardCount title={`Total Sellers Count`} amount={data?.totalSellerCount} src={Images.icn_total_product} />
                                <CardCount title={`Today’s Sellers Count`} amount={data?.todaysSeller} src={Images.icn_total_product} />
                            </div>

                            <div className="flex flex-col gap-5">
                                <CardCount title={`Total Customers`} amount={data?.totalCustomerCount} src={Images.icn_total_customers} />
                                <CardCount title={`Today’s New Customers`} amount={data?.todaysCustomer} src={Images.icn_total_customers} />
                            </div>
                        </div>
                    </div>

                    <div className="flex  items-center grid grid-cols-2 gap-10 bg-gray-50 my-8">

                        <OrderList orders={data?.seller_list} />
                        <div
                            className="flex flex-col items-center justify-center w-full bg-white h-full p-4 rounded-3xl"
                        >
                            <div className="flex gap-5 justify-between items-center w-full max-md:flex-wrap max-md:max-w-full">
                                        <div className="my-auto text-xl font-semibold text-neutral-900">Total Revenue</div>
                                        <div className=" relative">
                                            <div
                                                onClick={() => setIsOpen(!isOpen)}
                                                className='font-bold  cursor-pointer flex items-start gap-3 bg-white px-2.5 rounded-lg py-1  border border-neutral-300'
                                            >
                                                <div>
                                                    <div className='text-xs text-neutral-400 '>Yearly</div>
                                                </div>
                                                <i className="fa fa-angle-down text-xs  text-neutral-400"></i>
                                            </div>
                                            {isOpen && (
                                                <div
                                                    id="chart_menu"
                                                    className="absolute left-0  py-2 top-full mt-2 w-full bg-white z-10 rounded-lg border border-gray-200">
                                                    {options && options?.length > 0 && options?.map((option) => (
                                                        <div
                                                            key={option?.id}
                                                            onClick={() => handleOptionClick(option.id)}
                                                            className={`px-4 py-1 font-bold cursor-pointer text-xs ${selectedId === option.id ? 'bg-red-800 text-white' : 'text-neutral-400 hover:bg-red-800 hover:text-white'}`}
                                                        >
                                                            {option.title}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                            <Bar data={chartData} options={chartOptions} />
                        </div>
                    </div>
                    <div className="gap-5 w-full">
                    <OrderOverview
                            order_pending={data?.overview?.orderPending}
                            order_return={data?.overview?.orderReturn}
                            order_completed={data?.overview?.orderCompleted}
                            ongoing_order={data?.overview?.orderOngoing}
                        />
                    </div>
                    <div className="flex gap-5 mt-8 w-full max-md:flex-wrap max-md:max-w-full">
                        <ProductsListing products={data?.order_history} />
                        <TransactionHistory transactions={data?.transaction_history} />
                    </div>
                </>
                {/* )
                })} */}
            </div>
        </>
    );
}

export default Dashboard;
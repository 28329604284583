import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { fetchData, fetchDataWithToken, fetchWithFormDataToken, fetchWithSellerFormDataToken } from '../../../../apiGeneralFunction';
import ImgComp from '../../../ReusableComp/ImgComp';
import * as Images from '../../../../assets';
import ModalBoxWrapper from '../../../ReusableComp/ModalBoxWrapper';
import { CustomerEditModal } from '../../../ReusableComp/ModalBox';
import { SearchBar } from '../../../ReusableComp/SearchBar';
import TrimmedComponent from '../../../ReusableComp/TrimmedComponent';
import { NumberConversion } from '../../../ReusableComp/NumberConversion';



const SubscriptionList = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [subscriptionData,setSubscriptionData] = useState([]);

    const controller = new AbortController();

    useEffect(() => {
        fetchSellerData();

        return () => {

            controller.abort();
        };
    }, []);

    const fetchSellerData = async (page = 1) => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }

        const abortController = new AbortController();

        try {

            const result = await fetchDataWithToken('subscription-list', storedToken, navigate);
            if (result?.status === true) {
                setSubscriptionData(result?.data)
            }
            else {
            }
        } catch (error) {
        }
        finally {
            abortController.abort();
        }
    }

    return (
        <>

            <div className=" p-10 bg-white rounded-3xl  max-lg:p-5 ">
                <div
                    className="flex  grid grid-cols-4 max-lg:grid-cols-2 gap-5 items-start "
                >
                    {subscriptionData.map((item, index) => {
                        return (
                            <div
                                className="flex flex-col shrink p-6 bg-white rounded-2xl shadow-xl basis-0 max-md:px-5 h-full"
                                key={index}>
                                <div className="flex flex-col ">
                                    <div
                                        className="text-xl font-semibold tracking-tight leading-snug text-neutral-900"
                                    >
                                        <TrimmedComponent trimdata={item?.plan_name} />
                                    </div>

                                    <div className="flex justify-center items-end self-start pt-1 mt-2 text-center">
                                        <div className="text-3xl font-bold tracking-tighter text-red-700 mb-2">
                                            <NumberConversion priceConvert={item?.price} />
                                        </div>
                                        <div className="gap-2.5 pb-1 text-base text-neutral-400">
                                            &nbsp; / Year
                                        </div>
                                    </div>
                                    <div className="mt-2 text-sm leading-5 text-neutral-400">
                                        {/* {item.desc} */}
                                        <TrimmedComponent trimdata={item?.plan_description} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        </>
    )
}

export default SubscriptionList
import React, { useState } from 'react'
import Navbar from '../../ReusableComp/Layout/NavbarComp/Navbar'
import Categories from './Categories';
import Sizes from './Sizes';
import Colors from './Colors';
import Metals from './Metals';
import Purity from './Purity';
import Occasions from './Occasions';
import Promotion from './Promotion';
import General from './General';
import Banners from './Banners';



const Settingspage = () => {

  const [activeTab, setActiveTab] = useState(0);

  // const tabs = ['Categories', 'Sizes', 'Colors','Metals','Purity','Occasions'];
  const tabs = ['Banners','Categories', 'Colors', 'Metals', 'Purity', 'Occasions','Promotion'];

  const content = [
    <Banners/>,
    <Categories />,
    // <Sizes/>,
    <Colors />,
    <Metals />,
    <Purity />,
    <Occasions />,
    // <General/>,
    <Promotion/>,
  ];

  const handleTabClick = (index) => {
    setActiveTab(index);
  };


  return (
    <>
      <Navbar name={'Settings'} />

      <div className='p-10 bg-gray-50 h-full'>
        <div className="flex">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`cursor-pointer px-10 py-2 border text-sm bg-white font-bold  ${activeTab === index ? 'mybzb_bg_clr border border-red-800 text-white' : 'text-neutral-500'
                } ${tab === 'Banners' ? 'rounded-tl-lg' : ''}  ${tab === 'Promotion' ? 'rounded-tr-lg' : ''}`}
              onClick={() => handleTabClick(index)}
            >
              {tab}
            </div>
          ))}
        </div>
        <div className="h-full">{content[activeTab]}</div>
      </div>
    </>
  )
}

export default Settingspage
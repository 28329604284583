import React, { useEffect, useState } from 'react';
import ImgComp from '../../ImgComp';
// import Home from './Home';
// import About from './About';
// import Services from './Services';
import * as Images from '../../../../assets';
import Homepage from '../../../Pages/Homepage';
import Customerspage from '../../../Pages/Customerspage';
import Orderspage from '../../../Pages/Orderspage';
import Productspage from '../../../Pages/Productspage';
import Reportspage from '../../../Pages/Reportspage';
import Reviewspage from '../../../Pages/Reviewspage';
import Subscriptionpage from '../../../Pages/Subscriptionpage';
import Transactionspage from '../../../Pages/Transactionspage';
import Settingspage from '../../../Pages/Settingspage';
import Generalpage from '../../../Pages/Generalpage';
import Sellerspage from '../../../Pages/Storespage';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { fetchWithFormDataWithoutBody } from '../../../../apiGeneralFunction';
import { LogoutModalBox } from '../../ModalBox';

const SidebarLayout = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [selectedComponent, setSelectedComponent] = useState('Dashboard');
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const path = location.pathname.split('/')[2];
        const componentName = path ? path.charAt(0).toUpperCase() + path.slice(1) : 'Dashboard';
        setSelectedComponent(componentName);
    }, [location]);


    const changeComponent = (component) => {
        if (component === 'Sign Out') {
            setIsModalOpen(true);
        } else {
            setSelectedComponent(component);
            navigate(`/dashboard/${component.toLowerCase().replace(/\s+/g, '')}`);
        }
    };

    const renderComponent = () => {
        switch (selectedComponent) {
            case 'Dashboard':
                return <Homepage />;
            case 'Sellers':
                return <Sellerspage />;
            case 'Customers':
                return <Customerspage />;
            case 'Orders':
                return <Orderspage />;
            case 'Products':
                return <Productspage />;
            case 'Reports':
                return <Reportspage />;
            case 'Reviews':
                return <Reviewspage />;
            case 'Subscription':
                return <Subscriptionpage />;
            case 'Transactions':
                return <Transactionspage />;
            case 'General':
                return <Generalpage />;
            case 'Settings':
                return <Settingspage />;
            default:
                return <Homepage />;
        }
    };

    const Sidebar = ({ changeComponent }) => {

        const [isHovered, setIsHovered] = useState(false);
        const [hoveredItem, setHoveredItem] = useState(null);
        const handleMouseEnter = (itemName) => {
            setHoveredItem(itemName);
        };

        const handleMouseLeave = () => {
            setHoveredItem(null);
        };

        const sidebarItems = [
            { name: 'Dashboard', normalImg: Images.dashboard_normal_icon, hoverImg: Images.dashboard_hover_icon },
            { name: 'Sellers', normalImg: Images.seller_normal_icon, hoverImg: Images.seller_hover_icon },
            { name: 'Customers', normalImg: Images.customer_normal_icon, hoverImg: Images.customer_hover_icon },
            { name: 'Orders', normalImg: Images.order_normal_icon, hoverImg: Images.order_hover_icon },
            { name: 'Products', normalImg: Images.product_normal_icon, hoverImg: Images.product_hover_icon },
            { name: 'Reports', normalImg: Images.report_normal_icon, hoverImg: Images.report_hover_icon },
            { name: 'Reviews', normalImg: Images.review_normal_icon, hoverImg: Images.review_hover_icon },
            { name: 'Subscription', normalImg: Images.subscription_normal_icon, hoverImg: Images.subscription_hover_icon },
            { name: 'Transactions', normalImg: Images.transaction_normal_icon, hoverImg: Images.transaction_hover_icon },
            { name: 'General', normalImg: Images.general_normal_icon, hoverImg: Images.general_hover_icon },
            { name: 'Settings', normalImg: Images.settings_normal_icon, hoverImg: Images.settings_hover_icon },
            { name: 'CMS', normalImg: Images.cms_normal_icon, hoverImg: Images.cms_hover_icon },
            { name: 'Sign Out', normalImg: Images.signout_normal_icon, hoverImg: Images.signout_hover_icon },
            { name: 'Profile', normalImg: Images.dashboard_normal_icon, hoverImg: Images.dashboard_hover_icon },
        ];

        const handleLogout = () => {
            const fetchAPI = async () => {
                const tokenValue = localStorage.getItem('login api response token') || '';
                const storedToken = JSON.parse(tokenValue);
                if (!storedToken) {
                    navigate('/');
                    return;
                }
                else {
                    try {
                        const result = await fetchWithFormDataWithoutBody('logout', storedToken);
                        console.log('API logout response:', result);
                        if (result?.status === true) {
                            // toast.success(result?.message, {
                            //     position: 'top-center',
                            //     autoClose: 2000,
                            //     onClose: () => {
                            //         navigate('/');

                            //     }
                            // })
                            localStorage.clear();
                            navigate('/');

                        }
                        else {
                            // toast.error(result?.message, {
                            //     position: 'top-center',
                            //     autoClose: 3000,
                            // });
                        }
                    } catch (error) {
                    }
                }
            };
            fetchAPI();
        }

        const buttonEditContent = (
            <>
                <div className='flex items-center gap-5 justify-end mt-3'>
                    <button
                        className='border border-red-800 mybzb_text_clr text-base px-5 py-2 rounded-lg w-full focus:border-red-800 focus:outline-0 focus:ring-0'
                        onClick={() => setIsModalOpen(false)}
                    >
                        No
                    </button>
                    <button
                        className=' mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg w-full focus:border-red-800  focus:outline-0 focus:ring-0'
                        onClick={() => handleLogout()}
                    >
                        Yes
                    </button>
                </div>
            </>
        )

        const ModalContent = () => {
            return (
                <>
                    <ImgComp
                        src={Images.logout_vendor_img}
                    />
                    <div className='pt-3'>
                        Are you sure, <br />
                        You want to Log out ?
                    </div>
                </>
            )
        }

        return (
            <div className="max-lg:hidden px-10 py-14 w-80 shadow-lg bg-white">
                <div className=" flex  items-center">
                    <ImgComp
                        src={Images.logo}
                        srcSet={Images.logo}
                        alt={'logo'}
                        className={'w-[30%] mybzb_bg_pinkclr rounded-lg'}

                    />
                    <div className='font-bold text-3xl ms-3'>MyBzB</div>
                </div>
                <div>
                    {sidebarItems.map((item, index) => {
                        return (
                            <>
                                {item.name === 'Profile' ? (
                                    <></>
                                ) : (
                                    <div key={index}>

                                        <button
                                            key={item.name}
                                            onMouseEnter={() => handleMouseEnter(item.name)}
                                            onMouseLeave={handleMouseLeave}
                                            className={`flex items-center block py-3 my-5 px-5 w-full rounded-xl focus:outline-0 focus:ring-0  ${hoveredItem === item.name ? 'bg-red-700 selective_item' : ' text-neutral-500'
                                                } ${selectedComponent === item.name ? 'bg-red-700 selective_item' : 'text-neutral-500 '
                                                } `}
                                            onClick={() => changeComponent(item.name)}
                                        >
                                            <img
                                                src={hoveredItem === item.name || selectedComponent === item.name ? item.hoverImg : item.normalImg}
                                                alt={item.name}
                                                className="w-5 me-3"
                                            />
                                            {item?.name !== 'Profile' && item?.name}
                                        </button>
                                    </div>
                                )
                                }
                            </>
                        )
                    })}
                </div>
                {isModalOpen &&
                    <div>
                        <LogoutModalBox
                            content={<ModalContent />}
                            buttonContent={buttonEditContent}
                            modalWidth={`max-w-sm`}
                            onClose={() => setIsModalOpen(false)}
                        />
                    </div>
                }
            </div>
        );
    };

    return (
        <div className="flex w-full ">
            <Sidebar changeComponent={changeComponent} />
            <div className="flex flex-col flex-grow w-full  ">
                {/* {renderComponent()} */}
                <Outlet />
            </div>
        </div>
    );
};

export default SidebarLayout;

import React from 'react'
import Navbar from '../../ReusableComp/Layout/NavbarComp/Navbar'
import Dashboard from './Dashboard'



const Homepage = () => {
  return (
    <>
      <Navbar name={'Dashboard'} />
      <div className='p-10 bg-gray-50'>
        <Dashboard />
      </div>
    </>
  )
}

export default Homepage
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithFormDataToken } from "../../../../apiGeneralFunction";

const Notification = () => {

    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const [userDataEmail, setUserDataEmail] = useState([]);
    const [userDataPush, setUserDataPush] = useState([]);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        handleSubmit()
    }, [])


    const handleSubmit = async () => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/login');
            return;
        }

        const formdata = new FormData();
        formdata.append("seller_id", userId?.user_id);

        try {
            const result = await fetchWithFormDataToken(`api/v1/seller/get_notification_data`, formdata, storedToken);
            console.log("notification data", result);
            if (result?.status === true) {
                setUserDataEmail(result?.data?.email);
                setUserDataPush(result?.data?.push);
            }
            else {

                setUserDataEmail();
                setUserDataPush();
            }
        } catch (error) {

        }


    };


    const NotificationToggle = ({ label, description, key, notification, title }) => {

        // console.log("notification details", notification);

        const [isActive, setIsActive] = useState(notification?.is_active === "0" ? 'false' : 'true');

        const toggleSwitch = async (notification, title) => {
            console.log("notification tggle sata", notification, title);
            setIsActive(prevState => !prevState);
            const updatedNotification = { ...notification, is_active: notification.is_active === "1" ? "0" : "1" };


            await handleUpdateNotification(updatedNotification);

        };

        const handleUpdateNotification = async (updatedNotification) => {

            const tokenValue = localStorage?.getItem('login api response token');
            const storedToken = JSON?.parse(tokenValue);

            const user_id = localStorage?.getItem('login data');
            const userId = JSON.parse(user_id);
            console.log("categories login id", userId);

            if (!storedToken) {
                navigate('/login');
                return;
            }

            const formdata = new FormData();
            formdata.append("seller_id", userId?.user_id);
            formdata.append("id", updatedNotification.id);
            formdata.append("type", 'email');
            formdata.append("is_active", updatedNotification?.is_active);

            try {
                const result = await fetchWithFormDataToken(`api/v1/seller/set_notification_data`, formdata, storedToken);
                console.log("notification data", result);
                if (result?.status === true) {
                    toast.success(result?.message, {
                        position: 'top-center',
                        autoClose: 3000,

                    });

                    handleSubmit()
                } else {
                    setUserDataEmail();
                    setUserDataPush();
                }
            } catch (error) {

            }
        };

        console.log("active values", isActive, notification?.is_active);
        return (
            <div className="flex gap-3 mt-6 max-md:flex-wrap max-md:max-w-full items-center" index={key}>
                <div
                    className={`w-14 flex items-center  rounded-full p-1 cursor-pointer ${isActive === "true" ? 'bg-red-800' : 'bg-neutral-300'
                        }`}
                    onClick={() => toggleSwitch(notification, title)}
                >
                    <div
                        className={`w-6 h-6 rounded-full bg-white shadow-md transform transition-transform ${isActive === "true" ? 'translate-x-full' : ''
                            }`}
                    />
                </div>
                <div className="flex flex-col justify-center">
                    <div className="text-sm font-bold text-zinc-800">{label}</div>
                    <div className=" text-sm text-neutral-400">{description}</div>
                </div>
            </div>
        );
    }

    const NotificationSection = ({ title, description, notifications }) => {

        console.log("notifications email", notifications);
        return (
            <section className="mt-4 max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                    <div className="flex flex-col w-[45%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col max-md:mt-8">
                            <h3 className="text-base font-bold text-zinc-800">{title}</h3>
                            <p className="mt-2 text-sm text-neutral-400">{description}</p>
                        </div>
                    </div> <div className="flex flex-col ml-5 w-[55%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col grow max-md:mt-8 max-md:max-w-full">
                            {notifications?.map((notification, index) => (
                                <NotificationToggle
                                    key={index}
                                    label={notification?.name} description={notification?.description}
                                    isActive={notification?.is_active}
                                    notification={notification}
                                    title={title}

                                />
                            )
                            )
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    const emailNotifications = [
        {
            label: "Events Notifications",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            //isActive: true,
        },
        {
            label: "Seminar Notifications",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            //isActive: false,
        },
        {
            label: "Promotional Notifications",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            //isActive: true,
        },
        {
            label: "Subscription Notifications",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            //isActive: true,
        },
        {
            label: "News and Updates Notifications",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            //isActive: false,
        },
    ];
    const pushNotifications = [
        {
            label: "Events Notifications",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            //isActive: true,
        },
        {
            label: "Seminar Notifications",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            //isActive: false,
        },
        {
            label: "Promotional Notifications",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            //isActive: true,
        },
        {
            label: "Subscription Notifications",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            //isActive: true,
        },
        {
            label: "News and Updates Notifications",
            description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
            //isActive: false,
        },
    ];

    console.log("SettingsNotificationContent userData", userDataEmail);
    return (
        <main className="flex flex-col justify-center max-w-full bg-white rounded-b-3xl">

            <section className="flex flex-col p-6 w-full  max-md:px-5 max-md:max-w-full">
                <div className="self-start text-xl font-bold text-zinc-800 max-md:max-w-full">
                    Notification Settings
                </div>
                <p className="self-start  text-base text-neutral-400 max-md:max-w-full">
                    Select the kinds of notifications you get about your activities and recommendations.
                </p>
                <hr className="shrink-0 mt-6 h-px bg-gray-200 max-md:max-w-full" />

                <NotificationSection
                    title="Email Notifications"
                    description="Get emails to find out what's going on when you're not online. You can turn these off"
                    notifications={userDataEmail}
                />
                <hr className="shrink-0 mt-4 h-px bg-gray-200 max-md:max-w-full" />
                <NotificationSection title="Push notifications"
                    description="Get push notifications in-website to find out what's going on when you're online."
                    notifications={userDataPush}
                />
            </section>
        </main>
    )
}

export default Notification
import React, { useState } from 'react';
import Select from 'react-select';


export const GreyInputWithAsterisk = ({ value, onChange, labelName, type, placeholder, hintName, name, readOnly }) => {
    return (
        <>
            <div className='flex flex-col items-start mt-2.5 text-sm'>
                <label htmlFor={name} className='text-neutral-500'>
                    {labelName}
                    <span className='mybzb_text_clr'> *</span>
                    <span className='text-neutral-500 font-medium'>{hintName}</span>
                </label>
                <input
                    className='read-only-input mt-3 w-full border border-gray-200 rounded-lg text-sm px-3 py-2 text-neutral-900 focus:border-gray-200 focus:outline-0 focus:ring-0'
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    type={type}
                    name={name}
                    readOnly={readOnly}
                />

            </div>
        </>
    )
}

export const ReadOnlyInputWithoutTopMargin = ({ value, onChange, labelName, type, placeholder, hintName, name, symbol }) => {
    return (
        <>
            <div className='flex flex-col items-start text-sm'>
                <label htmlFor={name} className='text-neutral-500'>
                    {labelName}
                    <span className='mybzb_text_clr'> {symbol}</span>
                    <span className='text-neutral-500 font-medium'>{hintName}</span>
                </label>
                <input
                    className='mt-3 w-full bg-gray-50 border border-gray-200 rounded-lg text-sm px-3 py-2 text-neutral-900 focus:border-gray-200 focus:outline-0 focus:ring-0'
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    type={type}
                    name={name}
                    readOnly
                />

            </div>
        </>
    )
}

export const MakingChargesAsterisk = ({ labelName, placeholder, value, onChange, type, name, hintName }) => {

    const [inputValue, setInputValue] = useState('');

    const handleChange = (e) => {
        const inputValue = e.target.value;
        if (validateInput(inputValue)) {
            setInputValue(inputValue);
            onChange(e);
        }
    };

    const validateInput = (value) => {
        const regex = /^[0-9,.]*$/;
        const normalizedValue = value.replace(/[,.]/g, '');
        const decimalParts = normalizedValue.split('.');
        if (decimalParts.length > 1 && decimalParts[1].length > 2) {
            return false;
        }
        return regex.test(value);
    };

    const formatInput = (value) => {
        const numericValue = parseFloat(value.replace(/[^\d.-]/g, ''));
        return numericValue.toLocaleString('en-US', { maximumFractionDigits: 2 });
    };

    return (

        <div className='flex flex-col items-start mt-3'>
            <label htmlFor={name} className='text-black font-bold'>
                {labelName}
                <span className='mybzb_text_clr'> *</span>
                <span className='text-neutral-500 font-medium'>{hintName}</span>
            </label>
            <input
                className='mt-3 w-full border border-gray-200 rounded-lg text-sm p-3 h-11 '
                placeholder={placeholder}
                value={formatInput(inputValue)}
                onChange={handleChange}
                type={type}
                name={name}
            />
        </div>
    );
};


export const InputWithoutAsterisk = ({ value, onChange, labelName, type, placeholder, hintName, name }) => {
    return (
        <>
            <div className='flex flex-col items-start mt-3'>
                <label className='text-black font-bold' htmlFor={name}>
                    {labelName}
                    <span className='text-neutral-500 font-medium'>{hintName}</span>
                </label>
                <input
                    className='mt-3 w-full border border-gray-200 rounded-lg'
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    type={type}
                    name={name}
                />

            </div>
        </>
    )
}

export const InputWithoutLabel = ({ value, onChange, type, placeholder, name }) => {
    return (
        <>
            <input
                className='mt-3 w-full border border-gray-200 rounded-lg text-sm p-3 text-black'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                type={type}
                name={name}
            />
        </>
    )
}

export const TextareaWithoutLabel = ({ value, onChange, placeholder, name }) => {
    return (
        <>
            <textarea
                className='mt-2.5 w-full border border-gray-200 rounded-lg h-28 text-sm p-3'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                name={name}
            >

            </textarea>
        </>
    )
}

export const DropdownWithAsterisk = ({ value, onChange, labelName, id, placeholder, options }) => {
    return (
        <>
            <div className='flex flex-col items-start mt-3'>
                <label className='text-black font-bold'>
                    {labelName}
                    <span className='mybzb_text_clr'> *</span>
                </label>
                <select
                    id={id}
                    value={value}
                    onChange={onChange}
                    className="block w-full p-2 mt-3 text-base h-12 text-neutral-500 border border-gray-300 rounded-lg bg-white"
                >
                    <option value="" disabled className="bg-white">{placeholder}</option>
                    {options?.length > 0 ?
                        options?.map((item) => (
                            <option
                                key={item?.id}
                                value={item?.id}
                                className="bg-white"
                            >
                                {item?.name}
                            </option>
                        ))
                        :
                        <></>
                    }
                </select>

            </div>
        </>
    )
}

export const CustomDropdown = ({ value, onChange, options, placeholder, labelName, asterisk }) => {
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#FDEDF1' : state.isFocused ? 'lightgray' : 'white',
            color: 'black',
            padding: 10,
        }),
        control: (provided, state) => ({
            ...provided,
            marginTop: '3px',
            borderColor: state.isFocused ? 'rgb(185 28 28 / var(--tw-border-opacity))' : 'hsl(0, 0%, 80%)',
            boxShadow: state.isFocused ? 'rgb(185 28 28 / var(--tw-border-opacity))' : '0 0 0 0 hsl(0, 0%, 80%)',
            ':hover': {
                borderColor: 'hsl(0, 0%, 80%)',
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#737373',
            padding: '6px 10px'
        }),
    };

    const formattedOptions = options.map(item => ({
        value: item.id,
        label: item.name,
    }));

    return (
        <div className='flex flex-col items-start text-sm '>
            <label className='text-neutral-500 '>
                {labelName}
                <span className='mybzb_text_clr'> {asterisk}</span>
            </label>
            <Select
                value={options.find(option => option.value === value)}
                onChange={(selectedOption) => onChange({
                    id: selectedOption.value,
                    label: selectedOption.label,
                })}
                options={options}
                styles={customStyles}
                placeholder={placeholder}
                className='w-full mt-3 '
            />
        </div>
    );
};

export const InputWithoutBold = ({ value, onChange, labelName, type, placeholder, hintName, name }) => {
    return (
        <>
            <div className='flex flex-col items-start mt-3 text-sm'>
                <label htmlFor={name} className='text-neutral-400 '>
                    {labelName}
                    <span className='text-neutral-500 font-medium'>{hintName}</span>
                </label>
                <input
                    className='mt-3 w-full border border-gray-200 rounded-lg text-sm'
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    type={type}
                    name={name}
                />

            </div>
        </>
    )
}

export const DashboardPrice = ({ value,name,onChange,labelName,onSaveClick,placeholder }) => {
    return (
        <>
            <div className='flex flex-col items-start mt-3 text-sm gap-3 w-full'>
                <label htmlFor={name} className='text-neutral-800 text-base font-bold'>
                    {labelName}
                </label>
                <div className="flex border rounded-md items-center w-full">
                    <input
                        className=' border-0 text-sm px-3 py-1.5 w-full rounded-md'
                        placeholder={placeholder}
                        value={value}
                        onChange={onChange}
                        type={`text`}
                        name={name}
                    />
                    {/* <ImgComp
                                src={Images.enable_icon}
                                srcSet={Images.enable_icon}
                                className='w-8'
                            /> */}
                    <div className="bg-red-700 text-white px-4 border border-red-700 py-1.5 font-bold cursor-pointer" onClick={onSaveClick}>
                        {/* <i className="fa fa-check"></i> */}
                        Save
                    </div>
                </div>
            </div>
        </>
    )
}



export const CustomProfileDropdown = ({ value, onChange, options, placeholder, labelName, asterisk }) => {
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#FDEDF1' : state.isFocused ? 'lightgray' : 'white',
            // backgroundColor: state.isSelected ? '#AC3533' : state.isFocused ? 'lightgray' : 'white',
            color: 'black',
            padding: 10,
        }),
        control: (provided, state) => ({
            ...provided,
            marginTop: '3px',
            // borderColor: state.isFocused ? '#AC3533' : '',
            // boxShadow: state.isFocused ? '0 0 0 0px #AC3533' : '0 0 0 0 #ccc',
            // ':hover': { 
            //     borderColor: '#AC3533', 
            // },  style={{ border: '1px solid hsl(0, 0%, 80%)' }}
            borderColor: state.isFocused ? 'hsl(0, 0%, 80%)' : 'hsl(0, 0%, 80%)',
            boxShadow: state.isFocused ? '0 0 0 0px hsl(0, 0%, 80%)' : '0 0 0 0 hsl(0, 0%, 80%)',
            ':hover': { 
                borderColor: 'hsl(0, 0%, 80%)', 
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
            padding: '6px 10px'
        }),
    };

    const formattedOptions = options.map(item => ({
        value: item.id,
        label: item.name,
    }));

    return (
        <div className='flex flex-col items-start gap-2'>
            <label className=' font-medium text-sm text-neutral-400 '>
                {labelName}
                <span className='mybzb_text_clr'> {asterisk}</span>
            </label>
            <Select
                value={options.find(option => option.value === value)}
                onChange={(selectedOption) => onChange(selectedOption ? selectedOption.value : '')}
                options={options}
                styles={customStyles}
                placeholder={placeholder}
                className='w-full text-sm'
            />
        </div>
    );
};
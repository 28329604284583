import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL, fetchWithFormDataToken, fetchWithSellerFormDataToken } from '../../../../../apiGeneralFunction';
import ImgComp from '../../../../ReusableComp/ImgComp';
import { FormattedDate } from '../../../../ReusableComp/FormattedDate';
import * as Images from '../../../../../assets';
import Pagination from '../../../../ReusableComp/Pagination';
import ProfileImageComponent from '../../../../ReusableComp/ProfileImageComponent';
import { SellerModalBoxWrapper } from '../../../../ReusableComp/ModalBox';
import { GreyInputWithAsterisk } from '../../../../ReusableComp/InputType';
import TableNoDataFound from '../../../../ReusableComp/TableNoDataFound';
import Loader from '../../../../ReusableComp/Loader';
import { SearchBar } from '../../../../ReusableComp/SearchBar';
import SortingDropdown from '../../../../ReusableComp/SortingDropdown';
import { EyeIcon } from '../../../../ReusableComp/Icons';


const NewSellerEnquiry = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [sellerDetailData, setSellerDetailData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQueryData, setSearchQueryData] = useState('');
    const [selectedId, setSelectedId] = useState('');
    const [showedit, setShowedit] = useState(true);

    const controller = new AbortController();

    const fetchSellerData = async (page = 1, sortTitle = null, searchQuery = '') => {
        setLoading(true);
        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);
        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }
        const abortController = new AbortController();
        try {

            let sortByValue = "desc";
            if (selectedId === 1) {
                sortByValue = "asc";
            } else if (selectedId === 2) {
                sortByValue = "desc";
            }


            const formdata = new FormData();
            formdata.append("page", page);
            formdata.append("sort_by", sortByValue);
            // formdata.append("sort_by", sortTitle?.title || "" || (selectedId === 1 ? "asc" : "desc"));
            formdata.append("search", searchQuery || '');
            const result = await fetchWithFormDataToken(`seller_enquiry_list`, formdata, storedToken, abortController);
            if (result?.status === true) {
                // setData(result?.data?.seller_enquiry_list);
                // setTotalPages(result?.data?.pagination?.total_pages)
                setData(result?.data?.seller_enquiry_list.reverse());
                setTotalPages(result?.data?.pagination?.total_pages);
            }
            else {
                setData(result?.data);
            }
        } catch (error) {
        }
        finally {
            setLoading(false);
            abortController.abort();
        }
    }

    const handlePageChange = (newPage) => {

        console.log("newPage seller enquiry", newPage);
        fetchSellerData(newPage);
        setCurrentPage(newPage)
    }

    // const handleApprovalChange = (approvalStatus, item) => {
    //     console.log("handleApprovalChange", approvalStatus, item);
    //     let isSellerApproved;
    //     switch (item.is_approved) {
    //         case '0':
    //             isSellerApproved = "0"; // pending
    //             break;
    //         case '1':
    //             isSellerApproved = "1"; // Approve
    //             break;
    //         case '2':
    //             isSellerApproved = "2";  // Reject
    //             break;
    //         default:
    //             isSellerApproved = 0; // Pending
    //     }

    //     const tokenValue = localStorage?.getItem('login api response token');
    //     const storedToken = JSON?.parse(tokenValue);


    //     const myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    //     myHeaders.append("Authorization", `Bearer ${storedToken}`);

    //     const raw = JSON.stringify({
    //         "user_id": item?.user_id,
    //         "is_approved": approvalStatus
    //     });

    //     const requestOptions = {
    //         method: "PATCH",
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: "follow"
    //     };

    //     fetch(`${BASE_URL}/approv_reject_seller`, requestOptions)
    //         .then((response) => response.text())
    //         .then((result) => {
    //             console.log("approv_reject_seller", result)
    //             fetchSellerData();
    //         }
    //         )
    //         .catch((error) => console.error(error));
    // }


    const handleApprovalChange = async (approvalStatus, item) => {
        console.log("handleApprovalChange", approvalStatus, item);
    

        let isSellerApproved;
        switch (item.is_approved) {
            case '0':
                isSellerApproved = "0"; // pending
                break;
            case '1':
                isSellerApproved = "1"; // Approve
                break;
            case '2':
                isSellerApproved = "2";  // Reject
                break;
            default:
                isSellerApproved = 0; // Pending
        }

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);
    
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${storedToken}`);
    
        const raw = JSON.stringify({
            user_id: item?.user_id,
            is_approved: approvalStatus,
        });
    
        const requestOptions = {
            method: "PATCH",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
    
        try {
            const response = await fetch(`${BASE_URL}/approv_reject_seller`, requestOptions);
            const result = await response.json();
    
            if (result.status) {
                console.log("approv_reject_seller", result);
    
                // Update only the specific item in the state
                setData((prevData) =>
                    prevData.map((row) =>
                        row.user_id === item.user_id
                            ? { ...row, is_approved: approvalStatus }
                            : row
                    )
                );
            } else {
                console.error("Error updating approval status:", result.message);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    


    const modalViewContent = (
        <>
            <div className='flex flex-col h-full '>
                <div className='text-left mb-5 font-bold text-xl'>
                    Sellers Information
                </div>
                <div className='flex justify-between gap-5 h-full '>
                    <div className=' items-start w-full grid grid-cols-3 gap-5'>
                        <GreyInputWithAsterisk
                            labelName={`Sellers Name`}
                            value={sellerDetailData?.sellersname}
                            onChange={() => setShowedit(false)}
                            type="text"
                            name="name"
                            readOnly={true}
                        />
                        <GreyInputWithAsterisk
                            labelName={`Email`}
                            value={sellerDetailData?.email}
                            type="email"
                            name="name"
                        />
                        <div
                            className="  text-sm text-left mt-2.5"
                        >
                            <label htmlFor="phoneNumber" className=" text-neutral-500" >
                                Phone Number
                            </label>
                            <div
                                className={`mt-2.5 focus:border-red-800 focus:ring-0 flex gap-1.5 items-start py-1 px-2 text-base  rounded-lg border border-gray-200 border-solid text-zinc-800
                                    }`}>
                                <div className="flex  my-auto w-full">
                                    <div className="flex gap-1 items-center text-sm ">
                                        <div className="">+91</div>
                                        <i className="fa fa-angle-down"></i>
                                    </div>
                                    <input
                                        type="text"
                                        name="phoneNumber"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        value={sellerDetailData?.phone}
                                        readOnly={true}
                                onChange={() => setShowedit(false)}
                                        className={`w-full read-only-input focus:border-red-800 focus:ring-0 justify-center px-4 py-1 focus:border-0 text-sm rounded-lg border-0 text-neutral-900 `}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w-full flex flex-col text-sm h-full'>
                            <div className='mb-3 text-neutral-500 text-left'>Sellers logo</div>
                            <div
                                className="h-full flex flex-col grow px-6 py-5 justify-around rounded-lg border border-red-500 border-dashed terms_bg_clr max-md:px-5 mybzb_bg_pinkclr "
                            >
                                <label htmlFor="file-input" className="h-full">
                                    <div className="text-center mx-auto">
                                        <ImgComp
                                            src={sellerDetailData?.profile_pic ? sellerDetailData?.profile_pic : Images.logo}
                                            srcSet={sellerDetailData?.profile_pic ? sellerDetailData?.profile_pic : Images.logo}
                                            // className="self-center w-10 border-white  mx-auto"
                                            className={`h-24 w-24 mx-auto`}
                                        />

                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className='w-full col-span-2 justify-between h-full'>
                            <GreyInputWithAsterisk
                                labelName={`Owner Name`}
                                value={sellerDetailData?.ownername}
                                type="text"
                                name="name"
                            />
                            <div className='grid grid-cols-2 gap-5'>
                                <GreyInputWithAsterisk
                                    labelName={`GSTIN number`}
                                    value={sellerDetailData?.gst_no}
                                    type="text"
                                    name="name"
                                />
                                <GreyInputWithAsterisk
                                    labelName={`PAN`}
                                    value={sellerDetailData?.pan_no}
                                    type="text"
                                    name="name"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    const secondViewContent = (
        <>
            <div className='flex flex-col h-full '>
                <div className='text-left mb-5 font-bold text-xl'>
                    Address Information
                </div>
                <div className='flex justify-between gap-5 h-full '>

                    <div className=' items-start w-full grid grid-cols-3 gap-5'>
                        <GreyInputWithAsterisk
                            labelName={`Home/Flat/Floor no.`}
                            value={sellerDetailData?.flatno_building}
                            type="text"
                            name="name"
                        />
                        <GreyInputWithAsterisk
                            labelName={`Apartment/Road/Area`}
                            value={sellerDetailData?.street_area}
                            type="text"
                            name="name"
                        />
                        <GreyInputWithAsterisk
                            labelName={`Pincode`}
                            value={sellerDetailData?.pincode}
                            type="text"
                            name="name"
                        />
                        <GreyInputWithAsterisk
                            labelName={`Country`}
                            value={sellerDetailData?.country}
                            type="text"
                            name="country"
                        />

                        <GreyInputWithAsterisk
                            labelName={`State`}
                            value={sellerDetailData?.state}
                            type="text"
                            name="state"
                        />
                        <GreyInputWithAsterisk
                            labelName={`City`}
                            value={sellerDetailData?.city}
                            type="text"
                            name="city"
                        />
                    </div>
                </div>
            </div>
        </>
    );

    const buttonViewContent = (
        <>
            <div className='flex items-center gap-5 justify-end mt-3'>
                <button
                    className='border border-red-700 mybzb_text_clr text-base px-5 py-2 rounded-lg  focus:border-red-800 focus:ring-0 focus:outline-0'
                    onClick={() => setIsViewModalOpen(false)}
                >
                    Cancel
                </button>
                <button
                    onClick={() => setIsViewModalOpen(false)}
                    className='border border-red-700 mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg focus:border-red-800 focus:ring-0 focus:outline-0'
                >
                    Back to Enquiry List
                </button>
            </div>
        </>
    );

    const closeViewModal = () => {
        setIsViewModalOpen(false);
    };

    const fetchSellerDetail = async (sellerId) => {

        console.log("fetchSellerDetail----", sellerId);

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);

        if (!storedToken) {
            navigate('/');
            // return;
        }
        const abortController = new AbortController();
        try {
            const formdata = new FormData();
            formdata.append("id", sellerId);
            const result = await fetchWithSellerFormDataToken(`seller/get-profile`, formdata, storedToken, abortController);
            if (result?.status === true) {
                setSellerDetailData(result?.data);
            }
            else {
            }
        } catch (error) {
        }
        finally {
            abortController.abort();
        }
    }

    const openViewModal = (item) => {
        console.log("openViewModal", item);
        setIsViewModalOpen(true);
        fetchSellerDetail(item?.user_id)
    };

    useEffect(() => {
        fetchSellerData(currentPage, selectedId, searchQueryData);
        return () => {
            controller.abort();
        };
    }, [currentPage, selectedId, searchQueryData]);

    const handleSearch = (searchQuery) => {
        setSearchQueryData(searchQuery)
        fetchSellerData(currentPage, selectedId, searchQuery);
    };

    const handleSelectChange = (id) => {
        setSelectedId(id);
        fetchSellerData(currentPage, id, searchQueryData)
    };

    return (
        <>


            <div className='w-full flex justify-between items-center text-xs p-5'>
                <SearchBar onSearch={handleSearch} searchQueryData={searchQueryData} />
                <div className='flex items-center gap-5'>
                    {/* <div className='flex items-center gap-3 border rounded-lg px-3 py-1 text-gray-500 font-bold '>
                        <ImgComp
                            src={Images.sort_icon}
                            srcSet={Images.sort_icon}
                            className='w-5'
                        />
                        Sort
                    </div> */}
                    <SortingDropdown
                        options={[
                            { id: 1, title: 'asc' },
                            { id: 2, title: 'desc' },
                            { id: 3, title: 'none' },
                        ]}
                        selectedId={selectedId}
                        onChange={(id) => handleSelectChange(id)}
                    />
                </div>
            </div>


            <div className=" h-full">
                <table className="w-full  text-xs  text-left rtl:text-right text-gray-500 ">
                    <thead className="text-sm text-gray-700  bg-gray-50  ">
                        <tr className='text-center'>

                            <th scope="col" className="px-6 py-3">
                                No.
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Sellers Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Owner Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Phone Number
                            </th>
                            <th scope="col" className="px-6 py-3">
                                City
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Enquiry Date
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Enquiry Status
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Verified Date
                            </th>

                            <th scope="col" className="px-6 py-3">
                                Approved/Decline
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ? (
                                <>
                                    <tr>
                                        <td colSpan={9}>
                                            <Loader />
                                        </td>
                                    </tr>

                                </>
                            ) : (
                                data?.length > 0 ?
                                    (
                                        data?.map((item, index) => {
                                            const enquiryDate = item?.enquiry_date;
                                            const datePart = enquiryDate.split(' ')[0];
                                            const [year, month, day] = datePart.split('-');
                                            const enquiryDateOnly = `${day}-${month}-${year}`;
                                            const formattedDate = enquiryDateOnly.replace(/\//g, '-');
                                            console.log(formattedDate);
                                            const verifyDate = item?.verified_date;
                                            const verifyDateOnly = new Date(verifyDate).toLocaleDateString();
                                            return (
                                                <tr
                                                    className="bg-white  cursor-pointer  border border-t-0 border-x-0  hover:bg-gray-50 text-center" key={index}>
                                                    <td className="px-6 py-4">
                                                        {(currentPage - 1) * 10 + index + 1}
                                                    </td>
                                                    <td className="px-6 py-4 flex items-center gap-3 justify-start ">
                                                        <ProfileImageComponent
                                                            profilePicUrl={item?.profile_pic}
                                                            className={`w-12 h-12 rounded-full border`}
                                                        />
                                                        {item?.seller_name || "-"}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {item?.owner_name?.trim() ? item.owner_name : "-"}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        {item?.phone || "-"}
                                                    </td>
                                                    <td className="px-6 py-4 capitalize">
                                                        {item?.city || "-"}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <FormattedDate date={item?.enquiry_date || "-"} />
                                                    </td>
                                                    <td className="px-6 py-4 text-red-800">
                                                        {item?.enquiry_status || "-"}
                                                    </td>
                                                    <td className="px-6 py-4">
                                                        <FormattedDate date={item?.verified_date || "-"} />
                                                    </td>
                                                    <td className='text-center '>
                                                        <div className='flex justify-center items-center gap-3 '>
                                                            {item?.is_approved === "2" &&
                                                                <div
                                                                    onClick={() => handleApprovalChange("1", item)}
                                                                    className='cursor-pointer '
                                                                >
                                                                    <i className='fa fa-check-square text-xl text-green-700'></i>
                                                                </div>
                                                            }
                                                            {item?.is_approved === "1" &&
                                                                <div className='cursor-pointer '
                                                                    onClick={() => handleApprovalChange("2", item)}
                                                                >
                                                                    <i className='fa fa-times-rectangle text-xl mybzb_text_clr'></i>
                                                                </div>
                                                            }
                                                            {item?.is_approved === "0" &&
                                                                <>
                                                                    <div
                                                                        onClick={() => handleApprovalChange("1", item)}
                                                                        className='cursor-pointer '
                                                                    >
                                                                        <i className='fa fa-check-square text-xl text-green-700'></i>
                                                                    </div>
                                                                    <div className='cursor-pointer '
                                                                        onClick={() => handleApprovalChange("2", item)}
                                                                    >
                                                                        <i className='fa fa-times-rectangle text-xl mybzb_text_clr'></i>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className='text-center cursor-pointer'
                                                        onClick={() => openViewModal(item)}
                                                    >
                                                        <div className='flex justify-center items-center gap-5 '>
                                                            <EyeIcon />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    )
                                    :
                                    (
                                        <TableNoDataFound colSpan={9} />
                                    )
                            )
                        }
                    </tbody>
                </table>

            </div>
            {data?.length > 0 &&
                <div className='flex w-full justify-center items-center my-5'>
                    {/* {pageLinks} */}
                    {/* {Array.from({ length: totalPages }, (_, index) => (
                    <div key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className='px-3 py-1 border rounded-full text-black text-center hover:bg-red-800 hover:text-white my-5 me-5 cursor-pointer'
                    >
                        {index + 1}
                    </div>
                ))} */}
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                </div>
            }
            {isViewModalOpen && (
                <SellerModalBoxWrapper
                    title="View Sellers Details"
                    firstContent={modalViewContent}
                    secondContent={secondViewContent}
                    buttonContent={buttonViewContent}
                    onClose={closeViewModal}
                    modalWidth={`max-w-5xl`}
                />
            )}
        </>
    )
}

export default NewSellerEnquiry
import React, { useEffect, useState } from "react";
import ImgComp from "../../../ReusableComp/ImgComp";
import * as Images from '../../../../assets';
import { fetchWithFormDataToken, fetchWithSellerFormDataToken } from "../../../../apiGeneralFunction";
import { useLocation, useNavigate } from "react-router-dom";
import { TwoBreadcrumb } from "../../../ReusableComp/Breadcrumb";
import { BgButton, OutlineButton } from "../../../ReusableComp/Buttons";
import { AlertModalBox } from "../../../ReusableComp/ModalBox";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductDetailpage = ({ }) => {

  const navigate = useNavigate();

  const [selectedSizeIndex, setSelectedSizeIndex] = useState(0);
  const [previewData, setPreviewData] = useState([]);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [reason, setReason] = useState('');

  const location = useLocation();
  const productId = location?.state?.productDetailId;

  useEffect(() => {
    handlePreview();
  }, [])

  const handlePreview = async () => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('login data');
    const userId = JSON.parse(user_id);

    if (!storedToken) {
      navigate('/');
      return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("product_id", productId);
      const result = await fetchWithSellerFormDataToken('seller/product_details_review', formdata, storedToken, null, abortController);

      console.log("product_details_review response", result);
      if (result?.status === true) {
        setPreviewData(result?.data);

      }
      else {

      }
    } catch (error) {

    }
    finally {

      abortController.abort();
    }
  };

  const handleSizeClick = (index) => {
    setSelectedSizeIndex(index);
  };

  const ImageGallery = ({ previewData }) => {

    const [expandedImgSrc, setExpandedImgSrc] = useState('');
    const [expandedImgAlt, setExpandedImgAlt] = useState('');
    const [isExpanded, setIsExpanded] = useState(true);

    useEffect(() => {
      if (previewData?.product_images?.length > 0) {
        setExpandedImgSrc(previewData.product_images[0].image_path);
        setExpandedImgAlt(previewData.product_images[0].type);
      }
    }, [previewData]);

    const handleImageClick = (img) => {
      setExpandedImgSrc(img?.image_path);
      setExpandedImgAlt(img?.type);
      setIsExpanded(true);
    };

    const closeExpandedImage = () => {
      setIsExpanded(false);
    };

    return (
      <div className="py-5">

        <div className="flex flex-row gap-5">
          <div className="row flex flex-col gap-5"
          >
            {previewData?.product_images?.map((img, index) => (
              <div className=" flex w-20 " key={index}
              >
                <img
                  src={img?.image_path}
                  alt={img?.type}
                  className="rounded-lg  border cursor-pointer "
                  onClick={() => handleImageClick(img)}
                  onMouseOver={(e) => (e.currentTarget.style.opacity = 1)}
                  onMouseOut={(e) => (e.currentTarget.style.opacity = 0.8)}
                />
              </div>
            ))}
          </div>

          {isExpanded && (
            <div className="container"
              style={{ position: 'relative', display: 'block' }}
            >
              <img id="expandedImg" src={expandedImgSrc} alt={expandedImgAlt} style={{ width: '100%' }}
                className="rounded-lg border"
              />
            </div>
          )}
        </div>
      </div>
    );
  };


  const InfoItem = ({ label, value }) => {

    const renderGenderContent = (value) => {
      switch (value) {
        case "1":
          return 'Male';
        case "2":
          return 'Female';
        case "3":
          return 'Kids';
        case "4":
          return 'Unisex';
        default:
          return null;
      }
    };
    return (
      <div className="flex gap-0">
        <div className="flex-1 justify-center items-start px-3 py-2 font-medium bg-rose-50 text-neutral-900 max-md:pr-5 text-sm">
          {label}
        </div>
        <div className="flex-1 justify-center items-start px-3 py-1 border-b border-rose-50 border-solid text-neutral-400 max-md:pr-5 text-sm">
          {label === 'Gender' ? renderGenderContent(value) : value}
        </div>
      </div>
    );
  }

  const JewelleryContent = () => {
    return (
      <div className="px-4 py-6 rounded-lg max-md:max-w-full flex gap-5  bg-white">
        <div className="w-full border border-rose-50 rounded-lg">
          {previewData?.jewelry_info?.length > 0 && previewData?.jewelry_info?.map((item, index) => (
            <InfoItem key={index} label={item?.title} value={item?.value} />
          ))}
        </div>
      </div>
    )
  }

  const AboutContent = () => {
    return (
      <>
        {previewData?.about_this_item_html &&
          <div className="px-4 py-6 rounded-lg max-md:max-w-full text-base bg-white">
            <div className=" text-neutral-400 font-medium"
              dangerouslySetInnerHTML={{ __html: previewData?.about_this_item_html }}
            />
          </div>
        }
      </>
    )
  }

  const AdditionalContent = () => {
    return (
      <>
        {previewData?.additional_info_html &&
          <div className="px-4 py-6 rounded-lg max-md:max-w-full text-base bg-white">
            <div className=" text-neutral-400 font-medium"
              dangerouslySetInnerHTML={{ __html: previewData?.additional_info_html }}
            />
          </div>
        }
      </>
    )
  }

  const ImportantInformation = () => {
    return (
      <>
        <div className="my-5">
          <h2 className="text-xl font-semibold leading-8 text-neutral-900 ">
            Important Information
          </h2>
          <div className="mb-3">
            <div className="text-sm my-1 font-bold">Safety Information:</div>
            <div className="text-neutral-400 text-sm">
              {previewData?.safety_Information}
            </div>
          </div>
          <div>
            <div className="text-sm my-1 font-bold">Legal Disclaimer:</div>
            <div className="text-neutral-400 text-sm">
              {previewData?.legal_Disclaimer}
            </div>
          </div>
        </div>
      </>
    )
  }


  const InformationSection = ({ title, infoItems }) => {

    const [isOpen, setIsOpen] = useState(true);

    const handleToggle = () => {
      setIsOpen(!isOpen);
    };

    const renderContent = () => {
      switch (title) {
        case 'Jewellery Information':
          return <JewelleryContent />;
        case 'About This item':
          return <AboutContent />;
        case 'Additional Information':
          return <AdditionalContent />;
        default:
          return null;
      }
    };


    return (
      <section className="flex flex-col bg-white rounded-xl border border-gray-200 border-solid max-md:max-w-full mt-5">
        <header
          onClick={handleToggle}
          className="flex justify-between items-center gap-3 px-4 py-3.5 border-b border-gray-200 shadow-2xl max-md:flex-wrap max-md:max-w-full">
          <h2 className="text-xl font-semibold leading-8 text-neutral-900 ">
            {title}
          </h2>
          {isOpen ?
            <i className="fa fa-angle-down"></i>
            :
            <i className="fa fa-angle-up"></i>
          }
        </header>
        {isOpen && (
          <div className="">
            {renderContent()}
          </div>
        )}
      </section>
    );
  }

  const handleRejectProduct = async () => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('login data');
    const userId = JSON.parse(user_id);

    if (!storedToken) {
      navigate('/');
      return;
    }

    toast.dismiss();
    if (!reason || reason.trim() === "") {
      toast.error("Please provide a reason for rejection.", {
        position: 'top-center',
        autoClose: 3000,
      });
      return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("product_id", productId);
      formdata.append("status", "2");
      formdata.append("reasons", reason);
      const result = await fetchWithFormDataToken('product-approval', formdata, storedToken, null, abortController);
      if (result?.status === true) {
        navigate('/dashboard/products')
      }
      else {
      }
    } catch (error) {
    }
    finally {
      abortController.abort();
    }
  };

  const handleApproveProduct = async () => {

    const tokenValue = localStorage?.getItem('login api response token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('login data');
    const userId = JSON.parse(user_id);

    if (!storedToken) {
      navigate('/');
      return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();
      formdata.append("product_id", productId);
      formdata.append("status", "1");
      const result = await fetchWithFormDataToken('product-approval', formdata, storedToken, null, abortController);
      if (result?.status === true) {
        navigate('/dashboard/products')
      }
      else {
      }
    } catch (error) {
    }
    finally {
      abortController.abort();
    }
  };

  const alertApproveButtonContent = () => {

    const noButton = () => {
      setIsApproveModalOpen(false)
      // setIsModalEditAlert(false)
    }
    return (
      <>
        <div className='flex gap-5'>
          <BgButton
            buttonName={`Yes`}
            onClick={() => handleApproveProduct()}

          />
          <OutlineButton
            buttonName={`No`}
            onClick={() => noButton()}
          />
        </div>
      </>
    )
  }

  const alertRejectButtonContent = () => {

    const noButton = () => {
      setIsRejectModalOpen(false)
      // setIsRejectModalOpen(false)
    }
    return (
      <>
        <div className="flex gap-5 flex-col">
          <textarea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder='Enter Reason'
            className=' w-full border border-gray-200 rounded-lg text-sm resize-none'
            rows="6"
          >
          </textarea>

          <div className='flex gap-5 '>
            <BgButton
              buttonName={`Yes`}
              onClick={() => handleRejectProduct()}

            />
            <OutlineButton
              buttonName={`No`}
              onClick={() => noButton()}
            />
          </div>
        </div>
      </>
    )
  }

  console.log("previewData", previewData);

  return (
    <>

      <div >
        <div className='font-bold text-xl'>
          Product Details
        </div>
        <TwoBreadcrumb
          href1={`/dashboard/products`}
          name1={`Products`}
          href2={``}
          name2={`Product Details`}
        />
      </div>

      <main className="flex flex-col justify-center self-stretch mt-5">
        <section className="flex flex-col p-8 bg-white rounded-lg max-md:px-5 max-md:max-w-full">
          <div className="max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[45%] max-md:ml-0 max-md:w-full">
                <ImageGallery previewData={previewData} />
                <InformationSection title="Jewellery Information"
                />
              </div>
              <div className="flex flex-col ml-5 w-[55%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow max-md:mt-6 max-md:max-w-full">
                  <h1 className="mt-2 text-xl leading-7 text-zinc-900 max-md:max-w-full">
                   {previewData?.product_name}
                  </h1>
                  <div className="flex gap-5 justify-between mt-4 text-sm leading-5 text-zinc-900 max-md:flex-wrap grid grid-cols-2 text-left">
                    <div>
                      <span className="text-gray-500">Brand: </span>
                      <span className="font-semibold text-zinc-900">MyBzB</span>
                    </div>
                    <div
                    >
                      <span className="text-gray-500">Category: </span>
                      <span className="font-semibold text-zinc-900">{previewData?.category_name}</span>
                    </div>
                  </div>
                  {/* <div className="flex gap-5 justify-between mt-2 text-sm leading-5 max-md:flex-wrap">
                  <div className="text-zinc-900">
                    <span className="text-gray-500">Sku: </span>
                    <span className="font-semibold text-zinc-900">A264671</span>
                  </div>
                  <div className="text-green-600 max-md:max-w-full">
                    <span className="text-gray-500">Availability: </span>
                    <span className="font-semibold text-green-600"> In Stock </span>
                  </div>
                </div> */}
                  <div className="flex gap-2 items-center self-start mt-6 leading-[150%]">
                    <div className="self-stretch text-2xl font-bold text-red-700"> ₹ {Number(previewData?.selling_price).toLocaleString('en-IN')} </div>
                    {previewData?.original_price !== previewData?.selling_price &&
                      <div className="self-stretch my-auto text-base text-neutral-400 line-through"> ₹ {Number(previewData?.original_price).toLocaleString('en-IN')}</div>
                    }
                    {previewData?.discount > 0 && (
                      <div className="justify-center self-stretch p-1 my-auto text-sm font-medium text-green-600 rounded-lg bg-green-600 bg-opacity-10">
                        {previewData?.discount_type === 'flat' ? (
                          <span>
                            {previewData?.discount < 0 ? '' : 'Flat ₹'}
                            {Math.abs(previewData?.discount)} off
                          </span>
                        )
                          :

                          <span>

                            {Math.abs(previewData?.discount)}
                            {previewData?.discount < 0 ? '' : '%'} off
                          </span>
                        }
                      </div>
                    )}
                  </div>
                  <hr className="shrink-0 mt-6 h-px bg-gray-200 border-border-gray-200 max-md:max-w-full" />
                  <div className="mt-6 text-sm font-bold leading-5 text-neutral-900 max-md:max-w-full">
                    <span className="">Select Size -</span> <span className="">Gross Weight</span>
                  </div>
                  <div className="mt-1 text-xs leading-5 text-neutral-400 max-md:max-w-full">
                    Weight and price of the jewellery item may very subject to the stock available
                  </div>
                  <div className="flex gap-5 pr-20 mt-3 leading-[150%] max-md:flex-wrap max-md:pr-5">
                    <div className="flex flex-col">
                      {previewData?.variation?.length > 0 &&
                        previewData.variation
                          .filter(item => item?.data?.length > 0 && item?.data[0] !== "none" && item?.data[0] !== "0")
                          .map((item, index) => (
                            <div className="flex gap-5 pr-20 mt-3 leading-[150%] max-md:flex-wrap max-md:pr-5" key={index}>
                              <div className="flex flex-col">
                                {item?.data.some(size => size !== "none" && size !== "0") && (
                                  <>
                                    <h3 className="text-sm font-semibold text-neutral-900">{item?.name} in {item?.type}</h3>
                                    <div className="flex gap-3 mt-3 text-xs text-red-700 whitespace-nowrap">
                                      {item?.data.map((size, index) => (
                                        size !== "none" && size !== "0" ? (
                                          <div
                                            key={index}
                                            onClick={() => handleSizeClick(index)}
                                            className={
                                              index === selectedSizeIndex
                                                ? "justify-center px-3 py-1 bg-rose-50 border border-red-700 border-solid rounded-[360px] cursor-pointer"
                                                : "justify-center px-3 py-1 bg-rose-50 rounded-[360px] cursor-pointer"
                                            }
                                          >
                                            {size}
                                          </div>
                                        ) : null
                                      ))}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          ))
                      }
                    </div>
                  </div>
                  <div className="flex gap-2 p-2 mt-6 text-xs leading-4 text-red-700 bg-rose-50 rounded-lg font-[510] max-md:flex-wrap">

                    <ImgComp
                      src={Images.guarantee_img}
                      className={`w-12`}
                    />
                    <div className="flex-1 my-auto max-md:max-w-full">
                      <span className="text-gray-800">
                        Don’t worry, we’ve a 10-day return policy on this product.
                      </span>{" "}
                      <span className="font-semibold text-red-700"> See Details </span>
                    </div>
                  </div>
                  <section className="flex gap-0 my-6 bg-white rounded-lg border border-gray-200 border-solid max-md:flex-wrap py-5">
                    <div
                      className="flex flex-1 gap-3 justify-center rounded-lg max-md:px-5"
                    >
                      <ImgComp
                        src={Images.free_delivery_img}
                        className={`w-12`}
                      />
                      <div className="my-auto text-xs font-medium leading-4 text-center text-neutral-400">
                        Free Delivery
                      </div>
                    </div>
                    <div
                      className="flex flex-1 gap-3 justify-center rounded-lg max-md:px-5"
                    >
                      <ImgComp
                        src={Images.return_policy_img}
                        className={`w-12`}
                      />
                      <div className="my-auto text-xs font-medium leading-4 text-center text-neutral-400">
                        10 Days Return Policy
                      </div>
                    </div>
                    <div
                      className="flex flex-1 gap-3 justify-center rounded-lg max-md:px-5"
                    >
                      <ImgComp
                        src={Images.cod_img}
                        className={`w-12`}
                      />
                      <div className="my-auto text-xs font-medium leading-4 text-center text-neutral-400">
                        Pay on Cash
                      </div>
                    </div>
                  </section>
                  <InformationSection title="About This item" infoItems={[]} />
                  <InformationSection title="Additional Information" infoItems={[]} />
                  <ImportantInformation />
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex justify-between"
          >
            <div>
              <OutlineButton
                buttonName={`Back`}
                onClick={() => navigate('/dashboard/products')}
              />
            </div>
            
            {previewData.is_admin_approved === "0" &&
              <div className="flex gap-5">
                <OutlineButton
                  onClick={() => setIsRejectModalOpen(true)}
                  buttonName={`Reject`}
                />
                <BgButton
                  onClick={() => setIsApproveModalOpen(true)}
                  buttonName={`Approve`}
                />
              </div>
            }

            {previewData.is_admin_approved === "1" &&
              <div className="flex gap-5">
                <OutlineButton
                  onClick={() => setIsRejectModalOpen(true)}
                  buttonName={`Reject`}
                />
              </div>
            }

            {previewData.is_admin_approved === "2" &&
              <div className="flex gap-5">
                <BgButton
                  onClick={() => setIsApproveModalOpen(true)}
                  buttonName={`Approve`}
                />
              </div>
            }

          </div>
        </section>
      </main>
      {isApproveModalOpen &&
        <AlertModalBox
          modalWidth={`max-w-md`}
          onClose={() => setIsApproveModalOpen(false)}
          buttonContent={alertApproveButtonContent()}
          title={`Approve`}
        />
      }

      {isRejectModalOpen &&
        <AlertModalBox
          modalWidth={`max-w-md`}
          onClose={() => setIsRejectModalOpen(false)}
          buttonContent={alertRejectButtonContent()}
          title={`Reject`}
        />
      }
      <ToastContainer />

    </>
  );
}

export default ProductDetailpage;   
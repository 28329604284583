import React, { useEffect, useState } from 'react';
import * as Images from '../../../../assets';
import ModalBoxWrapper from '../../../ReusableComp/ModalBoxWrapper';
import ImgComp from '../../../ReusableComp/ImgComp';
import { fetchDataWithToken, fetchWithFormDataToken } from '../../../../apiGeneralFunction';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteModalBox from '../../../ReusableComp/DeleteModalBox';
import { ExportIcon, SortIcon } from '../../../ReusableComp/Icons';
import ProfileImageComponent from '../../../ReusableComp/ProfileImageComponent';
import TrimmedComponent from '../../../ReusableComp/TrimmedComponent';
import { FormattedDate } from '../../../ReusableComp/FormattedDate';
import TableNoDataFound from '../../../ReusableComp/TableNoDataFound';
import Loader from '../../../ReusableComp/Loader';



const Categories = () => {

    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [image, setImage] = useState(null);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [loading, setLoading] = useState(true);

    const [categoryList, setCategoryList] = useState([]);
    const [addCategory, setAddCategory] = useState([]);
    const [name, setName] = useState('');
    const [unit, setUnit] = useState('');

    const changeName = categoryList.map((item) => item?.categoryName)
    const changeSize = categoryList.map((item) => item?.size)

    const [nameUpdate, setNameUpdate] = useState('');
    const [idUpdate, setIdUpdate] = useState('');
    const [unitUpdate, setUnitUpdate] = useState('');

    const controller = new AbortController();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchData();
        return () => {
            controller.abort();
        };
    }, []);

    const fetchData = async () => {
        setLoading(true)
        try {
            const tokenValue = localStorage?.getItem('token');
            const storedToken = JSON?.parse(tokenValue);
            if (!storedToken) {
                navigate('/');
                return;
            }
            const result = await fetchDataWithToken('list-category', storedToken, navigate);
            if (result?.status === true) {
                setCategoryList(result?.data)
            }
        } catch (error) {
        }
        finally {
            setLoading(false)
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFile(file);
    };

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        handleFile(file);
        const fileName = file;
        console.log("fileName", fileName);
        setSelectedFileName(fileName?.name)
    };

    const handleFile = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(file);

        console.log("hangleFile", file);
        setSelectedFile(file)


    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setName('')
        setUnit('')
        setImage(null)
    };

    const openEditModal = () => {
        setIsModalEditOpen(true);
    };

    const closeEditModal = () => {
        setIsModalEditOpen(false);
        setNameUpdate('')
        setUnitUpdate('')
    };

    const modalContent = (
        <>
            <div className='flex flex-col h-full '>
                <div className='text-left mb-5 font-bold text-xl'>
                    Categories Information
                </div>
                <div className='flex justify-between gap-10 h-full '>
                    <div className='w-full flex flex-col justify-between h-full '>
                        <div className='flex flex-col items-start'>
                            <label className='text-neutral-500'>Categories Name
                                <span className='mybzb_text_clr'> *</span>
                            </label>
                            <input
                                className='mt-3 w-full border border-gray-200 rounded-lg'
                                placeholder='Enter Name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />

                        </div>
                        <div className='text-left my-5'>
                            <label htmlFor='size' className='mb-3 text-neutral-500'>
                                Measuring Unit
                                <span className='mybzb_text_clr'> *</span> (For Ex: mm, cms or inches)
                            </label>
                            <input
                                className='mt-3 w-full border border-gray-200 rounded-lg'
                                placeholder='Enter Measuring Unit'
                                value={unit}
                                onChange={(e) => setUnit(e.target.value)}

                            />
                            {/* <select id="size" class="block w-full p-2 mt-1 text-sm text-neutral-500 border border-gray-300 rounded-lg bg-white  ">
                                <option selected>Choose a size</option>
                                <option value="US">United States</option>
                                <option value="CA">Canada</option>
                                <option value="FR">France</option>
                                <option value="DE">Germany</option>
                            </select> */}

                        </div>
                        {/* <div className='text-left'>
                            <label htmlFor='status' className='text-neutral-500'>Status
                                <span className='mybzb_text_clr'> *</span>
                            </label>
                            <select id="status" class="mt-1 block w-full p-2  text-sm text-neutral-500 border border-gray-300 rounded-lg bg-white  ">
                                <option selected>Select Status</option>
                                <option value="US">Active</option>
                                <option value="CA">Inactive</option>

                            </select>

                        </div> */}
                    </div>
                    <div className='w-full h-full  flex flex-col'>
                        <div className='mb-3 text-neutral-500'>Categories Image (150px*150px)</div>
                        <div
                            className="flex flex-col grow px-6 py-6 justify-around rounded-lg border border-red-500 border-dashed terms_bg_clr max-md:px-5 mybzb_bg_pinkclr "
                            onDrop={handleDrop}
                            onDragOver={(e) => e.preventDefault()}
                        >
                            <label htmlFor="file-input" className="cursor-pointer ">

                                <div className="text-center  mx-auto ">
                                    {/* <img
                                    loading="lazy"
                                    src={image}
                                    alt="Profile"
                                    className="self-center w-28 border-white border-solid aspect-square border-[6px] mx-auto"
                                /> */}
                                    <ImgComp
                                        src={image ? image : Images.upload_icon}
                                        srcSet={image ? image : Images.upload_icon}
                                        className="self-center w-14 border-white  mx-auto"
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileSelect}
                                        className="hidden"
                                        id="file-input"
                                    />
                                    {/* <div>{selectedFileName}</div> */}
                                </div>
                                {!image &&
                                    <>
                                        <div className='mybzb_text_clr'>Click to upload</div>

                                        <div className="mt-1 text-xs text-center text-neutral-400 max-md:max-w-full">
                                            SVG, PNG, JPG or GIF <br />(max. 150px*150px)
                                        </div>
                                    </>
                                }
                            </label>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );

    const buttonContent = (
        <>
            <div className='flex items-center gap-5 justify-end mt-3'>
                <button
                    className='border border-red-500 mybzb_text_clr text-base px-5 py-2 rounded-lg'
                    onClick={() => setIsModalOpen(false)}
                >
                    Cancel
                </button>
                <button
                    onClick={() => handleSubmit()}
                    className='border border-red-500 mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg'
                >
                    Submit
                </button>
            </div>
        </>
    )

    const modalEditContent = (
        <>
            <div className='flex flex-col  '>
                <div className='text-left mb-5 font-bold text-xl'>
                    Categories Information
                </div>
                <div className='flex justify-between gap-10 '>
                    <div className='w-full flex flex-col  gap-5 justify-around'>
                        <div className='flex flex-col items-start'>
                            <label className='text-neutral-500'>Categories Name

                            </label>
                            <input
                                className='mt-3 w-full border border-gray-200 rounded-lg'
                                placeholder='Enter Name'
                                type='text'
                                value={nameUpdate}
                                onChange={(e) => setNameUpdate(e.target.value)}
                            />

                        </div>
                        <div className='text-left '>
                            <label htmlFor='size' className='mb-3 text-neutral-500'>
                                Measuring Unit
                            </label>
                            <input
                                className='mt-3 w-full border border-gray-200 rounded-lg'
                                placeholder='Enter Measuring Unit'
                                type='text'
                                value={unitUpdate}
                                onChange={(e) => setUnitUpdate(e.target.value)}
                            />

                        </div>
                        {/* <div className='text-left'>
                            <label htmlFor='status' className='text-neutral-500'>Status

                            </label>
                            <select id="status" class="mt-1 block w-full p-2  text-sm text-neutral-500 border border-gray-300 rounded-lg bg-white  ">
                                <option selected>Select Status</option>
                                <option value="US">Active</option>
                                <option value="CA">Inactive</option>
                            </select>

                        </div> */}
                    </div>
                    <div className='w-full flex flex-col'>
                        <div className='mb-3 text-neutral-500 text-sm'>Categories Image (150px*150px)</div>
                        {/* <div
                            className="flex flex-col grow px-6 py-6 justify-around rounded-lg border border-red-500 border-dashed terms_bg_clr max-md:px-5 mybzb_bg_pinkclr "
                            onDrop={handleDrop}
                            onDragOver={(e) => e.preventDefault()}
                        >
                            <label htmlFor="file-input" className="cursor-pointer">

                                <div className="text-center  mx-auto h-full">
                                  
                                    <ImgComp
                                        src={Images.upload_icon}
                                        srcSet={Images.upload_icon}
                                        className="self-center w-14 border-white  mx-auto"
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileSelect}
                                        className="hidden"
                                        id="file-input"
                                    />
                                  
                                </div>


                            </label>
                           
                            {!image &&
                                <>
                                    <div className='mybzb_text_clr'>Click to upload</div>

                                    <div className="mt-1 text-xs text-center text-neutral-400 max-md:max-w-full">
                                        SVG, PNG, JPG or GIF <br />(max. 150px*150px)
                                    </div>
                                </>
                            }
                        </div> */}
                        {/* <ImgComp
                            src={Images.home_product_ring}
                            srcSet={Images.home_product_ring}
                            className=" border h-40 rounded-lg"
                        /> */}
                        <div
                            className="flex flex-col grow px-6 py-6 justify-around rounded-lg border border-red-500 border-dashed terms_bg_clr max-md:px-5 mybzb_bg_pinkclr "
                            onDrop={handleDrop}
                            onDragOver={(e) => e.preventDefault()}
                        >
                            <label htmlFor="file-input" className="cursor-pointer">

                                <div className="text-center  mx-auto h-full">
                                    {/* <img
                                    loading="lazy"
                                    src={image}
                                    alt="Profile"
                                    className="self-center w-28 border-white border-solid aspect-square border-[6px] mx-auto"
                                /> */}
                                    <ImgComp
                                        src={image ? image : Images.upload_icon}
                                        srcSet={image ? image : Images.upload_icon}
                                        className="self-center w-14 border-white  mx-auto"
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileSelect}
                                        className="hidden"
                                        id="file-input"
                                    />
                                    {/* <div>{selectedFileName}</div> */}
                                </div>


                            </label>
                            {/* <div className="w-full text-end">
                                <i className="fa fa-edit text-orange-500"></i>
                            </div> */}
                            {!image &&
                                <>
                                    <div className='mybzb_text_clr'>Click to upload</div>

                                    <div className="mt-1 text-xs text-center text-neutral-400 max-md:max-w-full">
                                        SVG, PNG, JPG or GIF <br />(max. 150px*150px)
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

    const buttonEditContent = (
        <>
            <div className='flex items-center gap-5 justify-end mt-3'>
                <button
                    className='border border-red-500 mybzb_text_clr text-base px-5 py-2 rounded-lg'
                    onClick={() => setIsModalEditOpen(false)}
                >
                    Cancel
                </button>
                <button
                    className=' mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg'
                    onClick={() => updateAPI()}
                >
                    Save Changes
                </button>
            </div>
        </>
    )

    const modalDeleteContent = (
        <>
            <div className='text-center text-xl mb-5 px-5'>
                Are you sure want to delete this {nameUpdate} ?
            </div>

        </>
    );

    const buttonDeleteContent = (
        <>
            <div className='flex items-center gap-5 justify-end mt-3'>
                <button
                    className='border border-red-500 mybzb_text_clr text-base px-5 py-2 rounded-lg w-full'
                    onClick={() => setIsModalDeleteOpen(false)}
                >
                    No
                </button>
                <button
                    className=' mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg w-full'
                    onClick={() => deleteAPI()}
                >
                    Yes
                </button>
            </div>
        </>
    )

    const handleSubmit = async () => {

        const tokenValue = localStorage?.getItem('token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }

        const mapUnitToValue = unit => {
            switch (unit) {
                case 'mm':
                    return '1';
                case 'inches':
                    return '2';
                case 'cms':
                    return '3';
                default:
                    return '';
            }
        };



        const size = mapUnitToValue(unit);

        console.log("size conersion", size);

        const abortController = new AbortController();

        try {

            const formdata = new FormData();

            if (selectedFile) {

                formdata.append("image", selectedFile, selectedFileName);
            }
            else {

                formdata.append("image", '');
            }



            formdata.append("name", name);
            formdata.append("size", size);

            const result = await fetchWithFormDataToken('add-category', formdata, storedToken, abortController);

            console.log("add category response", result);
            if (result?.status === true) {
                toast.success(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
                setAddCategory(result?.data)
                fetchData();
                setIsModalOpen(false)
                setName('');
                setUnit('');
                // setImage('');

            }
            else {
                toast.error(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
                setName('');
                setUnit('');
                setImage('');

            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }


    };

    const deleteAPI = async () => {


        const tokenValue = localStorage?.getItem('token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }
        const abortController = new AbortController();

        try {

            const formdata = new FormData();


            formdata.append("id", idUpdate);

            const result = await fetchWithFormDataToken('delete-category', formdata, storedToken, abortController);

            console.log("delete category response", result);
            if (result?.status === true) {
                toast.success(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
                fetchData();
                setIsModalDeleteOpen(false)
            }
            else {
                toast.error(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
                setIsModalDeleteOpen(false)

            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }


    };

    const updateItem = (item) => {

        console.log("item update", item);
        setIdUpdate(item?.id)

        setIsModalEditOpen(true);
        setNameUpdate(item?.categoryName)
        setUnitUpdate(item?.size)

    };

    const deleteItem = (item) => {

        console.log("item update", item);
        setIdUpdate(item?.id)
        setIsModalDeleteOpen(true);
        setNameUpdate(item?.categoryName)
    };


    const updateAPI = async () => {

        console.log("update image API", image, selectedFileName);
        const tokenValue = localStorage?.getItem('token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }

        const mapUnitToValue = unitUpdate => {
            switch (unitUpdate) {
                case '1':
                    return 'mm';
                case '2':
                    return 'inches';
                case '3':
                    return 'cms';
                case 'mm':
                    return '1';
                case 'inches':
                    return '2';
                case 'cms':
                    return '3';
                default:
                    return '';
            }
        };



        const size = mapUnitToValue(unitUpdate);

        console.log("upadte size conersion", size);
        const abortController = new AbortController();

        try {

            const formdata = new FormData();
            if (selectedFile) {

                formdata.append("image", selectedFile, selectedFileName);
            }
            else if (image) {

                formdata.append("image", image);
            }

            formdata.append("id", idUpdate);
            formdata.append("name", nameUpdate);
            formdata.append("size", size);


            const result = await fetchWithFormDataToken('update-category', formdata, storedToken, abortController);

            console.log("delete category response", result);
            if (result?.status === true) {
                toast.success(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
                fetchData();
                setIsModalEditOpen(false);
                setImage('')
            }
            else {
                toast.error(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });

            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    }

    const EnableDisableAPI = async (item) => {
        console.log("EnableDisableAPI", item?.status);

        const tokenValue = localStorage?.getItem('token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }

        const newStatus = item.status === 'active' ? 'inactive' : 'active';

        const abortController = new AbortController();

        try {

            const formdata = new FormData();
            formdata.append("id", item?.id);
            formdata.append("status", newStatus);


            const result = await fetchWithFormDataToken('enable-disable-category', formdata, storedToken, abortController);

            console.log("enable-disable-category response", result);
            if (result?.status === true) {
                toast.success(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
                fetchData();
            }
            else {
                toast.error(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }

    }
    return (
        <>
            <div className='w-full flex justify-between items-center bg-white p-5 rounded-tr-3xl'>
                <div className='font-bold text-2xl'>Categories</div>
                <div className='flex items-center gap-5 text-sm'>
                    {/* <SortIcon /> */}
                    <ExportIcon />
                    <div onClick={openModal} className='mybzb_bg_clr text-white px-3 py-1 rounded-lg cursor-pointer'>
                        <i className='fa fa-plus me-3'></i>
                        Add
                    </div>
                </div>
            </div>

            <div className='shadow-md rounded-b-3xl '>
                <table class=" w-full text-xs text-left rtl:text-right text-gray-500">
                    <thead class="text-sm text-gray-700 ">
                        <tr className='text-center bg-gray-50'>

                            <th scope="col" class="px-6 py-3">
                                No.
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Category Image
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Category Name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Measurement <span className='text-gray-400'>(Based on Categories)</span>
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Created date
                            </th>
                            {/* <th scope="col" class="px-6 py-3">
                                Status
                            </th> */}
                            <th scope="col" class="px-6 py-3">
                                Enable/Disable
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody className='' >
                        {
                            loading ? (
                                <>
                                    <tr>
                                        <td colSpan={7}>
                                            <Loader />
                                        </td>
                                    </tr>

                                </>
                            ) : (
                                categoryList?.length > 0 ?
                                    categoryList && categoryList?.slice()?.reverse().map((item, index) => {
                                        const createdDate = item?.craetedDate;
                                        const dateOnly = new Date(createdDate).toLocaleDateString();
                                        return (
                                            <tr className="border-red-500  hover:bg-gray-50 text-center">

                                                <td className="px-6 py-4">
                                                    {index + 1}
                                                </td>
                                                <td className="px-6 py-4">
                                                    <ProfileImageComponent
                                                        profilePicUrl={item?.categoryImage}
                                                        className={`w-12 h-12 rounded-full border mx-auto`}
                                                    />
                                                </td>
                                                <td className="px-6 py-4">
                                                    <TrimmedComponent trimdata={item?.categoryName} />
                                                </td>
                                                <td className="px-6 py-4">
                                                    <TrimmedComponent trimdata={item?.size} />
                                                </td>
                                                <td className="px-6 py-4">
                                                    <FormattedDate date={item?.craetedDate || "-"} />
                                                </td>
                                                <td className="px-6 py-4 flex items-center justify-center gap-5">
                                                    {item?.status === 'active' &&
                                                        <div onClick={() => EnableDisableAPI(item)}>
                                                            <i className='fa fa-check-square text-xl text-green-700'></i>
                                                        </div>
                                                    }
                                                    {item?.status === 'inactive' &&
                                                        <div className='cursor-pointer '
                                                            onClick={() => EnableDisableAPI(item)}
                                                        >
                                                            <i className='fa fa-times-rectangle text-xl mybzb_text_clr'></i>
                                                        </div>
                                                    }

                                                </td>
                                                <td className='text-center '>
                                                    <div className='flex justify-center items-center gap-5'>
                                                        <div
                                                            className='cursor-pointer edit_bg px-2 rounded-lg'
                                                            onClick={() => updateItem(item)}
                                                        >
                                                            <i className='fa fa-pencil text-lg'></i>
                                                        </div>
                                                        <div
                                                            className='cursor-pointer inactive_bg px-2 rounded-lg'
                                                            onClick={() => deleteItem(item)}
                                                        >
                                                            <i className="fa fa-trash-o mybzb_text_clr text-lg "></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <TableNoDataFound colSpan={7} />
                            )
                        }
                    </tbody>
                </table>
            </div>

            {isModalOpen && (
                <ModalBoxWrapper
                    title="Add Categories"
                    content={modalContent}
                    buttonContent={buttonContent}
                    onClose={closeModal}
                    modalWidth={`max-w-2xl`}
                />
            )}

            {isModalEditOpen && (
                <ModalBoxWrapper
                    title="Edit Categories"
                    content={modalEditContent}
                    buttonContent={buttonEditContent}
                    onClose={closeEditModal}
                    modalWidth={`max-w-xl`}
                />
            )}

            {isModalDeleteOpen && (
                <DeleteModalBox
                    content={modalDeleteContent}
                    buttonContent={buttonDeleteContent}
                    modalWidth={`max-w-sm`}
                />
            )}
            <ToastContainer />

        </>
    )
}

export default Categories
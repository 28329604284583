import React, { useEffect, useState } from 'react';
import ImgComp from '../../../../ReusableComp/ImgComp';
import * as Images from '../../../../../assets';
import { fetchDataWithToken, fetchWithFormDataToken } from '../../../../../apiGeneralFunction';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalBoxWrapper from '../../../../ReusableComp/ModalBoxWrapper';
import { CustomerEditModal, SellerModalBoxWrapper } from '../../../../ReusableComp/ModalBox';
import RichTextEditor from '../../../../ReusableComp/RichTextEditor';
import toastPromise from '../../../../../toastService';

const AboutusList = () => {

  const navigate = useNavigate();

  const controller = new AbortController();

  const [generalList, setGeneralList] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [nameUpdate, setNameUpdate] = useState('');
  const [idUpdate, setIdUpdate] = useState('');
  const [descUpdate, setDescUpdate] = useState('');

  useEffect(() => {
    fetchData();
    return () => {
      controller.abort();
    };
  }, []);

  const fetchData = async () => {
    const tokenValue = localStorage?.getItem('token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('login data');
    const userId = JSON.parse(user_id);
    console.log("categories login id", userId);

    if (!storedToken) {
      navigate('/');
      return;
    }

    const abortController = new AbortController();

    try {

      const formdata = new FormData();

      formdata.append("user_id", userId?.user_id);
      const result = await fetchWithFormDataToken('cms-list', formdata, storedToken, abortController);

      if (result?.status === true) {
        setName(result?.data[0])
      }
      else {
      }
    } catch (error) {
    }
    finally {
      abortController.abort();
    }
  };

  const modalContent = (
    <>
      <div className='flex flex-col h-full border rounded-lg p-4 '>
        <div className='text-left mb-5 font-bold text-xl'>
          Content 5
        </div>
        <div className='flex justify-between gap-10 h-full '>
          <div className='w-full flex flex-col justify-between h-full text-sm'>
            <div className='flex flex-col items-start'>
              <label className='text-neutral-500'>Title
                <span className='mybzb_text_clr'> *</span>
              </label>
              <input
                className='mt-3 w-full border border-gray-200 rounded-lg  text-sm'
                placeholder='Enter Title'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

            </div>
            <div className='text-left my-5'>
              <label htmlFor='size' className='mb-3 text-neutral-500'>
                Description
                <span className='mybzb_text_clr'> *</span>
              </label>
              <textarea
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
                placeholder='Enter Description'
                className='mt-3 w-full border border-gray-200 rounded-lg h-full h-28 text-sm'
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {

    const tokenValue = localStorage?.getItem('token');
    const storedToken = JSON?.parse(tokenValue);

    const user_id = localStorage?.getItem('login data');
    const userId = JSON.parse(user_id);
    console.log("categories login id", userId);

    if (!storedToken) {
      navigate('/');
      return;
    }

    const abortController = new AbortController();
    console.log("name update", nameUpdate);

    try {

      const formdata = new FormData();

      formdata.append("cms_id", 1);
      formdata.append("page_name", "About Us");
      formdata.append("content", nameUpdate);
      formdata.append("type", 2);
      // const result = await fetchWithFormDataToken('cms-add-update', formdata, storedToken, abortController);

      // if (result?.status === true) {
      //   toast.success(result?.message, {
      //     position: 'top-center',
      //     autoClose: 2000,
      //   });
      //   fetchData();
      // }
      // else {
      //   toast.error(result?.message, {
      //     position: 'top-center',
      //     autoClose: 2000,
      //   });
      // }
      const response = await toastPromise(
        fetchWithFormDataToken('cms-add-update', formdata, storedToken, abortController, null),
        {
            pending: "Updating About Us...",
            success: (data) => data.message || "About us content is changed successful !",
            error: (data) => data.message || "Failed to change the About us content",
        }
    );

    if (response.status === true) {
      fetchData();
    }
    } catch (error) {
    }
    finally {
      abortController.abort();
    }
  }

  const buttonContent = (
    <>
      <div className='flex items-center gap-5 justify-end mt-3'>
        <button
          className='border border-red-700 mybzb_text_clr text-base px-5 py-2 rounded-lg'
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </button>
        <button
          onClick={() => handleSubmit()}
          className='border border-red-700 mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg'
        >
          Submit
        </button>
      </div>
    </>
  )

  useEffect(() => {
    setNameUpdate(
      name?.content || '',
    );
  }, [name]);

  return (
    <>
      <section className="flex flex-col pb-8 bg-white rounded-b-3xl rounded-r-3xl max-md:max-w-full">
        <div className='px-5 border-b border-gray-200 border-solid flex items-center justify-between'>
          <header className="items-start  py-4 text-2xl font-semibold text-neutral-900 max-md:pr-5 max-md:max-w-full">
            About Us
          </header>
          <div className='flex items-center gap-5 text-xs'>
            {/* <div className='flex items-center gap-3 border rounded-lg px-3 py-1 text-gray-500  '>
              <ImgComp
                src={Images.export_icon}
                srcSet={Images.export_icon}
                className='w-5'
              />
              Export
            </div> */}
            {/* <div
              onClick={openModal}
              className='mybzb_bg_clr text-white px-3 py-1.5 rounded-lg cursor-pointer  '>
              <i className='fa fa-plus me-3'></i>
              Add
            </div> */}
          </div>
        </div>
        <div className="self-center mt-4 w-full px-5 gap-5 grid grid-cols-1">
          <RichTextEditor
            labelName={`About Us`}
            value={nameUpdate}
            onChange={(value) => setNameUpdate(value)}
          />
          <div className='ms-auto'>
            <button
              onClick={() => handleSubmit()}
              className='w-auto border border-red-700 mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg'
            >
              Submit
            </button>
          </div>
        </div>

      </section>

      {isModalOpen && (
        <CustomerEditModal
          title="Add Content"
          content={modalContent}
          buttonContent={buttonContent}
          onClose={closeModal}
          modalWidth={`max-w-2xl`}
        />
      )}

      <ToastContainer />
    </>
  )
}

export default AboutusList
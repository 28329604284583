import React, { useEffect, useState } from 'react'
import { TwoBreadcrumb } from '../../../ReusableComp/Breadcrumb'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../../ReusableComp/Layout/NavbarComp/Navbar';
import { ArrowDownIcon, ExportIcon, Search, SortIcon } from '../../../ReusableComp/Icons';
import { fetchWithFormDataToken } from '../../../../apiGeneralFunction';
import ImgComp from '../../../ReusableComp/ImgComp';
import * as Images from '../../../../assets';
import StarRating from '../../../ReusableComp/StarRating';
import ProfileImageComponent from '../../../ReusableComp/ProfileImageComponent';
import TrimmedComponent from '../../../ReusableComp/TrimmedComponent';
import { NumberConversion } from '../../../ReusableComp/NumberConversion';

const OrderDetailpage = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const orderId = location?.state?.orderDetailId;

    console.log("orderId", orderId);

    const [orderDetails, setOrderDetails] = useState([]);
    const [customerData, setCustomerData] = useState();
    const [totalPages, setTotalPages] = useState();
    const controller = new AbortController();

    useEffect(() => {
        fetchOrderDetailData();
        return () => {
            controller.abort();
        };
    }, []);

    const fetchOrderDetailData = async () => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);
        
        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            // return;
        }
        const abortController = new AbortController();
        try {
            const formdata = new FormData();
            formdata.append("order_id", orderId);
            const result = await fetchWithFormDataToken(`get-customer-order-details`, formdata, storedToken, abortController);
            if (result?.status === true) {
                setOrderDetails(result?.data?.order_details);
                setCustomerData(result?.data?.orderSummery);
            }
            else {
            }
        } catch (error) {
        }
        finally {
            abortController.abort();
        }
    }

    const handlePageChange = (newPage) => {
        fetchOrderDetailData(newPage);
    }

    const jsonResponse = {
        "steps": [
            { "name": "Order Confirmed", "date": "Mar 27, 2024", "completed": true },
            { "name": "Prepare Product", "date": "Mar 27, 2024", "completed": true },
            { "name": "Shipped", "date": "Mar 27, 2024", "completed": false },
            { "name": "Delivered", "date": "Mar 28, 2024", "completed": false }
        ]
    };


    const renderStatus = (status) => {
        switch (status) {
            case "1":
                return <span className="text-amber-600 bg-amber-50 px-3 rounded-lg">Pending</span>;
            case "2":
                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Confirm</span>;
            case "3":
                return <span className="text-blue-600 bg-blue-50 px-3 rounded-lg">Shipping</span>;
            case "4":
                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Delivered</span>;
            case "5":
                return <span className="text-green-600 bg-green-50 px-3 rounded-lg">Completed</span>;
            case "6":
                return <span className="text-red-600 bg-red-50 px-3 rounded-lg">Cancelled</span>;
            case "7":
                return <span className="text-amber-600 bg-amber-50 px-3 rounded-lg">Returned</span>;
            default:
                return <span className="text-gray-500">Unknown</span>;
        }
    };

    // const Timeline = ({ statusData }) => {

    //     const allStatuses = [
    //         { id: 1, name: "Pending" },
    //         { id: 2, name: "Confirm" },
    //         { id: 3, name: "Shipping" },
    //         { id: 4, name: "Delivered" },
    //         { id: 5, name: "Completed" },
    //         { id: 6, name: "Cancelled" },
    //         { id: 7, name: "Returned" }
    //     ];

    //     const latestOrderStatus = statusData?.length > 0 ? Math.max(...statusData.map(data => data.order_status)) : 0;

    //     return (
    //         <>
    //             <div className="rounded-lg shadow-xl bg-white w-full p-5">
    //                 <div className="text-base font-semibold text-neutral-900 mb-5">Order Status</div>
    //                 <div className="flex relative flex-col justify-between items-start mt-6 w-full min-h-[233px]">
    //                     {allStatuses.map((step, index) => {
    //                         const isLastStep = index === allStatuses.length - 1;
    //                         const isCompleted = step.id <= latestOrderStatus;
    //                         const statusEntry = statusData?.find(data => data?.order_status === step?.id);

    //                         return (
    //                             <React.Fragment key={index}>
    //                                 {!isLastStep && (
    //                                     <div
    //                                         className={`absolute left-2 z-0 w-0 ${isCompleted ? 'border border-green-700 border-dashed' : 'border border-dashed border-gray-300'}`}
    //                                         style={{
    //                                             top: `${index * 40 + 18}px`,
    //                                             height: isCompleted ? '40px' : '40px',
    //                                         }}
    //                                     />
    //                                 )}
    //                                 <div className="flex z-10 gap-4 items-start">
    //                                     <div className="flex gap-2.5 items-center w-4">
    //                                         <i className={`fa fa-circle px-0.5 rounded-full text-xs ${isCompleted ? 'border border-green-600 text-green-600' : 'border border-gray-500 text-gray-500'}`}></i>
    //                                     </div>
    //                                     <div className="flex flex-col justify-center">
    //                                         <div className={`flex gap-0.5 items-center text-sm font-bold ${isCompleted ? 'text-green-700' : 'text-neutral-600'}`}>
    //                                             <div className="self-stretch my-auto">{step.name}</div>
    //                                         </div>
    //                                         <div className={`mt-1 text-xs  ${isCompleted ? 'text-neutral-800' : 'text-neutral-400'}`}>
    //                                             {statusEntry ? new Date(statusEntry.datetime).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : 'No date available'}
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </React.Fragment>
    //                         );
    //                     })}

    //                     {/* {latestOrderStatus > 0 && (
    //                         <div
    //                             className="absolute left-2 z-0 w-0 border border-red-700"
    //                             style={{
    //                                 top: `${(latestOrderStatus - 1) * 40 + 18}px`,
    //                                 height: '70px',
    //                             }}
    //                         />
    //                     )} */}
    //                 </div>
    //             </div>
    //         </>
    //     );
    // };

        const Timeline = ({ statusData }) => {

        const allStatuses = [
            { id: 1, name: "Pending" },
            { id: 2, name: "Confirm" },
            { id: 3, name: "Shipping" },
            { id: 4, name: "Delivered" },
            { id: 5, name: "Completed" },
            { id: 6, name: "Cancelled" },
            { id: 7, name: "Returned" }
        ];

        const latestOrderStatus = statusData?.status;

        return (
            <>
                <div className="rounded-lg shadow-xl bg-white w-full p-5">
                    <div className="text-base font-semibold text-neutral-900 mb-5">Order Status</div>
                    <div className="flex relative flex-col justify-between items-start mt-6 w-full min-h-[233px]">
                        {allStatuses.map((step, index) => {
                            const isLastStep = index === allStatuses.length - 1;
                            const isCompleted = step.id <= latestOrderStatus;
                            const statusEntry = statusData?.status;

                            return (
                                <React.Fragment key={index}>
                                    {!isLastStep && (
                                        <div
                                            className={`absolute left-2 z-0 w-0 ${isCompleted ? 'border border-green-700 border-dashed' : 'border border-dashed border-gray-300'}`}
                                            style={{
                                                top: `${index * 32 + 18}px`,
                                                // height: isCompleted ? '40px' : '40px',
                                                height: '40px',
                                            }}
                                        />
                                    )}
                                    <div className="flex z-10 gap-4 items-start">
                                        <div className="flex gap-2.5 items-center w-4">
                                            <i className={`fa fa-circle px-0.5 rounded-full text-xs ${isCompleted ? 'border border-green-600 text-green-600' : 'border border-gray-500 text-gray-500'}`}></i>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                            <div className={`flex gap-0.5 items-center text-sm font-bold ${isCompleted ? 'text-green-700' : 'text-neutral-600'}`}>
                                                <div className="self-stretch my-auto">{step.name}</div>
                                            </div>
                                            {/* <div className={`mt-1 text-xs  ${isCompleted ? 'text-neutral-800' : 'text-neutral-400'}`}>
                                                {statusEntry ? new Date(statusEntry.datetime).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : 'No date available'}
                                            </div> */}
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        })}

                        {/* {latestOrderStatus > 0 && (
                            <div
                                className="absolute left-2 z-0 w-0 border border-red-700"
                                style={{
                                    top: `${(latestOrderStatus - 1) * 40 + 18}px`,
                                    height: '70px',
                                }}
                            />
                        )} */}
                    </div>
                </div>
            </>
        );
    };

    // Sample API data
    const statusData = [
        {
            "id": 3,
            "order_id": 3,
            "order_status": 1,
            "datetime": "2024-09-20 18:08:45",
            "status": "active",
            "created_at": "2024-09-21T18:08:45.000000Z",
            "updated_at": null
        },
        {
            "id": 4,
            "order_id": 3,
            "order_status": 2,
            "datetime": "2024-09-21 18:08:45",
            "status": "active",
            "created_at": "2024-09-21T18:08:45.000000Z",
            "updated_at": null
        }
    ];

    return (
        <>
            <div className='flex justify-between items-end mb-5'>
                <div>
                    <div className='font-bold text-2xl'>
                        Order ID #{orderDetails?.order_id}
                    </div>
                    <TwoBreadcrumb
                        href1={`/dashboard/orders`}
                        name1={`Orders`}
                        href2={``}
                        name2={`Order details`}
                    />
                </div>
                <button className=' text-base bg-green-600 text-white px-5 py-1.5 rounded-lg'>
                    Delivered
                </button>
            </div>
            <div
                className="flex justify-center flex-row gap-5 mt-3"
            >
                <div
                    className="w-1/3 items-center gap-5  flex flex-col "
                >
                    <div
                        className=' rounded-lg shadow-xl bg-white'
                    >
                        <div className="flex overflow-hidden flex-col items-center py-5 bg-white rounded-3xl max-md:px-5">
                            <ProfileImageComponent
                                profilePicUrl={orderDetails?.user_profile?.profile_pic}
                                className={`shrink-0 max-w-full aspect-square w-[100px] rounded-lg`}
                            />
                            <div className="mt-5 text-lg font-semibold text-neutral-900">
                                <TrimmedComponent trimdata={`${orderDetails?.user_profile?.firstname} ${orderDetails?.user_profile?.lastname}`} />
                            </div>
                            <div className=" text-center gap-2.5 px-3 py-1.5 mt-5 text-sm font-medium text-red-700 whitespace-nowrap bg-rose-50 rounded-lg">
                                Customer
                            </div>
                        </div>
                        <div className=" max-h-full  text-white bg-gray-700 rounded-t-2xl rounded-xl">
                            <div className="flex flex-col px-6 pt-4 pb-8 w-full max-md:px-5">
                                <div className="self-start text-lg font-semibold">Note order</div>
                                <div className="mt-3 text-xs font-medium">
                                    <TrimmedComponent trimdata={orderDetails?.note} />
                                </div>
                            </div>
                            <div className="gap-5 flex overflow-hidden items-center px-6 py-4 w-full bg-rose-50 rounded-t-2xl rounded-b-lg max-md:px-5">
                                <div className="flex flex-col justify-center items-center self-stretch my-auto w-8 h-8 bg-white rounded-lg min-h-[32px]">
                                    <ImgComp
                                        src={Images.order_detail_truck_icon}
                                        srcSet={Images.order_detail_truck_icon}
                                        alt={'register'}
                                        className='w-9 aspect-square'
                                    />
                                </div>
                                <div className="flex-1 shrink self-stretch my-auto text-xs font-medium basis-0 text-neutral-900">
                                    Lorem Ipsum is simply dummy text of the printing.
                                </div>
                            </div>
                        </div>
                    </div>
                    <Timeline statusData={orderDetails} />
                </div>
                <div className='flex flex-col gap-5 w-full grow'>
                    <table className="w-full text-xs text-left rtl:text-right text-gray-500 rounded-lg shadow-xl bg-white">
                        <thead className="text-sm text-gray-700  ">
                            <tr className=''>
                                <th scope="col" className="px-6 py-3">
                                    Items
                                    <i className='fa fa-arrow-down text-neutral-400 ms-2 '></i>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Qty
                                    <i className='fa fa-arrow-down text-neutral-400 ms-2 '></i>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Amount
                                    <i className='fa fa-arrow-down text-neutral-400 ms-2 '></i>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Tax
                                    <i className='fa fa-arrow-down text-neutral-400 ms-2 '></i>
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Total Amount
                                    <i className='fa fa-arrow-down text-neutral-400 ms-2 '></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-xs border border-x-0 border-b-0'>
                            <tr className="bg-white  hover:bg-gray-50 "
                            >
                                <td className="px-6 py-4  flex gap-5">
                                    <ProfileImageComponent
                                        profilePicUrl={orderDetails?.product_data?.image}
                                        className={`w-12 h-12 rounded-full border`}
                                    />
                                    <div className="flex flex-col justify-center self-stretch my-auto min-w-[240px]">
                                        <div className="text-sm font-semibold text-neutral-900">
                                            <TrimmedComponent trimdata={orderDetails?.product_data?.name} />
                                        </div>
                                        <div className="flex gap-4 items-start mt-2 max-w-full text-xs text-neutral-400 w-[239px]">
                                            <StarRating value={orderDetails?.rating?.rating} />
                                            <div className='text-xs'>({orderDetails?.rating?.rating} Reviews)</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-6 py-4 text-center">
                                    <TrimmedComponent trimdata={orderDetails?.quantity} />
                                </td>
                                <td className="px-6 py-4  ">

                                    <NumberConversion priceConvert={customerData?.Sub_total} />
                                </td>
                                <td className="px-6 py-4 text-center">
                                    <NumberConversion priceConvert={customerData?.tax} />
                                </td>
                                <td className="px-6 py-4 text-center">
                                    <NumberConversion priceConvert={customerData?.grand_total} />
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    <div className="flex overflow-hidden flex-col p-6 w-full bg-white rounded-lg shadow-xl bg-white max-md:px-5">
                        <div className="flex flex-col">
                            <div className="text-lg font-semibold text-neutral-900">
                                Delivered by
                            </div>
                            <div className="flex mt-6 items-center justify-between w-full rounded-none">
                                <div className="flex gap-6 items-center self-stretch my-auto">
                                    {/* <img
                                            loading="lazy"
                                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9235153dc736eb13ab4ee43e280943f70d7d120249bcc83b26c801a8ff116ac9?placeholderIfAbsent=true&apiKey=8e5983036f284a13866aae97819cdb73&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9235153dc736eb13ab4ee43e280943f70d7d120249bcc83b26c801a8ff116ac9?placeholderIfAbsent=true&apiKey=8e5983036f284a13866aae97819cdb73&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9235153dc736eb13ab4ee43e280943f70d7d120249bcc83b26c801a8ff116ac9?placeholderIfAbsent=true&apiKey=8e5983036f284a13866aae97819cdb73&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9235153dc736eb13ab4ee43e280943f70d7d120249bcc83b26c801a8ff116ac9?placeholderIfAbsent=true&apiKey=8e5983036f284a13866aae97819cdb73&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9235153dc736eb13ab4ee43e280943f70d7d120249bcc83b26c801a8ff116ac9?placeholderIfAbsent=true&apiKey=8e5983036f284a13866aae97819cdb73&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9235153dc736eb13ab4ee43e280943f70d7d120249bcc83b26c801a8ff116ac9?placeholderIfAbsent=true&apiKey=8e5983036f284a13866aae97819cdb73&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9235153dc736eb13ab4ee43e280943f70d7d120249bcc83b26c801a8ff116ac9?placeholderIfAbsent=true&apiKey=8e5983036f284a13866aae97819cdb73&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9235153dc736eb13ab4ee43e280943f70d7d120249bcc83b26c801a8ff116ac9?placeholderIfAbsent=true&apiKey=8e5983036f284a13866aae97819cdb73"
                                            className="object-contain shrink-0 self-stretch my-auto rounded-xl aspect-square w-[72px]"
                                        /> */}
                                    <ProfileImageComponent
                                        profilePicUrl={orderDetails?.delivery_profile}
                                        className={`w-12 h-12 rounded-full border`}
                                    />
                                    <div className="flex flex-col self-stretch my-auto">
                                        <div className="text-xl font-semibold text-neutral-900">
                                            <TrimmedComponent trimdata={orderDetails?.delivery_by} />
                                        </div>
                                        <div className="text-xs text-neutral-400">ID: <TrimmedComponent trimdata={orderDetails?.delivery_id} /></div>
                                    </div>
                                </div>
                                <div className="flex gap-10 items-end max-lg:gap-4 self-stretch my-auto">
                                    <div className="flex gap-5 items-center">
                                        <ImgComp
                                            src={Images.call_icon}
                                            className={`w-12 h-12 max-lg:w-10 max-lg:h-10`}
                                        />
                                        <div className="flex flex-col justify-center self-stretch my-auto">
                                            <div className="text-xs text-neutral-400">
                                                Phone Number
                                            </div>
                                            <div className="mt-1 text-base text-zinc-800">
                                                <TrimmedComponent trimdata={orderDetails?.delivery_phone} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-5 items-center">
                                        <ImgComp
                                            src={Images.delivery_time_icon}
                                            className={`w-12 h-12  max-lg:w-10 max-lg:h-10`}
                                        />
                                        <div className="flex flex-col justify-center self-stretch my-auto">
                                            <div className="text-xs text-neutral-400">
                                                Delivery Time
                                            </div>
                                            <div className="mt-1 text-base text-zinc-800">
                                                <TrimmedComponent trimdata={orderDetails?.delivery_time} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="flex grid grid-cols-3 gap-8 items-start mt-8 w-full max-md:max-w-full">
                            <div className="flex flex-col text-base font-semibold text-neutral-900 gap-3 ">
                                <div>Customer Identity</div>
                                <ImgComp
                                    src={customerData?.profile_pic ? customerData?.profile_pic : Images?.user_profile_placeholder}
                                    srcSet={customerData?.profile_pic ? customerData?.profile_pic : Images?.user_profile_placeholder}
                                    className={`w-56`}
                                />
                            </div>
                            <div className="flex flex-col max-md:max-w-full">
                                <div className="text-base font-semibold text-neutral-900">
                                    Customer Digital Signature
                                </div>
                                <div className="flex flex-col justify-center items-center px-6 py-8 mt-6 max-w-full rounded-lg bg-neutral-50 min-h-[181px] w-[435px] max-md:px-5">
                                    <img
                                        loading="lazy"
                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/02372b5288f0774e3d08af71ad82da064755c46f3c2704c5a0cf0b4cd92c42f2?placeholderIfAbsent=true&apiKey=8e5983036f284a13866aae97819cdb73"
                                        // className="object-contain max-w-full aspect-[1.96] "
                                        alt=''
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col ">
                                <div className="text-base font-semibold text-neutral-900">
                                    Review Rating
                                </div>
                                <div className="flex flex-col mt-6 w-full">
                                    <div className="text-sm text-neutral-400">
                                        Your overall rating
                                    </div>
                                    <div className="mt-4 text-lg font-[590] text-neutral-900">
                                        GOOD
                                    </div>
                                    <div className="flex gap-4 items-center self-start mt-4">
                                        <StarRating value={5} />
                                    </div>
                                    <div className="mt-4 text-sm leading-5 text-neutral-400">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s,
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

            </div>

        </>
    )
}

export default OrderDetailpage
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../../apiGeneralFunction';
import ImgComp from '../../../ReusableComp/ImgComp';
import * as Images from '../../../../assets';
import ModalBoxWrapper from '../../../ReusableComp/ModalBoxWrapper';
import { CustomerEditModal } from '../../../ReusableComp/ModalBox';
import { SearchBar } from '../../../ReusableComp/SearchBar';
import TableNoDataFound from '../../../ReusableComp/TableNoDataFound';
import Pagination from '../../../ReusableComp/Pagination';
import ProfileImageComponent from '../../../ReusableComp/ProfileImageComponent';
import TrimmedComponent from '../../../ReusableComp/TrimmedComponent';
import { FormattedDate } from '../../../ReusableComp/FormattedDate';
import { ExportIcon, EyeIcon } from '../../../ReusableComp/Icons';
import SortingDropdown from '../../../ReusableComp/SortingDropdown';
import { ReadOnlyInputWithoutTopMargin } from '../../../ReusableComp/InputType';
import toastPromise from '../../../../toastService';



const CustomersList = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [totalPages, setTotalPages] = useState();
    const [isModalEditOpen, setIsModalEditOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedId, setSelectedId] = useState('');
    const [searchQueryData, setSearchQueryData] = useState('');
    const [errors, setErrors] = useState({});
    const [customerId, setCustomerId] = useState();
    const [isStatusOpen, setIsStatusOpen] = useState(false);
    const [customerData, setCustomerData] = useState({
        name: '',
        registerDate: '',
        email: '',
        phoneNumber: '',
        homeFlatFloorNo: '',
        apartmentRoadArea: '',
        landmark: '',
        city: '',
        state: '',
        pincode: ''
    });

    const controller = new AbortController();

    const fetchCustomerData = async (page = 1, sortTitle = null, searchQuery = '') => {

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);
        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);
        if (!storedToken) {
            navigate('/');
            return;
        }
        const abortController = new AbortController();
        try {

            let sortByValue = "";
            if (selectedId === 1) {
                sortByValue = "asc";
            } else if (selectedId === 2) {
                sortByValue = "desc";
            }

            const formdata = new FormData();
            //  formdata.append("seller_id", userId?.user_id);
            formdata.append("page", page);
            formdata.append("sort_by", sortByValue);
            formdata.append("search", searchQuery || '');

            const result = await fetchWithFormDataToken(`get-customer-list`, formdata, storedToken, abortController);

            console.log("delete category response", result);
            if (result?.status === true) {
                setData(result?.data?.customer_list);
                setTotalPages(result?.data?.pagination?.total_pages)
            }
            else {
            }
        } catch (error) {

        }
        finally {

            abortController.abort();
        }
    }

    const handlePageChange = (newPage) => {
        fetchCustomerData(newPage, selectedId, searchQueryData);
        setCurrentPage(newPage)
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomerData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const datePart = customerData?.registerDate.split(' ')[0];
    const [year,month , day] = datePart.split('-');
    const formattedDate = `${day}-${month}-${year}`;
    const finalFormattedDate = formattedDate.replace(/\//g, '-');

    const modalEditContent = (
        <>
            <div className='grid grid-cols-1 gap-5'>
                <div className='flex flex-col border p-5 rounded-lg'>
                    <div className='text-left mb-5 font-bold text-xl'>
                        Customer Information
                    </div>
                    <div className='flex justify-between text-sm text-left gap-5 grid grid-cols-3 '>
                        <div className=' flex flex-col text-base  justify-around w-full gap-3  '>
                            <div className='text-sm text-gray-500'>Customer Image</div>
                            <div className='bg-gray-50'>
                                <ProfileImageComponent
                                    profilePicUrl={customerData?.profile_pic}
                                    className={`h-36 w-36  rounded-full border`}
                                />
                            </div>
                        </div>
                        <div className='col-span-2 w-full flex flex-col justify-between text-sm h-full  '>
                            <div className='grid grid-cols-2 gap-5 h-full'>
                                <div className='w-full flex flex-col text-base gap-2  justify-around'>
                                    <div>
                                        <ReadOnlyInputWithoutTopMargin
                                            labelName={`Customer Name`}
                                            value={customerData?.name}
                                            onChange={handleInputChange}
                                            type="text"
                                            name="name"
                                        />
                                    </div>
                                </div>

                                <div className='w-full flex flex-col text-base gap-2 justify-around'>
                                    <div>
                                        <ReadOnlyInputWithoutTopMargin
                                            labelName={`Register date`}
                                            value={customerData?.registered_date}
                                            onChange={handleInputChange}
                                            type="text"
                                            name="registerDate"
                                        />
                                    </div>
                                </div>

                                <div className='w-full flex flex-col text-base gap-2 justify-around'>
                                    <div>
                                        <ReadOnlyInputWithoutTopMargin
                                            labelName={`Email`}
                                            value={customerData?.email}
                                            onChange={handleInputChange}
                                            type="email"
                                            name="email"
                                        />
                                    </div>
                                </div>

                                <div className='w-full flex flex-col text-base gap-2 justify-around'>
                                    <div>
                                        <ReadOnlyInputWithoutTopMargin
                                            labelName={`Phone Number`}
                                            value={customerData?.phoneNumber}
                                            onChange={handleInputChange}
                                            type="text"
                                            name="phoneNumber"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col border p-5 rounded-lg '>
                    <div className='text-left mb-5 font-bold text-xl'>
                        Address Information
                    </div>
                    <div className='flex justify-between text-sm text-left gap-5'>
                        <div className='flex  flex-col justify-between text-sm gap-5 grid grid-cols-3 w-full'>
                            <div className='w-full flex flex-col text-base gap-2  justify-around'>
                                <div>
                                    <ReadOnlyInputWithoutTopMargin
                                        labelName={`Home/Flat/Floor No.`}
                                        value={customerData?.homeFlatFloorNo}
                                        onChange={handleInputChange}
                                        type="text"
                                        name="homeFlatFloorNo"
                                    />
                                </div>
                            </div>
                            <div className='w-full flex flex-col text-base gap-2  justify-around'>
                                <div>
                                    <ReadOnlyInputWithoutTopMargin
                                        labelName={`Apartment/Road/Area`}
                                        value={customerData?.apartmentRoadArea}
                                        onChange={handleInputChange}
                                        type="text"
                                        name="apartmentRoadArea"
                                    />
                                </div>
                            </div>
                            <div className='w-full flex flex-col text-base gap-2  justify-around'>
                                <div>
                                    <ReadOnlyInputWithoutTopMargin
                                        labelName={`Landmark`}
                                        value={customerData?.landmark}
                                        onChange={handleInputChange}
                                        type="text"
                                        name="landmark"
                                    />
                                </div>
                            </div>

                            <div className='w-full flex flex-col text-base gap-2 justify-around'>
                                <div>
                                    <ReadOnlyInputWithoutTopMargin
                                        labelName={`City`}
                                        value={customerData?.city}
                                        onChange={handleInputChange}
                                        type="text"
                                        name="city"
                                    />
                                </div>
                            </div>

                            <div className='w-full flex flex-col text-base gap-2 justify-around'>
                                <div>
                                    <ReadOnlyInputWithoutTopMargin
                                        labelName={`State`}
                                        value={customerData?.state}
                                        onChange={handleInputChange}
                                        type="text"
                                        name="state"
                                    />
                                </div>
                            </div>

                            <div className='w-full flex flex-col text-base gap-2 justify-around'>
                                <div>
                                    <ReadOnlyInputWithoutTopMargin
                                        labelName={`Pincode`}
                                        value={customerData?.pincode}
                                        onChange={handleInputChange}
                                        type="text"
                                        name="pincode"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    const updateItem = (item) => {
        console.log("updated item", item);
        setCustomerId(item?.id)

        if (item && (
            item.customer_name !== customerData.name ||
            item.registered_date !== customerData.registerDate ||
            item.email !== customerData.email ||
            item.phone !== customerData.phoneNumber ||
            item.flatno_building !== customerData.homeFlatFloorNo ||
            item.street_area !== customerData.apartmentRoadArea ||
            item.landmark !== customerData.landmark ||
            item.city !== customerData.city ||
            item.state !== customerData.state ||
            item.pincode !== customerData.pincode
        )) {
            setIsModalEditOpen(true);
            setCustomerData({
                name: item.customer_name || '',
                registerDate: item.registered_date || '',
                email: item.email || '',
                phoneNumber: item.phone || '',
                homeFlatFloorNo: item.flatno_building || '',
                apartmentRoadArea: item.street_area || '',
                landmark: item.landmark || '',
                city: item.city || '',
                state: item.state || '',
                pincode: item.pincode || ''
            });

        };
    };


    // const buttonEditContent = (
    //     <>
    //         <div className='flex items-center gap-5 justify-end mt-3'>
    //             <button
    //                 className='border_clr mybzb_text_clr text-base px-5 py-1.5 rounded-lg'
    //                 onClick={() => setIsModalEditOpen(false)}
    //             >
    //                 Cancel
    //             </button>
    //             <button
    //                 onClick={() => handleCustomerUpdate()}
    //                 className=' mybzb_bg_clr text-white text-base px-5 py-1.5 rounded-lg'
    //             >
    //                 Save Changes
    //             </button>
    //         </div>
    //     </>
    // )

    const closeEditModal = () => {
        setIsModalEditOpen(false);
    };

    const handleSearch = (searchQuery) => {
        setSearchQueryData(searchQuery)
        fetchCustomerData(currentPage, selectedId, searchQuery);
    };

    const handleSelectChange = (id) => {
        setSelectedId(id);
        fetchCustomerData(currentPage, id, searchQueryData)
    };

    useEffect(() => {
        fetchCustomerData(currentPage, selectedId, searchQueryData);
        return () => {
            controller.abort();
        };
    }, [currentPage, selectedId, searchQueryData]);



    // const handleCustomerUpdate = async () => {
    //     const tokenValue = localStorage?.getItem('login api response token');
    //     const storedToken = JSON?.parse(tokenValue);

    //     const fullName = customerData.name || '';
    //     const nameParts = fullName.split(' ');
    //     const firstName = nameParts[0] || '';
    //     const lastName = nameParts.slice(1).join(' ') || '';

    //     const formdata = new FormData();
    //     formdata.append("id", customerId);
    //     formdata.append("firstname", firstName);
    //     formdata.append("lastname", lastName);
    //     formdata.append("registerDate", customerData.registerDate);
    //     formdata.append("email", customerData.email);
    //     formdata.append("phone", customerData.phoneNumber);
    //     formdata.append("flatno_building", customerData.homeFlatFloorNo);
    //     formdata.append("street_area", customerData.apartmentRoadArea);
    //     formdata.append("landmark", customerData.landmark);
    //     formdata.append("city", customerData.city);
    //     formdata.append("state", customerData.state);
    //     formdata.append("pincode", customerData.pincode);


    //     try {
    //         const result = await fetchWithFormDataToken(`update-customer-profile`, formdata, storedToken);
    //         console.log("Customer update response", result);
    //         if (result?.status === true) {
    //             setIsModalEditOpen(false);
    //         } else {
    //         }
    //     } catch (error) {
    //     }
    // };

    const fetchCustomerAbleDisableData = async (status, item) => {

        console.log("fetchSellerAbleDisableData", status, item);

        const tokenValue = localStorage?.getItem('login api response token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            // return;
        }

        const abortController = new AbortController();

        try {
            const formdata = new FormData();
            formdata.append("customer_id", item?.id);
            formdata.append("status", status);

            const response = await toastPromise(
                fetchWithFormDataToken('admin-disabled-customer', formdata, storedToken, abortController, null),
                {
                    pending: "Updating seller status...",
                    success: (data) => data.message || "Status is changed successful !",
                    error: (data) => data.message || "Failed to change the seller status",
                }
            );

            if (response.status === true) {
                fetchCustomerData();
            }
        } catch (error) {
        }
        finally {
            setIsStatusOpen(false)
            abortController.abort();
        }
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const targetElement = event.target;
            if (isStatusOpen !== null && !targetElement.closest(`#status_menu_${isStatusOpen}`) && !targetElement.closest('.relative')) {
                setIsStatusOpen(null);
            }
        };
        document.body.addEventListener('click', handleOutsideClick);
        return () => {
            document.body.removeEventListener('click', handleOutsideClick);
        };
    }, [isStatusOpen]);

    return (
        <>
            <div className='bg-white shadow-md rounded-3xl'>
                <div className='w-full flex justify-between items-center p-5 text-xs'>
                    <SearchBar onSearch={handleSearch} searchQueryData={searchQueryData} />
                    <div className='flex items-center gap-5'>
                        <SortingDropdown
                            options={[
                                { id: 1, title: 'asc' },
                                { id: 2, title: 'desc' },
                                // { id: 3, title: 'none' },
                            ]}
                            selectedId={selectedId}
                            // onChange={handleSelectChange}
                            onChange={(id) => handleSelectChange(id)}
                        />
                        <div className=''>
                            <ExportIcon disabled={true} />
                        </div>
                    </div>
                </div>

                <div className="  h-full">
                    <table className="w-full text-xs text-left rtl:text-right text-gray-500 ">
                        <thead className="text-sm text-gray-700  bg-gray-50  ">
                            <tr className='text-center'>
                                <th scope="col" className="px-6 py-3">
                                    No.
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Customers
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Email
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Phone Number
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Location
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Register Date
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0 ?
                                data && data?.reverse().map((item, index) => {
                                    const renderStatus = (status, index, item) => {
                                        console.log("renderStatusstatus", status, item);
                                        let textColor, bgColor, iconColor;
                                        switch (status) {
                                            case "active":
                                                textColor = "text-green-600";
                                                bgColor = "bg-green-50";
                                                iconColor = "text-green-600";
                                                break;
                                            case "deactive":
                                                textColor = "text-red-700";
                                                bgColor = "bg-red-50";
                                                iconColor = "text-red-700";
                                                break;
                                            default:
                                                textColor = "text-gray-500";
                                                bgColor = "";
                                                iconColor = "text-gray-500";
                                        }

                                        return (
                                            <>

                                                <div className='relative'>
                                                    <div className={`${bgColor} ${textColor} px-3 py-1 rounded-lg cursor-pointer w-fit`}
                                                        onClick={() => {
                                                            setIsStatusOpen(prevState => (prevState === index ? null : index));
                                                        }}
                                                    >

                                                        {status === "active" ? "Active" : status === "deactive" ? "Inactive" : "Unknown"}
                                                        <i className={`fa fa-angle-down ${iconColor} ms-2 `} />
                                                    </div>

                                                    {isStatusOpen === index && (
                                                        <div
                                                            id={`status_menu_${index}`}
                                                            className="absolute right-0 z-20 mt-2 origin-top-right  rounded-md  focus:outline-none"
                                                            role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1"
                                                        >
                                                            <ul className=" text-xs" aria-labelledby="user-menu-button">
                                                                {status === "active" ?
                                                                    <li onClick={() => fetchCustomerAbleDisableData('deactive', item)}>
                                                                        <div className="block px-5 py-2 text-red-800 bg-red-50 hover:text-red-700 cursor-pointer rounded-lg">
                                                                            Inactive
                                                                        </div>
                                                                    </li>
                                                                    :
                                                                    <li onClick={() => fetchCustomerAbleDisableData('active', item)}>
                                                                        <div className="block px-5 py-2 text-green-600 bg-green-50 hover:text-red-700 cursor-pointer  rounded-lg">
                                                                            Active
                                                                        </div>
                                                                    </li>
                                                                }
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        );
                                    };
                                    return (
                                        <tr className="bg-white cursor-pointer  hover:bg-gray-50 text-center" key={index}
                                        >
                                            <td className="px-6 py-4">
                                                {(currentPage - 1) * 10 + index + 1}
                                            </td>
                                            <td
                                                className="px-6 py-4 flex items-center gap-3 justify-start "
                                                onClick={() => navigate('customer-detail', { state: { customerDetailId: item?.id } })}
                                            >
                                                <ProfileImageComponent
                                                    profilePicUrl={item?.profile_pic}
                                                    className={`w-12 h-12 rounded-full border`}
                                                />
                                                <TrimmedComponent trimdata={item?.customer_name} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <TrimmedComponent trimdata={item?.email} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <TrimmedComponent trimdata={item?.phone} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <TrimmedComponent trimdata={item?.location} />
                                            </td>
                                            <td className="px-6 py-4">
                                                <FormattedDate date={item?.registered_date || "-"} />
                                            </td>
                                            {/* <td className='text-center cursor-pointer'
                                                onClick={() => updateItem(item)}
                                            >
                                                <div className='flex justify-center items-center gap-5 '>
                                                    <EyeIcon />
                                                </div>
                                            </td> */}
                                            <td className="px-6 py-4 capitalize font-bold text-center">
                                                <div className=' text-center mx-auto flex justify-center items-center'>
                                                    {renderStatus(item?.status, index, item) || "-"}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <TableNoDataFound colSpan={7} />
                            }
                        </tbody>
                    </table>
                </div>
                {data?.length > 0 &&
                    <div className='flex w-full justify-center items-center my-5'>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>
                }
            </div>
            {isModalEditOpen && (
                <CustomerEditModal
                    title="View Customers Details"
                    content={modalEditContent}
                    // buttonContent={buttonEditContent}
                    onClose={closeEditModal}
                    modalWidth={`max-w-3xl`}
                />
            )}
        </>
    )
}

export default CustomersList
import { Link } from "react-router-dom";

export const ThreeBreadcrumb = ({ name2, name3, href2, href3 }) => {
    function BreadcrumbItem({ to, text, isCurrent }) {
        return (
            <>
                <Link
                    to={to}
                    className={`${isCurrent ? "font-bold mybzb_text_clr " : "font-bold text-neutral-500"
                        }`}
                    aria-current={isCurrent ? "page" : undefined}
                >
                    {text}
                </Link>
                {!isCurrent && (
                    <>
                        <i className="fa fa-angle-right text-base font-bold"></i>
                    </>
                )}
            </>
        );
    }
    return (
        <>
            <header className="flex flex-col  items-start self-stretch  py-3.5 text-sm bg-white border-b border-gray-200 border-solid text-neutral-400 max-md:px-5">
                <nav className="flex gap-2 items-center justify-start w-[1296px] mx-auto" aria-label="Breadcrumb">
                    <BreadcrumbItem to="/" text="Home" />
                    <BreadcrumbItem to={href2} text={name2} />
                    <BreadcrumbItem
                        to={href3}
                        text={name3}
                        isCurrent={true}
                    />
                </nav>
            </header>
        </>
    )
}

export const TwoBreadcrumb = ({ href2, name2, href1, name1 }) => {
    const BreadcrumbItem = ({ to, text, isCurrent }) => {
        return (
            <>
                <Link
                    to={to}
                    className={`  ${isCurrent ? "font-bold mybzb_text_clr " : "font-bold text-neutral-500"
                        }`}
                    aria-current={isCurrent ? "page" : undefined}
                >
                    {text}
                </Link>
                {!isCurrent && (
                    <>
                        <span className="font-bold ">
                           &nbsp; / &nbsp;
                        </span >
                    </>
                )}
            </>
        );
    } 
    return (
        <>
            <div className=" text-sm text-neutral-400 py-3">
                <BreadcrumbItem to={href1} text={name1} />
                <BreadcrumbItem
                    to={href2}
                    text={name2}
                    isCurrent={true}
                />
            </div>
        </>
    )
}
import React, { useEffect, useState } from 'react';
import * as Images from '../../../../assets';
import ModalBoxWrapper from '../../../ReusableComp/ModalBoxWrapper';
import ImgComp from '../../../ReusableComp/ImgComp';
import { fetchDataWithToken, fetchWithFormDataToken } from '../../../../apiGeneralFunction';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteModalBox from '../../../ReusableComp/DeleteModalBox';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { blobToWebP } from 'webp-converter-browser';
import ProfileImageComponent from '../../../ReusableComp/ProfileImageComponent';
import { FormattedDate } from '../../../ReusableComp/FormattedDate';
import TrimmedComponent from '../../../ReusableComp/TrimmedComponent';
import TableNoDataFound from '../../../ReusableComp/TableNoDataFound';
import Pagination from '../../../ReusableComp/Pagination';
import Loader from '../../../ReusableComp/Loader';


const Banners = () => {

    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [bannerList, setBannerList] = useState([]);
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);
    const [nameUpdate, setNameUpdate] = useState('');
    const [idUpdate, setIdUpdate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const controller = new AbortController();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchData();
        return () => {
            controller.abort();
        };
    }, []);

    const fetchData = async () => {
        setLoading(true)
        try {
            const tokenValue = localStorage?.getItem('token');
            const storedToken = JSON?.parse(tokenValue);
            if (!storedToken) {
                navigate('/');
                return;
            }
            const result = await fetchDataWithToken('list-customer-banner', storedToken, navigate);
            if (result?.status === true) {
                setBannerList(result?.data)
            }
        } catch (error) {
        }
        finally {
            setLoading(false)
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFile(file);
    };

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        handleFile(file);
        const fileName = file;
        console.log("fileName", fileName);
        setSelectedFileName(fileName?.name)
    };

    const handleFile = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        reader.readAsDataURL(file);

        console.log("hangleFile", file);
        setSelectedFile(file)
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const modalContent = (
        <>
            <div className='flex flex-col h-full '>
                <div className='text-left mb-5 font-bold text-xl'>
                    Banners Information
                </div>
                <div className='flex justify-between flex-col gap-5 h-full '>

                    <div className='flex flex-col items-start'>
                        <label className='text-neutral-500'>Banners Name
                            <span className='mybzb_text_clr'> *</span>
                        </label>
                        <input
                            className='mt-3 w-full border border-gray-200 rounded-lg'
                            placeholder='Enter Name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />

                    </div>
                    <div className=' flex flex-col mt-3'>
                        <div className='mb-3 text-neutral-500 text-start'>Banners Image (150px*150px)</div>
                        <div
                            className="flex w-[150px] h-[150px] flex-col grow px-1 py-1 justify-around rounded-lg border border-red-500 border-dashed terms_bg_clr max-md:px-5 mybzb_bg_pinkclr "
                            onDrop={handleDrop}
                            onDragOver={(e) => e.preventDefault()}
                        >
                            <label htmlFor="file-input" className="cursor-pointer">
                                <div className="text-center  mx-auto h-full">
                                    <ImgComp
                                        src={image ? image : Images.upload_icon}
                                        srcSet={image ? image : Images.upload_icon}
                                        // className="self-center w-14 border-white  mx-auto"
                                        className={`${image ? 'w-48' : 'w-14'} self-center border-white  mx-auto`}
                                    />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileSelect}
                                        className="hidden"
                                        id="file-input"
                                    />
                                </div>
                            </label>
                            {!image &&
                                <>
                                    <div className='mybzb_text_clr'>Click to upload</div>

                                    <div className="mt-1 text-xs text-center text-neutral-400 max-md:max-w-full">
                                        SVG, PNG, JPG or GIF <br />(max. 150px*150px)
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    );

    const buttonContent = (
        <>
            <div className='flex items-center gap-5 justify-end mt-3'>
                <button
                    className='border border-red-500 mybzb_text_clr text-base px-5 py-2 rounded-lg'
                    onClick={() => setIsModalOpen(false)}
                >
                    Cancel
                </button>
                <button
                    onClick={() => handleSubmit()}
                    className='border border-red-500 mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg'
                >
                    Submit
                </button>
            </div>
        </>
    )

    const modalDeleteContent = (
        <>
            <div className='text-center text-xl mb-5 px-5'>
                Are you sure want to delete this {nameUpdate} ?
            </div>

        </>
    );

    const buttonDeleteContent = (
        <>
            <div className='flex items-center gap-5 justify-end mt-3'>
                <button
                    className='border border-red-500 mybzb_text_clr text-base px-5 py-2 rounded-lg w-full'
                    onClick={() => setIsModalDeleteOpen(false)}
                >
                    No
                </button>
                <button
                    className=' mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg w-full'
                    onClick={() => deleteAPI()}
                >
                    Yes
                </button>
            </div>
        </>
    )

    const handleSubmit = async () => {

        const tokenValue = localStorage?.getItem('token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }
        const abortController = new AbortController();

        try {
            const formdata = new FormData();
            if (selectedFile) {
                formdata.append("image", selectedFile, selectedFileName);
            }
            else {
                formdata.append("image", image);
            }
            formdata.append("title", name);
            const result = await fetchWithFormDataToken('add-customer-banner', formdata, storedToken, abortController);

            console.log("add category response", result);
            if (result?.status === true) {
                toast.success(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
                fetchData();
                setIsModalOpen(false)
                setName('');
                setImage('');
            }
            else {
                toast.error(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
                setName('');
                setImage('');
            }
        } catch (error) {
        }
        finally {
            abortController.abort();
        }
    };

    const deleteAPI = async () => {

        const tokenValue = localStorage?.getItem('token');
        const storedToken = JSON?.parse(tokenValue);

        const user_id = localStorage?.getItem('login data');
        const userId = JSON.parse(user_id);
        console.log("categories login id", userId);

        if (!storedToken) {
            navigate('/');
            return;
        }
        const abortController = new AbortController();
        try {
            const formdata = new FormData();
            formdata.append("id", idUpdate);
            const result = await fetchWithFormDataToken('delete-customer-banner', formdata, storedToken, abortController);
            console.log("delete category response", result);
            if (result?.status === true) {
                toast.success(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
                fetchData();
                setIsModalDeleteOpen(false)
            }
            else {
                toast.error(result?.message, {
                    position: 'top-center',
                    autoClose: 2000,
                });
            }
        } catch (error) {
        }
        finally {
            abortController.abort();
        }
    };


    const deleteItem = (item) => {
        setIdUpdate(item?.id)
        setIsModalDeleteOpen(true);
        setNameUpdate(item?.Name)
    };

    return (
        <>
            <div className='w-full flex justify-between items-center bg-white p-5 rounded-tr-3xl'>
                <div className='font-bold text-2xl'>Banners</div>
                <div className='flex items-center gap-5'>
                    <div onClick={openModal} className='mybzb_bg_clr text-sm text-white px-3 py-1 rounded-lg cursor-pointer'>
                        <i className='fa fa-plus me-3'></i>
                        Add
                    </div>
                </div>
            </div>


            <div className='shadow-md rounded-b-3xl '>
                <table class="w-full text-xs text-left rtl:text-right text-gray-500 ">
                    <thead class="text-sm text-gray-700" >
                        <tr className='text-center bg-gray-50'>

                            <th scope="col" class="px-6 py-3">
                                No.
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Banners Name
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Banners Image
                            </th>

                            <th scope="col" class="px-6 py-3">
                                Created date
                            </th>

                            <th scope="col" class="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            loading ? (
                                <>
                                    <tr>
                                        <td colSpan={5}>
                                            <Loader />
                                        </td>
                                    </tr>

                                </>
                            ) : (
                                bannerList?.length > 0 ?
                                    bannerList && bannerList?.slice()?.reverse().map((item, index) => {
                                        return (
                                            <tr className="bg-white border-red-500  hover:bg-gray-50 text-center">
                                                <td className="px-6 py-4">
                                                    {(currentPage - 1) * 10 + index + 1}
                                                </td>
                                                <td className="px-6 py-4">
                                                    <TrimmedComponent trimdata={item?.Name} />
                                                </td>
                                                <td className="px-6 py-6">
                                                    <ProfileImageComponent
                                                        profilePicUrl={item?.Image}
                                                        className={`w-12 h-12 rounded-full border mx-auto`}
                                                    />
                                                </td>
                                                <td className="px-6 py-4">
                                                    <FormattedDate date={item?.craetedDate || "-"} />
                                                </td>
                                                <td className='text-center '>
                                                    <div className='flex justify-center items-center gap-5'>
                                                        <div
                                                            className='cursor-pointer inactive_bg px-2 rounded-lg'
                                                            onClick={() => deleteItem(item)}
                                                        >
                                                            <i className="fa fa-trash-o mybzb_text_clr text-lg "></i>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <TableNoDataFound colSpan={5} />
                            )
                        }
                    </tbody>
                </table>
            </div>
            {/* <div className='flex w-full justify-center items-center my-5'>
                    <Pagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                </div> */}

            {isModalOpen && (
                <ModalBoxWrapper
                    title="Add Banners"
                    content={modalContent}
                    buttonContent={buttonContent}
                    onClose={closeModal}
                    modalWidth={`max-w-lg`}
                />
            )}

            {isModalDeleteOpen && (
                <DeleteModalBox
                    content={modalDeleteContent}
                    buttonContent={buttonDeleteContent}
                    modalWidth={`max-w-sm`}
                />
            )}
            <ToastContainer />

        </>
    )
}

export default Banners
import React from 'react'
import Navbar from '../../ReusableComp/Layout/NavbarComp/Navbar'
import TransactionList from './TransactionList'



const Transactionspage = () => {
  return (
    <>
      <Navbar name={'Transactions'} />
      <div className='p-10 bg-gray-50'>
        <TransactionList />
      </div>
    </>
  )
}

export default Transactionspage
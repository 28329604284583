import React from 'react'
import Navbar from '../../ReusableComp/Layout/NavbarComp/Navbar'
import CMSList from './CMSList'


const CMSpage = () => {
  return (
    <>
      <Navbar name={'Sellers'} />
      <div className='p-10 bg-gray-50 h-full'>
        <CMSList />
      </div>
    </>
  )
}

export default CMSpage
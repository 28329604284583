import { useEffect, useState } from "react";
import ImgComp from "../ImgComp";
import * as Images from '../../../assets'


export const FilterIcon = ({ onClick }) => {
    const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const handleMenuItemClick = (itemId) => {
        setSelectedItemId(itemId);
        setIsUserProfileOpen(false); // Close the menu after selection
        console.log(`Selected filter: ${itemId}`); // Debugging
        if (onClick) {
            onClick(itemId); // Pass selected item to parent (if needed)
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                !event.target.closest('#user_menu') &&
                !event.target.closest('#menu-toggle')
            ) {
                setIsUserProfileOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const menuItems = [
        { id: 1, label: "today" },
        { id: 2, label: "yesterday" },
        { id: 3, label: "week" },
        { id: 4, label: "month" },
        { id: 5, label: "3_month" },
        { id: 6, label: "6_month" },
        { id: 7, label: "year" },
        { id: 8, label: "all" }
    ];

    return (
        <div className="relative">
            <div
                id="menu-toggle"
                onClick={() => setIsUserProfileOpen(!isUserProfileOpen)}
                className="cursor-pointer flex items-start gap-3 bg-white px-2.5 rounded-lg py-1"
            >
                <div
                    className="flex items-center gap-3 border rounded-lg px-3 py-1.5 text-white text-xs mybzb_bg_clr"
                >
                    <ImgComp
                        src={Images.filter_white_outline}
                        srcSet={Images.filter_white_outline}
                        className="w-5"
                    />
                    Filter
                    <span className="sr-only">Open user menu</span>
                    <i className={`fa fa-angle-${isUserProfileOpen ? 'up' : 'down'}`}></i>
                </div>
            </div>
            {isUserProfileOpen && (
                <div
                    id="user_menu"
                    className="absolute right-0 z-20 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-toggle"
                    tabIndex="-1"
                >
                    <ul className="py-2" aria-labelledby="menu-toggle">
                        {menuItems.map((menuItem) => (
                            <li key={menuItem.id}>
                                <div
                                    className={`block px-4 py-2 text-sm text-zinc-800 hover:bg-gray-200 hover:text-orange-500 cursor-pointer ${
                                        selectedItemId === menuItem.id ? 'bg-gray-100' : ''
                                    }`}
                                    onClick={() => handleMenuItemClick(menuItem.id)}
                                >
                                    {menuItem.label}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

// export const FilterIcon = ({ onClick,onFilterSelect }) => {

//     const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
//     const [selectedItemId, setSelectedItemId] = useState(null);

//     const handleMenuItemClick = (itemId) => {
//         setSelectedItemId(itemId);
//         const filterValue = mapIdToFilterValue(itemId);
//         onFilterSelect(filterValue);
//         setIsUserProfileOpen(false);
//     };

//     const mapIdToFilterValue = (id) => {
//         switch (id) {
//             case 1: return "today";
//             case 2: return "yesterday";
//             case 3: return "week";
//             case 4: return "month";
//             case 5: return "3_month";
//             case 6: return "6_month";
//             case 7: return "year";
//             case 8: return "all";
//             default: return "";
//         }
//     };

//     useEffect(() => {
//         const handleClickOutside = (event) => {
//             if (
//                 !event.target.closest('#user_menu')
//             ) {
//                 setIsUserProfileOpen(false)
//             }
//         };

//         document.addEventListener('mousedown', handleClickOutside);
//         return () => {
//             document.removeEventListener('mousedown', handleClickOutside);
//         };
//     }, []);

//     const menuItems =  [
//         {
//             "id": 1,
//             "label": "Today"
//         },
//         {
//             "id": 2,
//             "label": "Yesterday"
//         },
//         {
//             "id": 3,
//             "label": "Week"
//         },
//         {
//             "id": 4,
//             "label": "Month"
//         },
//         {
//             "id": 5,
//             "label": "3 Month"
//         },
//         {
//             "id": 6,
//             "label": "6 Month"
//         },
//         {
//             "id": 7,
//             "label": "Year"
//         },
//         {
//             "id": 8,
//             "label": "All"
//         }
//     ]

//     return (
//         <>
//             <div 
//                 className='relative'
//             >
//                 <div
//                     onClick={() => setIsUserProfileOpen(!isUserProfileOpen)}
//                     className='cursor-pointer flex items-start gap-3  text-sm font-bold'
//                 >
//                     <div
//                         onClick={onClick}
//                         className='flex items-center gap-3 border rounded-lg px-3 py-1.5 text-white  mybzb_bg_clr'
//                     >
//                         <ImgComp
//                             src={Images.filter_white_outline}
//                             srcSet={Images.filter_white_outline}
//                             className='w-4'
//                         />
//                         Filter
//                         <span className="sr-only">Open user menu</span>
//                         <i className="fa fa-angle-down" ></i>
//                     </div>
//                 </div>
//                 {isUserProfileOpen &&
//                     <div
//                         id="user_menu"
//                         className="absolute right-0 z-20 mt-2  origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1"
//                     >
//                         <ul className="py-2" aria-labelledby="user-menu-button">
//                             {menuItems.map((menuItem) => (
//                                 <li key={menuItem.id}>
//                                     <div
//                                         className="block px-4 py-2 text-sm text-zinc-800 hover:terms_bg_clr hover:text-orange-500 cursor-pointer"
//                                         onClick={() => handleMenuItemClick(menuItem.id)}
//                                     >
//                                         {menuItem.label}
//                                     </div>
//                                 </li>
//                             ))}
//                         </ul>
//                     </div>
//                 }

//             </div>
            
//         </>
//     )
// }
import React, { useState } from 'react'
import ImgComp from '../ImgComp';
import * as Images from '../../../assets';


const DeleteModalBox = ({ title, content, onClose, buttonContent, modalWidth }) => {

    const [isOpen, setIsOpen] = useState(true);

    const closeModal = () => {
        setIsOpen(false);
        // onClose();
    };

    return (
        <div
            style={{ backgroundColor: 'rgb(0 0 0 / 40%)' }}
            // onClick={closeModal}
            className=" backdrop-filter backdrop-blur-sm inset-0 flex justify-center items-center bg-gray-200 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full"
        >

            <div
                // className=' bg-white rounded-2xl max-md:h-full w-full max-w-2xl zoom_in_pp_modal' 
                className={`bg-white rounded-2xl max-md:h-full w-full ${modalWidth} zoom_in_pp_modal`}
            // onClick={(e) => e.stopPropagation()} 
            >
                <div className=" relative bg-white w-full max-w-2xl max-h-full rounded-2xl">
                    <div className="relative bg-white rounded-2xl shadow-lg  p-6">


                        <ImgComp
                            src={Images.delete_modal}
                            className={'h-48 mx-auto'}
                        />

                        <div className='w-full flex flex-col  p-5 rounded-lg  '>
                            {content}
                            {buttonContent}

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default DeleteModalBox
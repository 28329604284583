import React, { useEffect, useState } from 'react';
import ImgComp from '../../../../ReusableComp/ImgComp';
import * as Images from '../../../../../assets';
import { fetchDataWithToken, fetchWithFormDataToken } from '../../../../../apiGeneralFunction';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CustomerEditModal } from '../../../../ReusableComp/ModalBox';

const FaqsList = () => {

  const navigate = useNavigate();

  const controller = new AbortController();

  const [generalList, setGeneralList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [nameUpdate, setNameUpdate] = useState('');
  const [idUpdate, setIdUpdate] = useState('');
  const [descUpdate, setDescUpdate] = useState('');

  useEffect(() => {
    fetchData();
    return () => {
      controller.abort();
    };
  }, []);

  const fetchData = async () => {
    try {
      const tokenValue = localStorage?.getItem('token');
      const storedToken = JSON?.parse(tokenValue);
      if (!storedToken) {
        navigate('/');
        return;
      }
      const result = await fetchDataWithToken('list-general-settings', storedToken, navigate);

      if (result?.status === true) {
        setGeneralList(result?.data)
      }
    } catch (error) {
    }
  };

  const InfoCardTitle = ({ title, id }) => {
    return (
      <>
        <div className="text-base font-semibold leading-8 ">
          Q{id}. &nbsp;{title}
        </div>
      </>
    )
  }

  const InfoCard = ({ title, id, imageSrc, description, descId }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [inputValue, setInputValue] = useState(description);

    const updateItem = (id, title, description) => {
      setIdUpdate(id)
      setNameUpdate(title)
      setInputValue(description)

    };

    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };

    const handleEditClick = () => {
      setIsEditing(true);
    };

    const updateAPI = async () => {
      setIsEditing(false);
      updateItem(id, title, inputValue);
      const tokenValue = localStorage?.getItem('token');
      const storedToken = JSON?.parse(tokenValue);

      const user_id = localStorage?.getItem('login data');
      const userId = JSON.parse(user_id);
      console.log("categories login id", userId);

      if (!storedToken) {
        navigate('/');
        return;
      }


      const abortController = new AbortController();

      try {

        const formdata = new FormData();


        formdata.append("id", idUpdate);
        formdata.append("title", nameUpdate);
        // formdata.append("description", descUpdate);

        formdata.append("description", inputValue);
        const result = await fetchWithFormDataToken('update-general-settings', formdata, storedToken, abortController);

        console.log("delete category response", result);
        if (result?.status === true) {
          toast.success(result?.message, {
            position: 'top-center',
            autoClose: 2000,
          });
          fetchData();

          setDescUpdate('');
          setNameUpdate('')
        }
        else {
          toast.error(result?.message, {
            position: 'top-center',
            autoClose: 2000,
          });

        }
      } catch (error) {

      }
      finally {

        abortController.abort();
      }
    }

    console.log("InfoCard id", title, id);
    return (
      <section className="flex flex-col grow p-4 rounded-xl border border-gray-200 border-solid max-md:mt-6 max-md:max-w-full">
        <header className="flex gap-5 justify-between max-md:flex-wrap max-md:max-w-full">
          <InfoCardTitle title={title} id={id} />
          <div
            onClick={isEditing ? updateAPI : handleEditClick}
            className="cursor-pointer flex justify-center items-center px-1 py-0.5 my-auto w-4 h-4 bg-neutral-50 rounded-[360px]"
          >
            <i
              onClick={() => updateItem(id, title, description)}
              className={`fa ${isEditing ? 'fa-check-circle' : 'fa-pencil'} text-base ${isEditing ? 'text-green-800' : 'text-gray-400'}`}
            >
            </i>
          </div>
        </header>

        {isEditing ? (
          <textarea
            value={inputValue}
            className='mt-3 w-full border border-gray-200 rounded-lg h-full h-32 text-sm'
            onChange={handleInputChange}
          >
          </textarea>
        ) : (
          <p className="justify-center px-3.5 py-2 mt-2 text-sm rounded-lg bg-neutral-50 text-neutral-900 max-md:max-w-full">{description}</p>
        )}
        {/* <p className="justify-center px-3.5 py-3 mt-4 text-sm rounded-lg bg-neutral-50 text-neutral-900 max-md:max-w-full">{description}</p> */}
      </section>
    );
  };

  const headings = [
    {
      id: 1,
      title: 'Discover the Essence of Bhartiya Culture in Jewellery'
    },
    {
      id: 2,
      title: 'Limited Jewellers, Unlimited Design'
    },
    {
      id: 3,
      title: 'From Traditional to Modern'
    },
    {
      id: 4,
      title: 'Immerse Yourself in the Beauty of Bhartiya Jewellery'
    },
  ]

  const mergeTitleAndDescription = (headings, data) => {
    return data.map(item => {
      const heading = headings.find(h => h.id === item.id);
      return heading ? { ...item, title: heading.title } : item;
    });
  };

  const mergedData = mergeTitleAndDescription(headings, generalList);

  const filterById = (id) => {
    return mergedData.filter(item => item.id === id);
  };


  const renderInfoCards = (ids) => {
    return ids.map(id => (
      filterById(id).map(item => (
        <InfoCard key={item.id} {...item} />
      ))
    ));
  };

  const modalContent = (
    <>
      <div className='flex flex-col h-full border rounded-lg p-4 '>
        <div className='text-left mb-5 font-bold text-xl'>
          Question 5
        </div>
        <div className='flex justify-between gap-10 h-full '>
          <div className='w-full flex flex-col justify-between h-full text-sm'>
            <div className='flex flex-col items-start'>
              <label className='text-neutral-500'>Question title
                <span className='mybzb_text_clr'> *</span>
              </label>
              <input
                className='mt-3 w-full border border-gray-200 rounded-lg  text-sm'
                placeholder='Enter Question title'
              // value={name}
              // onChange={(e) => setName(e.target.value)}
              />

            </div>
            <div className='text-left my-5'>
              <label htmlFor='size' className='mb-3 text-neutral-500'>
                Description
                <span className='mybzb_text_clr'> *</span>
              </label>
              <textarea
                // value={unit}
                // onChange={(e) => setUnit(e.target.value)}
                placeholder='Enter Description'
                className='mt-3 w-full border border-gray-200 rounded-lg h-full h-28 text-sm'
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const buttonContent = (
    <>
      <div className='flex items-center gap-5 justify-end mt-3'>
        <button
          className='border border-red-700 mybzb_text_clr text-base px-5 py-2 rounded-lg'
          onClick={() => setIsModalOpen(false)}
        >
          Cancel
        </button>
        <button
          // onClick={() => handleSubmit()}
          className='border border-red-700 mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg'
        >
          Submit
        </button>
      </div>
    </>
  )

  return (
    <>
      <section className="flex flex-col pb-8 bg-white rounded-b-3xl rounded-r-3xl max-md:max-w-full">
        <div className='px-5 border-b border-gray-200 border-solid flex items-center justify-between'>
          <header className="items-start  py-4 text-2xl font-semibold text-neutral-900 max-md:pr-5 max-md:max-w-full">
            Help & FAQ's
          </header>
          <div className='flex items-center gap-5 text-xs'>
            {/* <div className='flex items-center gap-3 border rounded-lg px-3 py-1 text-gray-500  '>
              <ImgComp
                src={Images.export_icon}
                srcSet={Images.export_icon}
                className='w-5'
              />
              Export
            </div> */}
            <div
              onClick={openModal}
              className='mybzb_bg_clr text-white px-3 py-1.5 rounded-lg cursor-pointer  '>
              <i className='fa fa-plus me-3'></i>
              Add
            </div>
          </div>
        </div>
        <div className="self-center mt-4 w-full px-5 gap-5 grid grid-cols-1">
          {renderInfoCards([1, 2, 3, 4])}
        </div>
      </section>
      {isModalOpen && (
        <CustomerEditModal
          title="Add FAQs"
          content={modalContent}
          buttonContent={buttonContent}
          onClose={closeModal}
          modalWidth={`max-w-2xl`}
        />
      )}
      <ToastContainer />
    </>
  )
}

export default FaqsList
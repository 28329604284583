
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { fetchWithFormDataToken } from '../../../../apiGeneralFunction';
import ImgComp from '../../../ReusableComp/ImgComp';
import AboutusList from './AboutusList';
import FaqsList from './FaqsList';



const CMSList = () => {

    const navigate = useNavigate();


    const [activeTab, setActiveTab] = useState(0);

    const tabs = ['About Us', "Help & FAQ's"];

    const content = [
        <AboutusList />,
        <FaqsList />
    ];

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <>
            <div className="flex border w-fit rounded-lg bg-white">
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`cursor-pointer px-10 py-2 text-sm   ${activeTab === index ? 'mybzb_bg_clr text-white border border-b-1 border-x-0 border-t-0 border-red-800 ' : 'text-neutral-500'
                            } ${tab === 'About Us' ? 'rounded-tl-lg' : ' rounded-tr-lg'}  `}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab}
                    </div>
                ))}
            </div>
            <div className="">{content[activeTab]}</div>
        </>
    )
}

export default CMSList
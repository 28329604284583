
export const OutlineButton = ({ onClick, buttonName }) => {
    return (
      <button
        className=' border border-red-700 text-red-800 text-base px-5 py-2 rounded-lg w-full'
        onClick={onClick}
      >
        {buttonName}
      </button>
    )
  }
  
  export const BgButton = ({ onClick, buttonName }) => {
    return (
  
      <button
        className=' mybzb_bg_clr text-white text-base px-5 py-2 rounded-lg w-full'
        onClick={onClick}
      >
        {buttonName}
      </button>
  
    )
  }
import React, { useEffect, useState } from 'react';
import { SortIcon } from '../Icons';

const SortingDropdown = ({ options, selectedId, onChange }) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    const handleOptionClick = (id) => {
        console.log("Option clicked with ID:", id);
        onChange(id);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const targetElement = event.target;
            if (isOpen && !targetElement.closest('#sort_menu') && !targetElement.closest('.relative')) {
                setIsOpen(false);
            }
        };
        document.body.addEventListener('click', handleOutsideClick);
        return () => {
            document.body.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen]);


    return (
        <div className="relative cursor-pointer">
            <SortIcon
                onClick={() => setIsOpen(!isOpen)}
            />
            {isOpen && (
                <div
                    id="sort_menu"
                    className="absolute left-0 top-full mt-2 w-full bg-white z-10 rounded-lg border border-gray-200">
                    {options && options?.length > 0 && options?.map((option) => (
                        <div
                            key={option.id}
                            onClick={() => handleOptionClick(option.id)}
                            className={`font-bold capitalize px-3 py-1.5 cursor-pointer text-xs ${selectedId === option?.id ? 'bg-red-800 text-white' : 'text-neutral-500 hover:bg-red-800 hover:text-white'}`}
                        >
                            {option.title}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SortingDropdown;

